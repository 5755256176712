<template>
  <transition name="modal" appear>
    <div class="modal modalOverlay" @click.self.stop="close('overlay')">
      <div class="modalHeaderWindow" ref="window">
        <header class="modalHeader" ref="header">
          <i class="modalHeader_icon material-icons" :class="{ isLarge: isLargeIcon }">{{
            icon
          }}</i>
          <p class="modalHeader_title" :class="{ isSmall: isSmallTitle }">{{ title }}</p>
          <button
            type="button"
            class="modalHeader_cancel"
            :disabled="isDisabledClose"
            @click.stop="close"
          >
            <i class="material-icons">cancel</i>
          </button>
        </header>
        <div ref="mContent" class="modalContent">
          <div ref="content" class="modalContentInr" :class="{ isImageList }">
            <slot name="content" />
          </div>
        </div>
        <footer class="modalFooter" ref="footer" v-if="isSetFooter">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    isLargeIcon: {
      type: Boolean,
      default: false,
    },
    isSmallTitle: {
      type: Boolean,
      default: false,
    },
    isDisabledClose: {
      type: Boolean,
      default: false,
    },
    isDisabledOverlay: {
      type: Boolean,
      default: false,
    },
    isImageList: {
      type: Boolean,
      default: false,
    },
    isSetFooter: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    close: function (type) {
      if (this.isDisabledOverlay && type === "overlay") {
        return;
      }
      if (!this.isDisabledClose) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  &Overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $LAYER_MODAL;
  }
  &HeaderWindow {
    background: $COLOR_DISABLE_TEXT;
    border-radius: $BOX_RADIUS;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    max-height: calc(100% - #{$GRID} * 2);
    max-width: calc(100% - #{$GRID} * 2);
    overflow: hidden;
  }
  &Content {
    box-sizing: border-box;
    overflow: auto;
    .isImageList {
      min-width: $IMAGE_WIDTH * 4 + $GRID * 3;
      max-width: $IMAGE_WIDTH * 6 + $GRID * 3;
      @media screen and (max-width: $IMAGE_WIDTH * 6 + $GRID * 5) {
        max-width: $IMAGE_WIDTH * 5 + $GRID * 3;
      }
      @media screen and (max-width: $IMAGE_WIDTH * 5 + $GRID * 5) {
        max-width: $IMAGE_WIDTH * 4 + $GRID * 3;
      }
    }
    &Inr {
      padding: $GRID;
    }
  }
  &Footer {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    height: $GRID * 4;
    justify-content: flex-end;
    padding: $GRID;
  }
  &Header {
    align-items: center;
    background: $BG_COLOR_BOX;
    box-shadow: $DEPTH_LV_0;
    color: $COLOR_KEY;
    display: flex;
    flex: 0 0 auto;
    height: $GRID * 3;
    padding: $GRID * 0.5 $GRID;
    position: relative;
    text-align: center;
    &_cancel {
      -webkit-appearance: none;
      border: none;
      color: $COLOR_SUB_LIGHT_TEXT;
      outline: none;
      position: absolute;
      right: $GRID;
      text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      top: 12px;
      &:hover {
        text-shadow: none;
      }
      &:disabled {
        box-shadow: none;
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &_icon {
      font-size: 20px;
      margin-right: $GRID * 0.5;
      &.isLarge {
        font-size: 30px;
      }
    }
    &_title {
      font-size: 24px;
      margin-top: 4px;
      &.isSmall {
        font-size: 16px;
      }
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.6s;
  .modalWindow {
    transition: opacity 0.6s, transform 0.6s;
  }
}
.modal-enter,
.modal-leave-to {
  transition: opacity 0.3s;
  opacity: 0;
  .modalWindow {
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    transform: translateY(-20px);
  }
}
</style>
