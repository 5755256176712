<template>
  <div class="input-url">
    <input-text
      v-model="url"
      :text="url"
      :has-error="!isDisabled && !isValid"
      :has-limit="false"
      :is-disabled="isDisabled"
    />
    <p class="input-url__notes">
      <span v-if="errorNotes" class="input-url__error">{{ errorNotes }}</span>
      <template v-else-if="textLengthNotes">
        {{ textLengthNotes }}
      </template>
    </p>
  </div>
</template>

<script>
import InputText from "@/components/atoms/common/InputText.vue";
import isValidUrl from "@/lib/isValidUrl";
import isValidCompleteUrl from "@/lib/isValidCompleteUrl";

export default {
  components: {
    InputText,
  },
  model: {
    event: "input",
  },
  props: {
    text: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    disabledText: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    maxLength: {
      // 最大文字数
      type: Number,
      default: null,
    },
    showLength: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url: {
      get() {
        return this.text;
      },
      set(url) {
        if (url === "") url = null;
        this.$emit("input", {
          url,
          // 説明：以下はフェア詳細用に残しておく
          // プラン詳細では使わないようにする
          isValid: isValidUrl(url),
        });
        this.checkUrl(url);
      },
    },
    errorNotes() {
      if (this.isDisabled) {
        return this.disabledText || null;
      }

      const urlLength = this.url ? this.url.length : 0;
      if (this.showLength && urlLength > this.maxLength) {
        return `${this.url.length - this.maxLength}字オーバーしています`;
      }

      if (this.isRequired && urlLength === 0) {
        return "この項目は入力必須です";
      }

      if (!this.isValid) {
        return "正しいURLを入力してください";
      }

      return null;
    },
    textLengthNotes() {
      if (!this.showLength) return null;

      const urlLength = this.url ? this.url.length : 0;
      return `文字数: ${urlLength}/${this.maxLength}`;
    },
  },
  watch: {
    url: {
      immediate: true,
      handler() {
        this.checkUrl(this.url);
      },
    },
    isRequired: {
      immediate: true,
      handler() {
        this.checkUrl(this.url);
      },
    },
  },
  methods: {
    checkUrl(url) {
      const urlLength = url ? url.length : 0;

      this.$emit("check", {
        isValid: isValidUrl(url),
        isComplete: isValidCompleteUrl(url),
        isOverMaxLength: urlLength > this.maxLength,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.input-url {
  width: 100%;

  &__notes {
    font-size: $FONT_SIZE_SS;
    height: $FONT_SIZE_SS;
    margin-top: $SUB_GRID;
    text-align: right;
  }

  &__error {
    color: $COLOR_ALERT;
  }
}
</style>
