<template>
  <input
    class="input"
    ref="input"
    type="text"
    v-model="innerValue"
    :class="className"
    :disabled="isDisabled"
    :required="myRequired"
    :placeholder="placeholder"
    @keyup="onKeyup($event.target.value)"
    @blur="onBlur($event.target.value)"
  />
</template>

<script>
import Vue from "vue";

/**
 * 数字を文字列に変換する
 */
const numberToString = (number, minDigits) => {
  const numberString = String(number);
  if (numberString && numberString.length >= minDigits) {
    return numberString.replace(/\D/g, "");
  }
  return "";
};

/**
 * 入力されている値のバリデーションを実施する
 */
const validateNumber = (number, minDigits, isRequired, needValidate) => {
  if (!needValidate && !isRequired) {
    return true;
  }

  const numberString = numberToString(number, minDigits);
  return numberString != "" ? true : false;
};

export default {
  model: {
    event: "input",
  },
  props: {
    value: [String, Number],
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    maxDigits: {
      type: Number,
      default: null,
    },
    minDigits: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: "",
    },
    /*
     * バリデーションチェックをするか
     * - trueにすると、requiredじゃない場合でもValidかどうかを返すようになる
     * - isRequiredの場合は設定不要
     */
    needValidate: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      innerValue: this.value,
      tempValue: null,
      tempRequired: null,
      isValid: null,
    };
  },
  computed: {
    myRequired() {
      const isRequired = this.isRequired;
      this.CheckValidation(this.value);
      this.tempRequired = isRequired;
      return isRequired;
    },
    className() {
      return {
        hasError: !this.isValid || this.hasError,
        "input--double-small": this.size == "ss",
        "input--triple-small": this.size == "sss",
      };
    },
  },
  watch: {
    value: function () {
      if (this.value != this.innerValue) {
        this.innerValue = this.value;
      }
    },
  },
  methods: {
    CheckNumeric(input) {
      // 入力された値に対して以下の置換処理を実施
      // 1. 全角数字を半角数字に置換
      // 2. 数字以外の文字列を消去
      const number = input
        .replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        })
        .replace(/\D/g, "")
        .replace(/(^0)([0-9]+$)/, "$2");

      // 桁数制限がある場合に値を丸め込む
      const sliceLength = this.maxDigits || number.length;
      const slicedNumber = number.slice(0, sliceLength);
      const value = slicedNumber !== "" ? parseInt(slicedNumber) : null;

      this.innerValue = value;
      this.$emit("input", value);
      this.CheckValidation(value);
    },
    CheckValidation(number) {
      this.isValid = validateNumber(
        number,
        this.minDigits,
        this.isRequired,
        this.needValidate
      );
      this.$emit("check", this.isValid);
    },
    onKeyup(input) {
      this.CheckNumeric(input);
      this.$emit("keyup", this.innerValue);
    },
    onBlur(input) {
      this.CheckNumeric(input);
      this.$emit("blur", this.innerValue);
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  @include commonInputField();
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  width: 8rem;

  &--double-small {
    width: 4rem;
  }

  &--triple-small {
    width: 5rem;
    font-size: $FONT_SIZE_S;
    padding: $SUB_GRID;
  }

  &::placeholder {
    text-align: center;
  }
}
</style>
