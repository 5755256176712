<template>
  <div class="listUnitHead">
    <fair-id
      v-if="isIdCheck"
      :id="id"
      :is-checked="isChecked"
      @update="updateId"
    />
    <p v-else class="listUnitHead_id fontNum" :class="{ isSmallId }">
      #{{ id }}
    </p>
    <dl class="listUnitHead_update">
      <dt class="listUnitHead_update_tit">最終更新日：</dt>
      <dd class="listUnitHead_update_date fontNum">{{ updateDateText }}</dd>
    </dl>
  </div>
</template>

<script>
import formatDateText from "@/lib/formatDateText";
import FairId from "@/components/atoms/common/FairId.vue";

export default {
  components: {
    FairId,
  },
  props: {
    id: Number,
    date: String,
    isIdCheck: {
      type: Boolean,
      default: false,
    },
    isChecked: Boolean,
    isSmallId: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    updateDateText: function() {
      return formatDateText(this.date) || "記録なし";
    },
  },
  methods: {
    updateId: function(id, isChecked) {
      this.$emit("updateId", id, isChecked);
    },
  },
};
</script>

<style scoped lang="scss">
.listUnitHead {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  &_id.isSmallId {
    color: $COLOR_SUB_TEXT;
    font-size: 14px;
  }
  &_update {
    color: $COLOR_SUB_LIGHT_TEXT;
    font-size: 10px;
    &_tit,
    &_date {
      display: inline;
    }
    &_date {
      font-size: 12px;
    }
  }
}
</style>
