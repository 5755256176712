/**
 * httpClient
 */
import {
  create,
  getBaseUrl,
  checkResponseStatus,
  needsUpdateConfig,
  updateGlobal,
  updateRequest,
  isTest,
} from "@/lib/httpClientCommon";

// インスタンス作成
let httpClientDefault = create();
// リクエスト共通処理
httpClientDefault.interceptors.request.use((request) => {
  if (isTest()) console.log("リクエスト：", request);
  const needs = needsUpdateConfig(request);
  if (needs.client) httpClientDefault = updateGlobal(httpClientDefault);
  if (needs.request) request = updateRequest(request);
  if (isTest()) console.log("リクエスト処理終了");
  return request;
});
// レスポンス共通処理
httpClientDefault.interceptors.response.use(
  (response) => {
    if (isTest()) console.log("レスポンス：", response);
    checkResponseStatus(response);
    if (isTest()) console.log("レスポンス処理終了");
    return response;
  },
  (error) => {
    const e = error && error.response ? error.response : error;
    if (isTest()) console.log("レスポンスエラー：", e);
    checkResponseStatus(error);
    if (isTest()) console.log("レスポンス処理終了");
    return e;
  }
);
/**
 * ベースURLを強制セット
 * @param {Number} id {ベースURL}/{id} null:ベースURLのみ
 */
export const setForcedBaseUrl = (id) => {
  httpClientDefault.defaults.baseURL = getBaseUrl(id, true);
};

export const httpClient = httpClientDefault;
