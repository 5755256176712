<template>
  <div class="navLinkContact" @click="$emit('click')">
    <div class="navLinkContact_inr" :class="{ toggled }">
      <span class="navLinkContact_icon material-icons">help_center</span>
      <p class="navLinkContact_txt">お困りですか？</p>
      <p class="navLinkContact_tel fontNum">{{ tel }}</p>
      <p class="navLinkContact_time">
        受付時間
        <br />
        {{ time }}
      </p>
      <div class="navLinkContact_toggle"></div>
    </div>
    <action-button
      class="navLinkContact_btn"
      text="お問い合わせ"
      @clickHandler="showContact"
    />
    <action-button
      v-if="!isVendor"
      class="navLinkContact_btn"
      text="マニュアル"
      @clickHandler="showManual"
    />
    <icon-button
      class="navLinkContact_btn voice"
      icon="chat"
      text="ご意見・ご要望"
      :with-bg="false"
      @clickHandler="showVoice"
    />

    <button
      class="navLinkContact_shortBtn"
      title="お問い合わせ"
      @click.stop="showContact"
    >
      <span class="navLinkContact_shortBtnIcon material-icons"
        >help_center</span
      >
    </button>
    <button
      v-if="!isVendor"
      class="navLinkContact_shortBtn"
      title="マニュアル"
      @click.stop="showManual"
    >
      <span class="navLinkContact_shortBtnIcon isSmaller material-icons"
        >menu_book</span
      >
    </button>
    <button
      class="navLinkContact_shortBtn"
      title="ご意見・ご要望"
      @click.stop="showVoice"
    >
      <span class="navLinkContact_shortBtnIcon isSmaller material-icons"
        >chat</span
      >
    </button>

    <modal-contact v-if="contactIsShow" />
    <modal-manual v-if="manualIsShow" />
    <modal-voice v-if="voiceIsShow" />
  </div>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import IconButton from "@/components/atoms/common/IconButton.vue";
import ModalContact from "@/components/organisms/common/ModalContact.vue";
import ModalManual from "@/components/organisms/common/ModalManual.vue";
import ModalVoice from "@/components/organisms/common/ModalVoice.vue";

import MASTER_CONFIG from "@/assets/common/MasterConfig";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    ActionButton,
    IconButton,
    ModalContact,
    ModalManual,
    ModalVoice,
  },
  props: {
    toggled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("utility/contact", { contactIsShow: (state) => state.isShow }),
    ...mapState("utility/manual", { manualIsShow: (state) => state.isShow }),
    ...mapState("utility/voice", { voiceIsShow: (state) => state.isShow }),
    ...mapGetters({
      role: "common/role",
      isVendor: "common/isVendor",
    }),
    tel: function() {
      return MASTER_CONFIG.tel;
    },
    time: function() {
      return MASTER_CONFIG.time;
    },
  },
  watch: {
    role: {
      immediate: true,
      handler(role) {
        if (role === null || this.isVendor) return;
        this.initManual();
      },
    },
  },
  methods: {
    ...mapActions("utility/contact", ["showModalContact"]),
    ...mapActions("utility/manual", {
      initManual: "getInitialData",
      showModalManual: "showModalManual",
    }),
    ...mapActions("utility/voice", ["showModalVoice"]),
    showContact: function() {
      this.showModalContact();
    },
    showManual: function() {
      this.showModalManual();
    },
    showVoice: function() {
      this.showModalVoice();
    },
  },
};
</script>

<style scoped lang="scss">
.navLinkContact {
  @include gradientMain();
  border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
  box-shadow: $DEPTH_LV_0;
  color: $COLOR_KEY;
  padding: $GRID * 0.5;
  &_toggle {
    position: absolute;
    right: 0;
    top: 0;
    width: $GRID;
    height: $GRID;
    border-radius: 50%;
    background: $COLOR_KEY;
    color: #fff;
    font-size: $GRID;
    vertical-align: top;
    display: none;

    @media screen and (min-width: $MAIN_WIDTH) {
      display: block;
    }

    &::after {
      font-family: "Material Icons";
      content: "\e5c7";

      .toggled & {
        font-family: "Material Icons";
        content: "\e5c5";
      }
    }
  }
  &_inr {
    padding: 0 0 $GRID * 0.5 $GRID * 1.5;
    position: relative;
  }
  &_icon {
    font-size: 22px;
    left: 0;
    position: absolute;
    top: 0;
  }
  &_txt {
    font-size: 13px;
  }
  &_tel {
    font-size: 24px;
    letter-spacing: -0.02em;
  }
  &_time {
    font-size: 11px;
    line-height: 1.4;
    white-space: nowrap;

    @media screen and (min-width: $MAIN_WIDTH) {
      margin-bottom: $GRID;

      .toggled & {
        margin-bottom: initial;
      }
    }
  }
  &_btn {
    width: 100%;
    &.voice {
      padding: 1px 0;
      ::v-deep .material-icons {
        margin-top: 1px;
      }
    }
    & + & {
      margin-top: $SUB_GRID * 1;
    }
  }
  &_shortBtn {
    -webkit-appearance: none;
    border: none;
    box-sizing: border-box;
    display: none;
    outline: none;
    margin: $SUB_GRID auto;
    text-align: center;
    transition: opacity 0.1s;
    width: 100%;
    &Icon {
      font-size: 28px;
      &.isSmaller {
        font-size: 27px;
      }
    }
    &:hover {
      opacity: 0.5;
    }
    & + & {
      margin-top: $GRID * 0.5;
    }
  }
  @media screen and (max-width: $MAIN_WIDTH) {
    background: none;
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    &_inr {
      padding: 0;
    }
    &_shortBtn {
      display: inline-block;
    }
    &_icon {
      display: none;
    }
    &_txt,
    &_tel,
    &_time,
    &_btn {
      display: none;
    }
  }
}
</style>
