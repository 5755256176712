/**
 * セットアップ時の状態を管理するモジュール
 */
const Setup = {
  namespaced: true,
  state: {
    errorTexts: {
      delete: "削除に失敗しました　時間をおいて再度お試しください",
      get: "データ取得に失敗しました　時間をおいて再度お試しください",
      save: "保存に失敗しました　時間をおいて再度お試しください",
    },
  },
};

export default Setup;
