<template>
  <div class="jobTabUnit">
    <p class="supplement">※ 媒体の都合により、サイトへの反映に時間がかかることがあります</p>
    <tab-btn
      v-for="(v, i) in tab.key"
      :key="i"
      :text="v"
      :is-active="i == tab.active"
      :num="i == 'draft' ? draftCount : null"
      @tabChange="tabChange(i)"
    />
  </div>
</template>

<script>
// component
import TabBtn from "@/components/atoms/common/TabButton.vue"; // store
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TabBtn,
  },
  computed: {
    ...mapGetters("job", ["tab"]),
  },
  methods: {
    ...mapActions("job", ["updateTabKey", "getExecutedJobsApi"]),
    ...mapActions("job/filter", ["updateFilterType", "initialize"]),
    tabChange: async function(key) {
      this.updateTabKey(key);
      this.updateFilterType(key);
      this.initialize();
      if (key != "executed") this.getExecutedJobsApi(); // 更新履歴タブから移動した時にデータを初期化
    },
  },
};
</script>

<style scoped lang="scss">
.jobTabUnit {
  margin-bottom: $GRID;
}

.supplement {
  margin-bottom: $SUB_GRID;
  font-size: $FONT_SIZE_S;
  color: $COLOR_KEY;
}
</style>
