<template>
  <transition name="modal" appear>
    <div class="importWindow">
      <span>選択した{{ targetName }}を</span>
      <action-button
        class="associateButton"
        :is-import="true"
        :is-check="true"
        :is-save="false"
        text="確認する"
        @clickHandler="check"
      />
      <action-button
        class="associateButton"
        :is-import="true"
        :is-save="true"
        :is-disabled="isDisabledSave"
        :text="buttonText"
        @clickHandler="click"
      />
      <action-button
        class="cancelButton"
        :is-import="true"
        :is-cancel="true"
        :text="cancelButtonText"
        @clickHandler="cancel"
      />
    </div>
  </transition>
</template>

<script>
// component
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
  },
  props: {
    buttonText: {
      type: String,
      default: "登録する",
    },
    cancelButtonText: {
      type: String,
      default: "解除する",
    },
    targetName: {
      type: String,
      default: "対象",
    },
    isDisabledSave: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click: function() {
      this.$emit("click");
    },
    cancel: function() {
      this.$emit("cancel");
    },
    check: function() {
      this.$emit("check");
    },
  },
};
</script>

<style scoped lang="scss">
.importWindow {
  align-items: center;
  background: $COLOR_IMPORT_SELECTED;
  border-radius: $BOX_RADIUS;
  box-shadow: $DEPTH_LV_2;
  bottom: $GRID;
  color: #fff;
  display: flex;
  justify-content: space-between;
  right: $GRID;
  padding: $GRID;
  position: fixed;
  z-index: $LAYER_FLOAT_WINDOW;
  .btn {
    margin-left: $GRID;
  }
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.6s;
  .modalWindow {
    transition: opacity 0.6s, transform 0.6s;
  }
}
.modal-enter,
.modal-leave-to {
  transition: opacity 0.3s;
  opacity: 0;
  .modalWindow {
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    transform: translateY(-20px);
  }
}
</style>
