<template>
  <ul class="radioList" :class="{ hasError: !isValid, isDisabled }">
    <li v-for="(item, i) in items" class="radioList_item" :key="i">
      <label class="radioWrapper">
        <input
          class="inputRadio"
          type="radio"
          v-model="selfValue"
          :disabled="isDisabled"
          :value="i"
          @change="sendInput"
        />
        <span class="radioText">{{ item }}</span>
      </label>
    </li>
  </ul>
</template>

<script>
// TODO: InputRadio.vue がページごとに存在する
export default {
  model: {
    event: "input",
  },
  props: {
    value: Number,
    items: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      selfValue: this.value,
      tmpIsValid: null,
    };
  },
  computed: {
    isValid() {
      let isValid;
      if (!this.isRequired) {
        isValid = true;
      } else {
        isValid = this.selfValue != null ? true : false;
      }
      if (isValid !== this.tmpIsValid) {
        this.$emit("check", isValid);
        this.tmpIsValid = isValid;
      }
      return isValid;
    },
  },
  methods: {
    sendInput(event) {
      this.$emit("input", Number(event.target.value));
    },
  },
};
</script>

<style scoped lang="scss">
.radioList {
  display: flex;
  list-style: none;
  &_item {
    margin-right: 1em;
  }
  .radioWrapper {
    cursor: pointer;
    padding: 5px;
  }
  .inputRadio {
    cursor: pointer;
  }
  .radioText {
    margin-left: 5px;
  }
  &.isDisabled {
    color: $COLOR_DISABLE_TEXT;
    cursor: not-allowed;
    .radioWrapper {
      pointer-events: none;
    }
  }
}
</style>
