<template>
  <benefit-form
    class="benefit-form"
    title="限定特典"
    :note="onlyMediaName(keyName)"
    :option-level="optionLevel([keyName])"
  >
    <label class="checkbox" @click.prevent="value = !value">
      <input-checkbox :is-selected="value" />
      <span class="checkbox__text">マイナビ限定特典にする</span>
    </label>
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import InputCheckbox from "@/components/atoms/common/InputCheckbox.vue";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";

import * as types from "@/store/benefit/mutationTypes";

export default {
  components: {
    InputCheckbox,
    BenefitForm,
  },
  data() {
    return {
      keyName: "is_media_only",
      mediaId: API_CODE.media.mynavi,
    };
  },
  computed: {
    ...mapGetters({
      mediaOnly: "mediaOnly",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
    }),
    value: {
      get() {
        return this.mediaOnly(this.mediaId);
      },
      set(value) {
        this.updateMediaOnly(value);
      },
    },
  },
  methods: {
    ...mapMutations({ updateMediaOnly: `${types.MEDIA_ONLY}` }),
  },
};
</script>

<style scoped lang="scss">
.benefit-form {
  .checkbox {
    align-items: center;
    display: flex;

    &__text {
      margin-left: $SUB_GRID;
      font-size: 13px;
    }
  }
}
</style>
