<template>
  <div class="block">
    <div class="blockTop">
      <h1 class="blockTit">画像一覧</h1>
      <action-button class="refreshBtn" text="最新の情報に更新する" @clickHandler="init" />
    </div>
    <div class="blockImg">
      <image-unit
        class="blockImgUnit"
        mode="select"
        v-for="(v, i) in filteredData"
        :key="i"
        :image="v"
        :selected="selected"
        :target-media-ids="targetMediaIds"
        :target-urls="targetUrls"
        :need-all-urls="needAllUrls"
        @select="setSelect"
      />
    </div>
  </div>
</template>

<script>
// component
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import ImageUnit from "@/components/organisms/image-list/ImageUnit.vue";
// store
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ActionButton,
    ImageUnit,
  },
  model: {
    prop: "selected",
    event: "input",
  },
  props: {
    selected: {
      type: [Number, String],
      default: -1,
    },
    targetMediaIds: {
      type: Array,
      default: () => [],
    },
    targetUrls: {
      type: Array,
      default: () => [],
    },
    needAllUrls: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("filter", ["filteredData"]),
    ...mapGetters(["count", "master", "medias"]),
  },
  methods: {
    ...mapActions({
      init: "getInitialData",
    }),
    setSelect: function(image) {
      this.$emit("input", image.id);
      this.$emit("output", image);
    },
  },
};
</script>

<style scoped lang="scss">
.block {
  color: $COLOR_KEY;
  font-size: 12px;
  padding: 0 $GRID * 0.25; // hover時のoutline
  &Tit {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: $GRID * 0.5;
  }
  &Top {
    display: flex;
    .refreshBtn {
      margin-left: $GRID;
      height: 24px;
    }
  }
}
.blockImg {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$GRID;
  margin-bottom: -$GRID * 1.5;
  &Unit {
    margin-left: $GRID;
    margin-bottom: $GRID * 1.5;
  }
}
</style>
