<template>
  <input-form :title="title" :option-level="optionLevel([keyName])">
    <template v-slot:content>
      <input-item>
        <template v-slot:input>
          <input-radio
            v-model="value"
            :items="master.item"
            :is-required="rules[keyName].is_required"
            @check="sendValidation"
          />
        </template>
      </input-item>
    </template>
  </input-form>
</template>

<script>
import InputForm from "@/components/molecules/contact/InputForm.vue";
import InputItem from "@/components/molecules/contact/InputItem.vue";
import InputRadio from "@/components/atoms/contact/InputRadio.vue";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    InputForm,
    InputItem,
    InputRadio,
  },
  props: {
    title: String,
    keyName: String,
  },
  computed: {
    ...mapState("utility/contact", ["rules", "data", "master"]),
    ...mapGetters("utility/contact", ["optionLevel"]),
    value: {
      get: function() {
        return this.data[this.keyName];
      },
      set: function(value) {
        this.setContactData({ keyName: this.keyName, value });
      },
    },
  },
  methods: {
    ...mapActions("utility/contact", ["setContactData", "setValidation"]),
    /**
     * validationModuleに入力情報を送信
     * @param isValid 入力済みフラグ false: 未入力 or エラー
     */
    sendValidation(isValid) {
      this.setValidation({ key: this.keyName, isValid });
    },
  },
};
</script>

<style scoped lang="scss"></style>
