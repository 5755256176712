import deepCopy from "@/lib/deepCopy";

import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

const getters = {
  isLoadEdit: ({ isLoadEdit }) => isLoadEdit,
  isUsed: ({ isUsed }) => isUsed,
  anchor: ({ anchor }) => anchor,
  anchorHash: ({ anchor }) => anchor.hash,
  anchorId: ({ anchor }) => anchor.id,
  benefits: ({ benefit }) => benefit,
  benefit:
    ({ benefit }) =>
    (id) =>
      Object.values(benefit)
        .flat()
        .find((item) => item.id === id) || null,
  listHeaderText: (_, getters) => (groupType) => {
    const ids = getters.mediaIdsByGroupType(parseInt(groupType));
    const mediaNames = ids
      .map((mediaId) => getters.media[mediaId].short_name)
      .join("・");
    return `${mediaNames}用`;
  },
  master: ({ master }) => master,
  media: ({ master }) => master.media,
  setting: ({ setting }) => setting,
  mediaIdsByGroupType:
    ({ master }, getters) =>
    (groupType) => {
      const ids = master.group_type[groupType].id || [];
      if (ids.length > 0) {
        return getters.filteredMediaIdsByCoremonet(ids, groupType);
      }
      return ids;
    },
  benefitTypeOptions:
    ({ master }) =>
    (groupType) =>
      master.type[groupType],
  benefitItem: (state) => (benefitId) => {
    if (state.benefit == undefined) return null;

    let benefit;
    Object.values(state.benefit).forEach((group) => {
      if (benefit != undefined) return;
      benefit = group.find((benefit) => benefit.id === benefitId);
    });
    return benefit;
  },
  image: (s, g, rootState) => (imageId) => {
    const images = rootState.imageSelect.originals;
    const hasImages = images && Object.values(images).length;
    const image = hasImages ? images[imageId] || {} : {};
    return deepCopy(image);
  },
  // 式場特典を利用できるかどうかを取得する
  enabledHallBenefits: (_, getters) => {
    const name = "hallBenefits";
    const type = "edit";
    return getters["common/isAuthorized"]({ name, type });
  },
  // フェア限定とWebつく限定でないウエパの特典を取得する
  wpBenefits: (_, getters) => {
    const mediaId = API_CODE.media.wp;
    const benefits = getters.benefits[BENEFIT_GROUP_TYPE.wp].filter(
      ({ media_ids, is_limited_to_fair, is_limited_to_webtsuku }) => {
        const isWp = media_ids.includes(API_CODE.media.wp);
        const isLimitedToFair = is_limited_to_fair[mediaId];
        const isLimitedToWebtsuku = is_limited_to_webtsuku[mediaId];
        return isWp && !isLimitedToFair && !isLimitedToWebtsuku;
      }
    );
    return benefits;
  },
  showPublishModal: ({ showPublishModal }) => showPublishModal,
};

export default getters;
