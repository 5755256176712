<template>
  <div class="datepicker" :class="{ hasError: !isValid }">
    <datepicker
      v-model="currentDate"
      :name="name"
      :disabled-dates="disabledDates"
      :inline="isShowOnlyCalender"
      :language="ja"
      :required="isRequired"
      :clear-button="!isRequired"
      format="yyyy-MM-dd"
      maximum-view="day"
    />
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    Datepicker,
  },
  model: {
    event: "input",
  },
  props: {
    value: String,
    name: String,
    disabledDates: {
      type: Object,
      default: () => {},
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isCheckingRequired: {
      type: Boolean,
      default: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isShowOnlyCalender: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      ja: ja,
    };
  },
  computed: {
    currentDate: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        let formatValue = null;
        if (value) {
          // 0埋め
          const month = ("0" + (value.getMonth() + 1)).slice(-2);
          const day = ("0" + value.getDate()).slice(-2);
          formatValue = value.getFullYear() + "-" + month + "-" + day;
        }
        this.$emit("input", formatValue);
      },
    },
    hasRequiredError: function () {
      if (this.hasError) return true;
      if (!this.isCheckingRequired || !this.isRequired) {
        return false;
      }
      return this.value ? false : true;
    },
    isValid: function () {
      let isValid = this.hasRequiredError ? false : true;
      this.sendIsValid(isValid);
      return isValid;
    },
  },
  methods: {
    sendIsValid: function (isValid) {
      this.$emit("check", isValid);
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  display: inline-block;
  font-size: 14px;
  .vdp-datepicker__calendar {
    width: 28 * 7px;
    z-index: $LAYER_DATEPICKER;
    .cell {
      height: 28px;
      line-height: 28px;
    }
  }
  .sat {
    color: $COLOR_SATURDAY;
  }
  .sun {
    color: $COLOR_SUNDAY;
  }
  .vdp-datepicker__clear-button {
    line-height: 30px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 30px;
  }
  input[type="text"] {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: block;
    outline: none;
    padding: 7px 10px 6px;
    width: 110px;
  }
  &.hasError {
    input[type="text"] {
      background: #fee;
    }
  }
}
</style>
