const getters = {
  /**
   * 必須かどうか
   */
  optionLevel: (state) => (keyNames) => {
    const isRequired = keyNames.some((k) => state.rules[k].is_required);
    return isRequired ? 2 : 1;
  },
  /**
   * 指定の項目が正常に入力されているか
   */
  isValid: (state) => (key) => {
    return state.validation.validItems.indexOf(key) >= 0;
  },
};

export default getters;
