<template>
  <input-form :title="title" :is-small-title="isSmallTitle" :option-level="optionLevel([keyName])">
    <template v-slot:content>
      <input-item>
        <template v-slot:input>
          <validating-textarea
            v-model="value"
            :is-full-size="true"
            :is-textarea="isTextarea"
            :is-required="rules[keyName].is_required"
            :max-length="rules[keyName].max_characters"
            :text="value"
            :placeholder="placeholder"
            @check="sendValidation"
          />
        </template>
      </input-item>
    </template>
  </input-form>
</template>

<script>
import InputForm from "@/components/molecules/contact/InputForm";
import InputItem from "@/components/molecules/contact/InputItem";
import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    InputForm,
    InputItem,
    ValidatingTextarea,
  },
  props: {
    title: String,
    keyName: String,
    placeholder: {
      text: String,
      default: null,
    },
    isTextarea: {
      text: Boolean,
      default: false,
    },
    isSmallTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("utility/voice", ["rules", "data"]),
    ...mapGetters("utility/voice", ["optionLevel"]),
    value: {
      get: function() {
        return this.data[this.keyName];
      },
      set: function(value) {
        this.setVoiceData({ keyName: this.keyName, value });
      },
    },
  },
  methods: {
    ...mapActions("utility/voice", ["setVoiceData", "setValidation"]),
    /**
     * validationModuleに入力情報を送信
     * @param isValid 入力済みフラグ false: 未入力 or エラー
     */
    sendValidation(isValid) {
      this.setValidation({ key: this.keyName, isValid });
    },
  },
};
</script>
