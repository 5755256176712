import getters from "@/store/voice/getters";
import mutations from "@/store/voice/mutations";
import actions from "@/store/voice/actions";

import modalModule from "@/store/modal";

const state = {
  isShow: false,
  init: {
    content: "",
    customer_name: "",
    customer_tel: null,
    customer_mail: "",
    customer_time: null,
  },
  data: {
    content: "",
    customer_name: "",
    customer_tel: null,
    customer_mail: "",
    customer_time: null,
  },
  validation: {
    requiredItems: [], // 必須項目
    completedRequiredItems: [], // 入力済み必須項目
    validItems: [], // 入力済み項目
    itemsHasError: [], // エラーのある項目
  },
  rules: {
    content: {
      is_required: true,
      max_characters: 1000,
    },
    customer_name: {
      is_required: true,
      max_characters: 30,
    },
    customer_tel: {
      is_required: true,
    },
    customer_mail: {
      is_required: true,
      max_characters: 100,
    },
    customer_time: {
      is_required: false,
      max_characters: 100,
    },
  },
};

const voiceModule = {
  namespaced: true,
  modules: {
    modal: modalModule,
  },
  state,
  getters,
  mutations,
  actions,
};

export default voiceModule;
