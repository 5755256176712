<template>
  <datepicker
    :value="value"
    :class="classType"
    :name="keyName"
    :date="value"
    :disabled-dates="disabledDates"
    :is-required="isRequired"
    :is-disabled="isDisabled"
    :has-error="hasError"
    @input="$emit('input', $event)"
    @check="$emit('check', $event && !isExpired)"
  />
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

import Datepicker from "@/components/atoms/common/Datepicker.vue";

export default {
  components: {
    Datepicker,
  },
  props: {
    keyName: String,
    media: Number,
    value: String,
    disabledDates: Object,
    isRequired: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hasWarning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExpired() {
      if (this.date) {
        let v = this.date.split("-");
        v = v.map((str) => parseInt(str, 10));
        v = new Date(v[0], v[1] - 1, v[2]);
        if (this.disabledDates.from && v > this.disabledDates.from) {
          return true;
        }
        if (this.disabledDates.to && v < this.disabledDates.to) {
          return true;
        }
      }
      return false;
    },
    classType() {
      return {
        isExpired: this.isExpired,
        hasWarning: this.hasWarning,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.list-item {
  display: inline-block;
  font-size: 14px;
  &.block {
    display: block;
  }
}
.datepicker.isExpired {
  ::v-deep input[type="text"] {
    background: $BG_COLOR_ALERT;
  }
}
.datepicker.hasWarning {
  ::v-deep input[type="text"] {
    background: $BG_COLOR_WARNING;
  }
}
</style>
