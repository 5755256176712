<template>
  <modal-header-window
    class="modalContact"
    icon="help_center"
    title="お問い合わせ"
    :is-large-icon="true"
    :is-small-title="true"
    :is-disabled-close="false"
    :is-set-footer="true"
    @close="close"
  >
    <template #content>
      <div class="modalContactInr">
        <contact-detail />
        <div class="modalContactForm">
          <form-item title="お問い合わせ内容" key-name="item" />
          <form-text title="詳細" key-name="content" />
          <customer-text title="ご担当者名" keyName="customer_name" />
          <customer-tel title="電話番号" keyName="customer_tel" />
          <customer-mail title="メールアドレス" keyName="customer_mail" />
          <customer-text
            title="お電話がつながりやすい<br>曜日・時間帯など"
            keyName="customer_time"
            placeholder="例）月・水曜日の15時～16時"
            :is-textarea="true"
            :is-small-title="true"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <action-button
        text="送信する"
        :is-disabled="!isAllValid"
        :is-save="true"
        @clickHandler="send"
      />
    </template>
  </modal-header-window>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton";
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow";
import ContactDetail from "@/components/molecules/common/ContactDetail";
import FormItem from "@/components/organisms/contact/FormItem.vue";
import FormText from "@/components/organisms/contact/FormText.vue";
import CustomerText from "@/components/organisms/contact/CustomerText";
import CustomerTel from "@/components/organisms/contact/CustomerTel";
import CustomerMail from "@/components/organisms/contact/CustomerMail";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    ActionButton,
    ModalHeaderWindow,
    ContactDetail,
    FormItem,
    FormText,
    CustomerText,
    CustomerTel,
    CustomerMail,
  },
  props: {},
  computed: {
    ...mapState("utility/contact", ["validation"]),
    isAllValid: function () {
      if (this.validation.itemsHasError.length > 0) return false;
      return (
        this.validation.completedRequiredItems.length ==
        this.validation.requiredItems.length
      );
    },
  },
  methods: {
    ...mapActions("utility/contact", ["closeModalContact", "sendData"]),
    close: function () {
      this.closeModalContact();
    },
    send: function () {
      this.sendData();
    },
  },
};
</script>

<style scoped lang="scss">
.modalContact {
  ::v-deep .modalContentInr {
    width: $GRID * (39 + 17 + 1 + 2);
  }
  &Inr {
    align-items: start;
    display: flex;
  }
  &Form {
    flex: 1;
    margin-left: $GRID;
  }
}
</style>
