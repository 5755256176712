<template>
  <div class="inputItem">
    <dl class="item" v-if="text">
      <dt class="item_title">
        <input-option-icon class="item_icon" size="s" :option-level="optionLevel" />
        <div>
          <p v-html="text"></p>
          <div class="item_note" v-if="note">{{ note }}</div>
        </div>
      </dt>
      <dd class="item_block">
        <slot name="input" />
      </dd>
    </dl>
    <div class="item_block" v-else>
      <slot name="input" />
    </div>
  </div>
</template>

<script>
import InputOptionIcon from "@/components/atoms/common/InputOptionIcon.vue";

export default {
  components: {
    InputOptionIcon,
  },
  props: {
    note: {
      type: String,
      default: "",
    },
    optionLevel: {
      type: Number,
      default: 0,
    }, // 0:アイコン無し 1:任意 2:必須
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.inputItem {
  display: flex;
  padding: $GRID * 0.5 $GRID;
  .item {
    display: flex;
    width: 100%;
    &_block {
      align-items: center;
      display: flex;
      flex: 1;
      min-height: $GRID * 4;
    }
    &_icon {
      margin-right: $GRID * 0.5;
    }
    &_note {
      font-size: 10px;
      font-weight: normal;
      margin-top: -2px;
    }
    &_title {
      align-items: center;
      color: $COLOR_KEY;
      display: flex;
      font-size: $FONT_SIZE_S;
      margin-left: -$GRID * 0.5;
      width: $GRID * 10;
    }
  }
}
</style>
