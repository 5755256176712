<template>
  <div class="inputFieldBox" :class="className">
    <input
      class="inputText"
      ref="input"
      v-model="selfText"
      type="text"
      name="email"
      autocomplete="email"
      :class="className"
      :disabled="isDisabled"
      :placeholder="placeholder"
      :required="isRequired"
      @keyup="convertFullAlphanumeric($event.target.value)"
    />
    <p class="inputFieldBox_count" v-if="hasLengthLabel">{{ textLengthMsg }}</p>
  </div>
</template>

<script>
// autocompleteを使つために name属性を消さないこと
export default {
  model: {
    event: "input",
  },
  props: {
    value: [String, Number],
    showLengthLabel: {
      type: Boolean,
      default: null,
    },
    maxLength: {
      type: Number,
      default: 100,
    },
    placeholder: {
      type: String,
      default: null,
    },
    isFullSize: {
      // width:100%で使用したい場合
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    /*
     * バリデーションチェックをするか
     * - trueにすると、requiredじゃない場合でもValidかどうかを返すようになる
     * - isRequiredの場合は設定不要
     */
    needValidate: {
      type: Boolean,
      default: false,
    },
    needRequireAlert: {
      // 入力エラー時に色を変えるか
      // 初回入力時等、エラー時に色を変えたくない場合はfalseにすること
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      tmpText: null,
      tmpIsValid: null,
    };
  },
  computed: {
    selfText: {
      get() {
        const text = this.value;
        return text == null ? "" : text;
      },
      set(value) {
        value = value === "" ? null : value; // 空文字はnullに統一
        if (value !== this.value) {
          this.$emit("input", value);
        }
      },
    },
    className() {
      return {
        isFullSize: this.isFullSize,
        hasError: !this.isValid,
        showLabel: this.showLengthLabel,
      };
    },
    hasLengthLabel() {
      return this.showLengthLabel === null
        ? this.hasLimit
        : this.showLengthLabel;
    },
    hasLengthError() {
      const text = this.selfText,
        maxLength = this.maxLength;
      return maxLength && maxLength < text.length;
    },
    hasRequiredError() {
      if (!this.isRequired && !this.needValidate) {
        return false;
      }
      return this.selfText ? false : true;
    },
    hasLimit() {
      return this.maxLength !== 0;
    },
    hasFormatError() {
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      return !reg.test(this.selfText);
    },
    textLengthMsg() {
      const text = this.selfText,
        maxLength = this.maxLength;
      if (this.hasLengthError) {
        return `${text.length - maxLength}字オーバーしています`;
      }
      if (this.showRequiredError) {
        return "この項目は入力必須です";
      }
      if (this.hasFormatError) {
        return "メールアドレスの形式ではありません";
      }
      const length = text && text.length ? text.length : 0;
      return `文字数: ${length}/${maxLength}`;
    },
    showRequiredError() {
      return this.needRequireAlert && this.isRequired && !this.selfText;
    },
    isValid() {
      const hasRequiredError = this.hasRequiredError,
        hasLengthError = this.hasLengthError,
        hasFormatError = this.hasFormatError;

      let isValid =
        hasRequiredError || hasLengthError || hasFormatError ? false : true;
      this.sendIsValid(isValid);

      isValid = !(this.showRequiredError || hasLengthError || hasFormatError);
      return isValid;
    },
  },
  methods: {
    convertFullAlphanumeric(value) {
      value = value.replace(/[Ａ-Ｚａ-ｚ０-９＠]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      });
      this.selfText = value;
      if (this.tmpText !== value) {
        this.tmpText = value;
        this.$emit("input", value);
      }
    },
    sendIsValid(isValid) {
      if (isValid !== this.tmpIsValid) {
        this.$emit("check", isValid);
        this.tmpIsValid = isValid;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.inputText {
  @include commonInputField();
}

.inputFieldBox {
  display: inline-block;
  position: relative;

  &.hasError & {
    &_count {
      color: $COLOR_ALERT;
    }
  }
  &.isFullSize {
    width: 100%;
  }
  &.isFullSize {
    .inputText {
      width: 100% !important;
    }
  }

  &_count {
    font-size: $FONT_SIZE_SS;
    color: $COLOR_SUB_TEXT;
    text-align: right;
    line-height: 1;
    margin-top: $SUB_GRID;
  }
}
</style>
