<template>
  <label class="checkbox" :class="{ disabled }" @click="onClick">
    <input
      type="checkbox"
      class="checkbox__input"
      :value="checkedValue"
      :name="name"
      :disabled="disabled"
      v-model="currentValue"
    />
    <span class="checkbox__icon">
      <svg
        class="checkbox__icon-image"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14.45 19.85"
      >
        <polygon
          class="checkbox__polygon checkbox__polygon--edge"
          points="5.26 19.85 0 13.44 1.93 11.85 4.73 15.26 12.2 0 14.45 1.1 5.26 19.85"
        />
        <polygon
          class="checkbox__polygon checkbox__polygon--inside"
          points="5.1 18.47 1.06 13.54 1.83 12.91 4.89 16.63 12.55 1 13.44 1.44 5.1 18.47"
        />
      </svg>
    </span>
    <span class="checkbox__box"></span>
    <span v-if="label !== ''" class="checkbox__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Boolean],
      defualt: () => [],
    },
    checkedValue: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onClick: function (event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss" scoped>
$ICON_HEIGHT: 16px;
$ICON_WIDTH: 11px;

.checkbox {
  display: inline-flex;
  align-items: flex-start;
  position: relative;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &__input {
    display: none;

    &:checked ~ .checkbox__icon {
      width: $ICON_WIDTH;
      transition: width 0.2s ease-in;
    }
  }

  &__icon {
    width: 0;
    height: $ICON_HEIGHT;
    overflow: hidden;
    position: absolute;
    height: $ICON_HEIGHT;
    left: 2px;

    &-image {
      position: absolute;
      left: 0;
      bottom: 0;
      width: $ICON_WIDTH;
      height: $ICON_HEIGHT;
    }
  }

  &__polygon--edge {
    display: none;
  }

  &__box {
    width: $GRID;
    height: $GRID;
    background-color: #fff;
    border: 1px solid #595757;
    border-radius: 3px;
    display: inline-block;
    transition: 0.3s;
  }

  &__label {
    flex: 1;
    margin-left: $SUB_GRID;
    font-size: $FONT_SIZE_S;
    line-height: $ICON_HEIGHT;
  }
}
</style>
