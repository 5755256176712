<template>
  <filter-block keyword="特典" :sort-item="sort" @update="updateSort">
    <template #filter-l>
      <filter-input
        name="keyword"
        placeholder="ID・特典名・特典内容・備考"
        @update="updateKeywordQuery"
      />
      <div class="filterBlockItem isSetTit">
        <p class="filterBlockItemTit">期間：</p>
        <filter-selectbox v-model="period" :value="period" :options="periodKeys" />
      </div>
      <div class="filterBlockItem checkboxField">
        <filter-checkbox :text="inUseLabel" :value="inUseQuery" @update="updateInUseQuery" />
      </div>
      <div class="filterBlockItem checkboxField type-filter">
        <template v-if="enabledHallBenefits">
          <filter-checkbox
            v-for="({ text, value }, index) in groupTypeKeys"
            :value="groupType.includes(value)"
            :key="`group_${index}`"
            :text="text"
            @update="updateGroupTypeQuery(value)"
          />
        </template>
        <filter-checkbox
          v-for="({ text, value }, index) in benefitTypeKeys"
          :value="benefitType.includes(value)"
          :key="`benefit_${index}`"
          :text="text"
          @update="updateBenefitTypeQuery(value)"
        />
      </div>
    </template>
    <template #filter-r>
      <div class="filterBlockItem isCheckbox">
        <p class="filterBlockItemTit">掲載媒体：</p>
        <filter-checkbox
          v-for="({ name, id }, index) in mediaList"
          :key="`media_${index}`"
          :is-btn-type="true"
          :text="name"
          :value="media.includes(id)"
          @update="updateMediaQuery(id)"
        />
      </div>
    </template>
  </filter-block>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import FilterBlock from "@/components/molecules/common/FilterBlock.vue";
import FilterInput from "@/components/atoms/common/FilterInput.vue";
import FilterCheckbox from "@/components/atoms/common/FilterCheckbox.vue";
import FilterSelectbox from "@/components/atoms/common/FilterSelectbox.vue";

export default {
  components: {
    FilterBlock,
    FilterInput,
    FilterCheckbox,
    FilterSelectbox,
  },
  computed: {
    ...mapGetters({
      enabledHallBenefits: "enabledHallBenefits",
      mediaList: "media",
      periodQuery: "filter/periodQuery",
      inUseQuery: "filter/inUseQuery",
      groupType: "filter/groupTypeQuery",
      benefitType: "filter/benefitTypeQuery",
      media: "filter/mediaQuery",
      periodKeys: "filter/periodKeys",
      groupTypeKeys: "filter/groupTypeKeys",
      benefitTypeKeys: "filter/benefitTypeKeys",
      sort: "filter/sort",
    }),
    period: {
      get() {
        return this.periodQuery;
      },
      set(value) {
        this.updatePeriodQuery(value);
      },
    },
    inUseLabel() {
      return this.enabledHallBenefits ? "フェア・式場特典で使用中のもの" : "使用中のもの";
    },
  },
  methods: {
    ...mapMutations({
      keywordQuery: "filter/keywordQuery",
      updatePeriodQuery: "filter/periodQuery",
      updateInUseQuery: "filter/inUseQuery",
      groupTypeQuery: "filter/groupTypeQuery",
      benefitTypeQuery: "filter/benefitTypeQuery",
      mediaQuery: "filter/mediaQuery",
      updateSort: "filter/sort",
    }),
    updateKeywordQuery(_, keyword) {
      this.keywordQuery(keyword);
    },
    updateGroupTypeQuery(value) {
      const checked = this.groupType.includes(value);
      const groupType = checked
        ? this.groupType.filter((type) => type !== value)
        : [...this.groupType, value];
      this.groupTypeQuery(groupType);
    },
    updateBenefitTypeQuery(value) {
      const checked = this.benefitType.includes(value);
      const benefitType = checked
        ? this.benefitType.filter((type) => type !== value)
        : [...this.benefitType, value];
      this.benefitTypeQuery(benefitType);
    },
    updateMediaQuery(value) {
      const checked = this.media.includes(value);
      const media = checked
        ? this.media.filter((mediaId) => mediaId !== value)
        : [...this.media, value];
      this.mediaQuery(media);
    },
  },
};
</script>

<style scoped lang="scss">
.checkboxField {
  display: flex;
  gap: $GRID;
}

.type-filter {
  width: max-content;
}
</style>
