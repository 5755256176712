<template>
  <input-item
    :media-name="mediaShortName(mediaId)"
    :is-by-media="needByMedia && !isCommon"
  >
    <div class="media-list__block">
      <label>
        <input type="radio" v-model="period" value="date" />日付で設定
        <p class="recommendPop">
          <span class="recommendPop_icon material-icons">
            sentiment_satisfied_alt
          </span>
          <span class="recommendPop_text">推奨</span>
        </p>
      </label>
      <div v-if="period === 'date'" class="inputDate">
        <input-datepicker
          v-model="periodFrom"
          :date="periodFrom"
          :key-name="keyDateFrom"
          :is-required="requiredFrom"
          :disabled-dates="disabledFrom"
          @check="validateFrom"
        />
        <span class="inputDate_text">～</span>
        <input-datepicker
          v-model="periodUntil"
          :date="periodUntil"
          :key-name="keyDateUntil"
          :is-required="requiredUntil"
          :disabled-dates="disabledUntil"
          :has-error="hasErrorUntil"
          @check="validateUntil"
        />
        <p class="note">※適用終了日を設定すると、終了前にお知らせします</p>
      </div>
    </div>
    <div class="media-list__block">
      <label>
        <input type="radio" v-model="period" value="text" />テキストで設定
      </label>
      <div v-if="period === 'text'" class="inputDate">
        <input-text
          v-model="periodText"
          key-name="valid_period_text"
          :text="periodText"
          :is-required="isRequired(keyDateText)"
          :max-length="maxCharacters(keyDateText, mediaId)"
          :media="mediaId"
          :media-ids="onlyMedia(keyDateText)"
          :use-error-code="false"
          @check="updateTextValidation({ mediaId, isValid: $event })"
        />
        <p class="note">
          ※適用期間の管理のため、「日付で設定」することを推奨します
        </p>
      </div>
    </div>
  </input-item>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { isBefore, parseISO } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";

import { disabledDateFrom, disabledDateUntil } from "@/lib/date";

import * as types from "@/store/benefit/mutationTypes";

import InputDatepicker from "@/components/benefit/inputs/InputDatepicker.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";
import InputText from "@/components/benefit/inputs/InputText.vue";

export default {
  components: {
    InputDatepicker,
    InputItem,
    InputText,
  },
  props: {
    mediaId: {
      type: Number,
      default: 0,
    },
    needByMedia: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      keyDateFrom: "valid_from",
      keyDateUntil: "valid_until",
      keyDateText: "valid_period_text",
    };
  },
  computed: {
    ...mapGetters({
      $periodFrom: "periodFrom",
      $periodUntil: "periodUntil",
      $periodText: "periodText",
      mediaShortName: "mediaShortName",
      periodType: "periodType",
      isRequired: "rules/isRequired",
      onlyMedia: "rules/onlyMedia",
      maxCharacters: "rules/maxCharacters",
    }),
    period: {
      get() {
        return this.periodType(this.mediaId);
      },
      set(type) {
        const mediaId = this.mediaId;
        this.updatePeriod({ type, mediaId });

        if (type === "date") {
          this.updateTextValidation({ mediaId, isValid: true });
        } else {
          this.updateFromValidation({ mediaId, isValid: true });
          this.updateUntilValidation({ mediaId, isValid: true });
        }
      },
    },
    periodFrom: {
      get() {
        return this.$periodFrom(this.mediaId);
      },
      set(from) {
        this.updateFrom({ from, mediaId: this.mediaId });
      },
    },
    periodUntil: {
      get() {
        return this.$periodUntil(this.mediaId);
      },
      set(until) {
        this.updateUntil({ until, mediaId: this.mediaId });
      },
    },
    periodText: {
      get() {
        return this.$periodText(this.mediaId);
      },
      set(text) {
        this.updateText({ text, mediaId: this.mediaId });
      },
    },
    requiredFrom() {
      const required = this.isRequired(this.keyDateFrom, this.mediaId);
      if (!required) {
        return required;
      }
      return this.periodFrom == null && this.periodUntil == null;
    },
    requiredUntil() {
      const required = this.isRequired(this.keyDateUntil, this.mediaId);
      if (!required) {
        return required;
      }
      return this.periodFrom == null && this.periodUntil == null;
    },
    disabledFrom() {
      return disabledDateFrom(this.periodUntil);
    },
    disabledUntil() {
      return disabledDateUntil(this.periodFrom);
    },
    isCommon() {
      return this.mediaId === API_CODE.mediaCommon;
    },
    hasErrorUntil() {
      const from = parseISO(this.periodFrom);
      const until = parseISO(this.periodUntil);
      return isBefore(until, from);
    },
  },
  beforeDestroy() {
    const mediaId = this.mediaId;
    this.updateTextValidation({ mediaId, isValid: true });
    this.updateFromValidation({ mediaId, isValid: true });
    this.updateUntilValidation({ mediaId, isValid: true });
  },
  methods: {
    ...mapMutations({
      updatePeriod: `${types.PERIOD_TYPE}`,
      updateFrom: `${types.PERIOD_FROM}`,
      updateUntil: `${types.PERIOD_UNTIL}`,
      updateText: `${types.PERIOD_TEXT}`,
      updateFromValidation: `validation/${types.PERIOD_FROM}`,
      updateUntilValidation: `validation/${types.PERIOD_UNTIL}`,
      updateTextValidation: `validation/${types.PERIOD_TEXT}`,
    }),
    validateFrom(isValid) {
      this.updateFromValidation({ isValid, mediaId: this.mediaId });
      this.validateUntil(true);
    },
    validateUntil(isValid) {
      const mediaId = this.mediaId;
      if (!isValid) {
        this.updateUntilValidation({ mediaId, isValid });
        return;
      }

      if (this.requiredUntil) {
        const until = this.periodUntil;
        const isValidUntil = until ? !this.hasErrorUntil : false;
        this.updateUntilValidation({ mediaId, isValid: isValidUntil });
      } else {
        const isValidUntil = !this.hasErrorUntil;
        this.updateUntilValidation({ mediaId, isValid: isValidUntil });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.media-list {
  &.by-media {
    .recommendPop {
      &::after {
        border-color: transparent $BG_COLOR_MEDIA_SETTING transparent
          transparent;
      }
    }
  }
  &__block {
    font-size: 13px;
    .recommendPop {
      border: 1px solid $COLOR_SUBMIT;
      border-radius: $BOX_RADIUS * 0.5;
      color: $COLOR_SUBMIT;
      display: inline-block;
      margin-left: $GRID * 0.75;
      padding: 2px $GRID * 0.5 2px $GRID * 0.3;
      position: relative;
      top: -2px;
      &::before,
      &::after {
        border-style: solid;
        border-width: 4px 8px 4px 0;
        bottom: 0;
        content: "";
        display: block;
        height: 0;
        margin: auto;
        position: absolute;
        top: 0;
        width: 0;
      }
      &::before {
        border-color: transparent $COLOR_SUBMIT transparent transparent;
        left: -8px;
      }
      &::after {
        border-color: transparent #fff transparent transparent;
        left: -6px;
      }
      &_icon {
        font-size: 21px;
        margin-right: $GRID * 0.2;
        vertical-align: middle;
      }
      &_text {
        vertical-align: middle;
      }
    }
    .inputDate {
      margin-top: $SUB_GRID;
      &_text {
        margin: 0 $SUB_GRID;
      }
    }
    .note {
      font-size: 10px;
      font-weight: normal;
      margin-top: $SUB_GRID;
    }
  }
}
</style>
