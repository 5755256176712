import { VenueState } from "@/store/hall-admin/status-types";
import isSuspended from "@/store/hall-admin/helper/is-suspended.js";

const getters = {
  hall: ({ hall }) => hall,
  isWholeListLoaded: ({ hall }) => hall.isWholeListLoaded,
  SystemFeatureType: ({ hall }) => hall.master.system_feature_type,
  hallInfo: (state) => (hallId) => {
    return state.hall.list.find((item) => item.hall_id == hallId);
  },
  hallState: (_) => (hallInfo) => {
    if (isSuspended(hallInfo.suspended_at)) return VenueState.SUSPENDED;
    if (hallInfo.usage_started_at !== null) return VenueState.USING;
    if (hallInfo.data_created_at !== null) return VenueState.DATA_CHECKING;
    if (hallInfo.associate_ended_at !== null)
      return VenueState.DATA_MAINTENANCE;
    if (hallInfo.import_ended_at !== null) return VenueState.BIND_WAITING;
    if (hallInfo.account_created_at !== null) return VenueState.IMPORT_WAITING;
    if (hallInfo.preparation_started_at !== null) return VenueState.APPLYING;

    return VenueState.UNUSED;
  },
  isPrepare: (s, getters) => (hallInfo) => {
    const hallState = getters.hallState(hallInfo);
    const { in_preparation_target } = hallInfo;

    // 利用中の式場は利用準備中のサービスがあるかのを確認
    const isStartedWithPreparingService =
      VenueState.USING === hallState &&
      Array.isArray(in_preparation_target) &&
      in_preparation_target.length > 0;
    if (isStartedWithPreparingService) return true;

    const isValidState =
      VenueState.USING > hallState && hallState > VenueState.UNUSED;
    return isValidState;
  },
  inUse: (s, getters) => (hallInfo) => {
    const { in_preparation_target } = hallInfo;
    return (
      getters.hallState(hallInfo) === VenueState.USING &&
      in_preparation_target.length === 0
    );
  },
  isAuthorized: (state) => {
    return state.isAuthorized;
  },
};

export default getters;
