<template>
  <dl class="selectedDetail isSchedule">
    <dt class="selectedDetail_item">{{ selectedItem }}</dt>
    <dd>
      <dl class="selectedInfo">
        <dt class="selectedInfo_label">
          選択した{{ isFairGroup ? `グループ` : `フェア` }}
        </dt>
        <dd class="selectedInfo_contents">
          <float-setting-fair-link
            :can-edit="canEdit"
            :error-data="errorData"
            :is-fair-group="isFairGroup"
            :is-daily-setting="isDailySetting"
            :is-error="isError"
            :selected-unit="selectedUnit"
            :target-id="targetId"
          />
        </dd>
      </dl>
      <dl class="selectedInfo" v-if="dates.length > 0">
        <dt class="selectedInfo_label">選択した日</dt>
        <dd class="selectedInfo_contents fontNum">
          <ul class="selectedInfo_dates">
            <li class="selectedInfo_date" v-for="(v, k) in dates" :key="k">
              {{ v }}
            </li>
          </ul>
        </dd>
      </dl>
    </dd>
  </dl>
</template>

<script>
import FloatSettingFairLink from "@/components/atoms/common/FloatSettingFairLink.vue";

export default {
  components: {
    FloatSettingFairLink,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    errorData: {
      type: Object,
      default: () => {},
    },
    isFairGroup: {
      type: Boolean,
      default: false,
    },
    isDailySetting: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: String,
      default: "",
    },
    selectedUnit: {
      type: String,
      default: "",
    },
    targetId: {
      type: Number,
      default: null,
    },
    dates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.selectedDetail {
  @include gradientMain();
  border-right: 1px solid #e2e2e2;
  box-sizing: border-box;
  color: $COLOR_KEY;
  font-size: 0;
  font-weight: bold;
  padding: 0 0 $GRID * 0.5 $GRID;
  width: $GRID * 16;
  &_item {
    display: inline-block;
    font-size: 12px;
    padding-top: $GRID * 0.5;
    position: relative;
    &::after {
      background: $COLOR_KEY;
      content: "";
      display: block;
      height: 2px;
      left: -1px;
      position: absolute;
      top: 0;
      width: calc(100% + 2px);
    }
  }
  &_num {
    display: block;
    padding-left: $GRID;
    text-align: center;
    .fontNum {
      font-size: 40px;
    }
  }
  &_unit {
    font-size: 14px;
    margin-left: 0.5em;
  }
  &.isSetDetailSlot {
    display: flex;
    .selectedDetail {
      &_area {
        width: 50%;
      }
      &_num {
        padding: 0 $GRID * 0.5 0 0;
        text-align: right;
      }
    }
  }
}
.selectedInfo {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: $BOX_RADIUS;
  box-shadow: $DEPTH_LV_0;
  box-sizing: border-box;
  width: $GRID * 14;
  margin: $GRID 0 0;
  padding: $SUB_GRID $SUB_GRID * 2;
  &_label {
    font-size: 11px;
    font-weight: bold;
    text-align: left;
  }
  &_contents {
    font-size: 14px;
    font-weight: normal;
    padding: $SUB_GRID;
    text-align: left;
    word-wrap: break-word;
    &.fontNum {
      font-size: 22px;
      text-align: center;
    }
  }
  &_linkBtn {
    margin-top: $SUB_GRID * 2;
  }
  &_dates {
    list-style: none;
    margin-top: $SUB_GRID * 1;
  }
  &_date {
    margin-bottom: $SUB_GRID * 2;
    word-wrap: break-word;
  }
  &_daily {
    font-size: 12px;
  }
  &_error {
    align-items: center;
    border: 1px solid $COLOR_ALERT;
    border-radius: 4px;
    color: $COLOR_ALERT;
    display: flex;
    font-size: 12px;
    margin: $GRID 0;
    padding: $SUB_GRID;
    .material-icons {
      display: inline-block;
      font-size: 18px;
      margin-right: $SUB_GRID;
    }
    &.isFatal {
      background-color: $COLOR_ALERT;
      color: #fff;
    }
  }
}
</style>
