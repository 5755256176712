<template>
  <div class="log-unit__wrap">
    <div class="log-unit">
      <p class="log-unit__id fontNum">{{ log.id || "-" }}</p>
      <p v-if="isShowParent" class="log-unit__hall fontNum">
        {{ log.hall_id || "-" }}
      </p>
      <log-icon
        class="log-unit__status"
        :class="{ 'log-unit__status--fail': !log.is_successful }"
        :icon="iconStatus"
        :text="log.is_successful ? '成功' : '失敗'"
        :is-horizontal="true"
        :is-small="true"
      />
      <p class="log-unit__user">{{ username }}</p>
      <log-icon
        class="log-unit__type"
        :icon="iconType"
        :text="log.header"
        :is-horizontal="true"
        :is-small="true"
      />
      <div class="log-unit__title">
        <span class="log-unit__title-link" @click="movePage(log)">
          {{ logTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LogIcon from "@/components/molecules/common/LogIcon.vue";
import { userName } from "@/store/job/operationLogs";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    LogIcon,
  },
  props: {
    log: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      isShowParent: "job/isShowParent",
      users: "job/operationLogs/users",
    }),
    iconStatus: function() {
      return this.log.is_successful ? "check_circle" : "cancel";
    },
    iconType: function() {
      const typeIcons = {
        Fair: "flag",
        FairOrder: "sort",
        FairGroup: "flag",
        FairGroupOrder: "flag",
        FairPublish: "flag",
        FairUnpublish: "flag",
        Schedule: "date_range",
        Stock: "date_range",
        Daily: "date_range",
        OptionalRecommended: "date_range",
        Recommended: "date_range",
        Benefit: "stars",
        BenefitLink: "stars",
        BenefitReplace: "stars",
        BenefitUnlink: "stars",
        Image: "photo",
        ImageTrim: "photo",
        Plan: "cake",
        PlanOrder: "sort",
        PlanPublish: "cake",
        PlanUnpublish: "cake",
        NewInfo: "campaign",
        HallInfoNewInfo: "campaign",
        HallInfoMedia: "campaign",
        NewInfoLink: "campaign",
        MediaAccount: "settings_input_component",
        MediaConnect: "settings_input_component",
        MediaStartUsage: "settings_input_component",
        MediaStopUsage: "settings_input_component",
        MediaSyncStart: "settings_input_component",
        MediaSyncStop: "settings_input_component",
        Venue: "location_city",
        Tag: "local_offer",
        HallSetting: "account_balance",
        User: "assignment_ind",
      };
      const type = this.log.operation_detail || this.log.operationable_type;
      return typeIcons[type] || Object.values(typeIcons)[0];
    },
    username: function() {
      const user = this.users.find((user) => user.id === this.log.user_id);
      if (!user) return this.log.user_id;
      return userName(user);
    },
    logTitle: function() {
      const operationId = this.log.operationable_id || "";
      const title = this.log.title || "ページリンク";
      return `${operationId}${operationId && "："}${title}`;
    },
  },
  methods: { ...mapActions("job/operationLogs", ["movePage"]) },
};
</script>

<style scoped lang="scss">
.log-unit__wrap {
  display: flex;
  margin: auto 2px;
  & + & {
    margin-top: 2px;
  }
}
.log-unit {
  align-items: center;
  background: $BG_COLOR_SUB;
  border-radius: $GRID * 0.5;
  color: $COLOR_KEY;
  display: flex;
  font-size: 14px;
  min-height: $GRID * 3;
  width: 100%;

  > * {
    margin-left: $GRID * 0.5;
  }
  &__id {
    color: $COLOR_SUB_TEXT;
    flex: 0 0 auto;
    min-width: $GRID * 3.5;
    &::before {
      content: "# ";
    }
  }
  &__title {
    font-size: 12px;
    margin: 0 $GRID;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &-link {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__status {
    flex: 0 0 auto;
    width: $GRID * 5;
    color: $COLOR_SUBMIT;
    &--fail {
      color: $COLOR_ALERT;
    }
  }
  &__type {
    flex: 0 0 auto;
    width: $GRID * 15;
  }
  &__hall {
    margin: auto 0;
    min-width: $GRID * 3.5;
  }
  &__user {
    margin: auto 0;
    min-width: $GRID * 8.5;
  }
}
</style>
