<template>
  <input-item :media-name="mediaShortName(mediaId)" :is-by-media="!isCommon">
    <validating-textarea
      v-model="title"
      :text="title"
      :is-full-size="true"
      :is-required="isRequired(keyName)"
      :is-textarea="isTextarea"
      :max-length="maxCharacters(keyName, mediaId)"
      :media-ids="[mediaId]"
      :ng-word="ngWord(keyName)"
      :rows="isTextarea ? 7 : null"
      :full-width="fullWidth"
      @check="textValidation"
    />
  </input-item>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

export default {
  components: {
    ValidatingTextarea,
    InputItem,
  },
  props: {
    mediaId: Number,
  },
  data() {
    return {
      keyName: "title",
    };
  },
  computed: {
    ...mapGetters({
      mediaShortName: "mediaShortName",
      $title: "title",
      ngWord: "ngWord",
      isRequired: "rules/isRequired",
      onlyMedia: "rules/onlyMedia",
      maxCharacters: "rules/maxCharacters",
    }),
    title: {
      get() {
        return this.$title(this.mediaId);
      },
      set(title) {
        this.updateTitle({ title, mediaId: this.mediaId });
      },
    },
    isCommon() {
      return this.mediaId === API_CODE.mediaCommon;
    },
    isTextarea() {
      return (
        this.mediaId === API_CODE.media.mynavi ||
        this.mediaId === API_CODE.media.homepage
      );
    },
    fullWidth() {
      return this.mediaId !== API_CODE.media.zexy;
    }
  },
  beforeDestroy() {
    this.textValidation(true);
  },
  methods: {
    ...mapMutations({
      updateTitle: `${types.TITLE}`,
      validateTitle: `validation/${types.TITLE}`,
    }),
    textValidation(isValid) {
      this.validateTitle({ isValid, mediaId: this.mediaId });
    },
  },
};
</script>
