import getters from "@/store/contact/getters";
import mutations from "@/store/contact/mutations";
import actions from "@/store/contact/actions";

import modalModule from "@/store/modal";

const state = {
  isShow: false,
  init: {
    item: null,
    pages: [],
    content: "",
    customer_name: "",
    customer_tel: null,
    customer_mail: "",
    customer_time: null,
  },
  data: {
    item: null,
    content: "",
    customer_name: "",
    customer_tel: null,
    customer_mail: "",
    customer_time: null,
  },
  master: {
    item: {
      1: "操作方法が分からない",
      2: "不具合が生じた",
      3: "使い方をいちから教えてほしい",
      4: "その他",
    },
    pages: {
      fair: "フェア マスタ設定",
      schedule: "フェア スケジュール設定",
      benefit: "フェア 特典設定",
      image: "画像設定",
      media: "媒体設定",
      other: "その他",
    },
  },
  validation: {
    requiredItems: [], // 必須項目
    completedRequiredItems: [], // 入力済み必須項目
    validItems: [], // 入力済み項目
    itemsHasError: [], // エラーのある項目
  },
  rules: {
    item: {
      is_required: true,
    },
    pages: {
      is_required: false,
    },
    content: {
      is_required: true,
      max_characters: 1000,
    },
    customer_name: {
      is_required: true,
      max_characters: 30,
    },
    customer_tel: {
      is_required: true,
    },
    customer_mail: {
      is_required: true,
      max_characters: 100,
    },
    customer_time: {
      is_required: false,
      max_characters: 100,
    },
  },
};

const contactModule = {
  namespaced: true,
  modules: {
    modal: modalModule,
  },
  state,
  getters,
  mutations,
  actions,
};

export default contactModule;
