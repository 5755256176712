<template>
  <modal-header-window
    class="modalImageSelect"
    icon="image"
    title="画像選択"
    v-if="isAllReady"
    :is-image-list="true"
    :is-set-footer="false"
    @close="CloseModal"
  >
    <template v-slot:content>
      <div class="content">
        <image-filter :is-modal-window="true" />
        <image-list-select
          v-model="selfSelected"
          :selected="selected"
          :target-urls="restrictTypes"
          :target-media-ids="restrictMediaIds"
          :need-all-urls="needAllUrls"
          @output="CompleteSelection"
        />
        <modal-loading v-if="loading" />
      </div>
    </template>
  </modal-header-window>
</template>

<script>
import ImageFilter from "@/components/organisms/image-list/ImageFilter";
import ImageListSelect from "@/components/organisms/image-list/ImageListSelect";
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow.vue";
import ModalLoading from "@/components/molecules/common/ModalLoading";
import ModalWindow from "@/components/molecules/common/ModalWindow.vue";

// Store
import store from "@/store/image-list/"; // image-list用storeを直接繋ぎ込み
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    ImageFilter,
    ImageListSelect,
    ModalHeaderWindow,
    ModalLoading,
    ModalWindow,
  },
  store: store,
  model: {
    prop: "selected",
    event: "input",
  },
  props: {
    selected: {
      type: [Number, String],
      default: -1,
    },
    restrictMediaIds: {
      type: Array,
      default: () => [],
    },
    restrictTypes: {
      type: Array,
      default: () => [],
    },
    needAllUrls: {
      type: Boolean,
      default: true,
    },
    requestImageData: {
      // 全画像のデータをemitして欲しい場合はtrue
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      images: "data",
      medias: "medias",
    }),
    ...mapState("common", { loading: (state) => state.loading }),
    ...mapGetters(["isSelectableImage"]),
    ...mapGetters("setup", ["isConnecting", "isReady"]),
    selfSelected: {
      get: function() {
        return this.selected;
      },
      set: function(value) {
        this.$emit("input", value);
      },
    },
    // 初期化とその他データの両方の用意ができたらtrue
    isAllReady() {
      let isAllReady = !this.loading && Object.keys(this.medias).length > 0;
      if (isAllReady && this.requestImageData) {
        this.returnAllImages();
      }
      return isAllReady;
    },
  },
  methods: {
    ...mapActions({
      init: "getInitialData",
    }),
    CloseModal() {
      this.$emit("close");
    },
    CompleteSelection(image) {
      this.$emit("output", image);
      this.$emit("close");
    },
    returnAllImages() {
      const data = {
        images: this.images,
        functions: {
          isSelectableImage: this.isSelectableImage,
        },
      };
      this.$emit("init", data);
    },
  },
  created() {
    this.init(false);
  },
};
</script>

<style scoped lang="scss">
.modalImageSelect {
  z-index: $LAYER_MODAL + 1;

  ::v-deep .modalHeaderWindow {
    height: calc(100% - #{$GRID} * 2);
  }
}
</style>
