<template>
  <input-item :media-name="mediaShortName(mediaId)" :is-by-media="!isCommon">
    <div>
      <image-selector
        v-model="image"
        v-slot="imageSelect"
        :selected="image.id"
        :types="imageType"
        :mediaIds="selectedOnlyMedia(keyName)"
      >
        <selected-image
          v-if="image.id"
          :path="image.urls.thumb"
          @reset="resetImage()"
          @change="imageSelect.selectImage"
        />
        <button
          v-else
          type="button"
          class="btnSelectImg"
          @click="imageSelect.selectImage"
        >
          選択
        </button>
      </image-selector>
    </div>
  </input-item>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import SelectedImage from "@/components/molecules/common/SelectedImage.vue";
import ImageSelector from "@/components/organisms/common/ImageSelector.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

export default {
  components: {
    SelectedImage,
    ImageSelector,
    InputItem,
  },
  props: {
    mediaId: Number,
  },
  data() {
    return {
      title: "画像",
      keyName: "image_id",
    };
  },
  computed: {
    ...mapGetters({
      mediaIds: "mediaIds",
      mediaShortName: "mediaShortName",
      $image: "imageByMediaId",
      isRequired: "rules/isRequired",
      maxCharacters: "rules/maxCharacters",
      selectedOnlyMedia: "selectedOnlyMedia",
    }),
    image: {
      get() {
        return this.$image(this.mediaId);
      },
      set(value) {
        this.updateImageId({ mediaId: this.mediaId, imageId: value.id });
      },
    },
    isCommon() {
      return this.mediaId === API_CODE.mediaCommon;
    },
    imageType() {
      // コレモネっとだけが選択されている場合はoriginalを返却する
      const coremonetSelected = this.mediaIds.includes(API_CODE.media.homepage);
      const onlyMediaSelected = this.mediaIds.length === 1;
      if (coremonetSelected && onlyMediaSelected) {
        return ["original"];
      }

      // ウエパが選択されている場合は1:1を返却する
      if (this.mediaIds.includes(API_CODE.media.wp)) {
        return ["1:1"];
      }

      return [];
    },
  },
  methods: {
    ...mapMutations({ updateImageId: `${types.IMAGE_ID}` }),
    resetImage() {
      this.updateImageId({ mediaId: this.mediaId, imageId: null });
    },
  },
};
</script>

<style scoped lang="scss">
.btnSelectImg {
  @include imgSelectBtn();
}
</style>
