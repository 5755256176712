<template>
  <div class="logUnit">
    <div class="logUnitHeader">
      <div class="logUnitHeader_title">
        <p class="logUnit_id fontNum">#{{ data.id }}</p>
        <p v-if="datetimeText" class="logUnit_datetime fontNum">
          {{ datetimeText }}
        </p>
      </div>
      <action-button
        v-if="data.isBeforeConfirm"
        class="logUnitHeader_button"
        text="ログを確認済みにする"
        :is-select="true"
        @clickHandler="update"
      />
    </div>
    <div class="logUnitBox">
      <div class="logUnitDetail">
        <p class="logUnit_title">
          <log-icon
            :status="data.status"
            :master="master"
            :is-show-short="true"
          />
          <span>{{ data.header || "" }}</span>
        </p>
        <p class="logUnit_text" v-if="isDelete || data.isDisabledLink">
          <span class="logUnit_linkId">ID:{{ data.queueable_id }}</span>
          <span v-if="data.queueable_title" class="logUnit_linkTitle">{{
            data.queueable_title
          }}</span>
        </p>
        <a class="logUnit_link" v-else-if="!data.no_link" :href="link">
          <span class="logUnit_linkId">ID:{{ data.queueable_id }}</span>
          <span v-if="data.queueable_title" class="logUnit_linkTitle">{{
            data.queueable_title
          }}</span>
        </a>
      </div>
      <dl
        v-if="data.message"
        class="logUnit_contents"
        :class="{ isError, isNotice }"
      >
        <dd v-html="message" class="logUnit_contentsText"></dd>
      </dl>
    </div>
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";
import formatDateText from "@/lib/formatDateText";
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import LinkButton from "@/components/atoms/common/LinkButton.vue";
import LogIcon from "@/components/molecules/common/LogIcon.vue";
import { getLogPath } from "@/store/common/helper";

export default {
  components: {
    ActionButton,
    LinkButton,
    LogIcon,
  },
  props: {
    data: Object,
    master: {
      type: Object,
      default: () => {},
    },
  },
  data: function() {
    return {
      isDelete: this.data.action == "deleted",
      messageLink: {
        text: "日毎設定を確認する",
        path: null,
      },
    };
  },
  computed: {
    datetimeText: function() {
      if (this.data.responded_at)
        return formatDateText(this.data.responded_at, true);
    },
    link: function() {
      const l = getLogPath(this.data.queueable_type);
      if (
        [
          "PlanOrder",
          "MediaAccountConnect",
          "NewInfo",
          "HallInfoNewInfo",
        ].includes(this.data.queueable_type)
      ) {
        return l;
      }

      return l + this.data.queueable_id;
    },
    message: function() {
      let message = this.data.message.replace(/\\r\\n/g, "\r\n");
      const isShowLink = !this.data.no_link && this.data.show_link_to_daily;
      // コード自動整形で改行される事があるため v-html で実装
      if (isShowLink) message += this.contentsButton;
      return message;
    },
    isError: function() {
      return this.data.status >= API_CODE.linkStatus.error; // 90以上
    },
    isNotice: function() {
      return this.data.status == API_CODE.linkStatus.notice; // 80
    },
    contentsButton: function() {
      return `<a class="logUnit_contentsButton" href="${this.messageLink.path}">${this.messageLink.text}</a>`;
    },
  },
  created: function() {
    this.messageLink.path = getLogPath("FairDaily");
    if (this.data.queueable_id) {
      this.messageLink.path += this.data.queueable_id;
    }
  },
  methods: {
    update: function() {
      this.$emit("update", this.data.id);
    },
  },
};
</script>

<style scoped lang="scss">
.logUnit {
  &Header {
    color: $COLOR_SUB_TEXT;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-bottom: 3px;
    &_button {
      padding: 0;
      bottom: 2px;
      width: $GRID * 8.75 !important;
    }
  }
  &Box {
    @include Box();
    padding: $GRID 12px;
  }
  &_id {
    display: inline-block;
    min-width: $GRID * 3;
  }
  &Detail {
    align-items: center;
    display: flex;
  }
  &_datetime {
    color: $COLOR_SUB_LIGHT_TEXT;
    display: inline-block;
    margin-left: $GRID * 0.5;
  }
  &_title {
    align-items: center;
    color: $COLOR_KEY;
    display: flex;
    font-size: 18px;
    .logIcon {
      margin-right: $GRID * 0.5;
    }
  }
  &_text,
  &_link {
    color: $COLOR_KEY;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    margin-left: 27px;
  }
  &_link {
    position: relative;
    text-decoration: none;
    &Title {
      margin-left: $GRID;
    }
    &::after {
      background: $COLOR_KEY;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
    &:hover {
      &::after {
        display: none;
      }
    }
  }
  &_contents {
    background: $BG_COLOR_SUB;
    border-radius: $BOX_RADIUS;
    margin-top: $GRID * 0.5;
    padding: $GRID * 0.5 $GRID * 0.5 $GRID * 1.25;
    &Title {
      color: $COLOR_SUB_LIGHT_TEXT;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: $GRID;
      .isError & {
        color: $COLOR_ALERT;
      }
      .isNotice & {
        color: $COLOR_WARNING;
      }
    }
    &Text {
      font-size: 14px;
      white-space: pre-line;
    }
    &.isError {
      background: $BG_COLOR_ALERT;
    }
    &.isNotice {
      background: $BG_COLOR_WARNING;
    }
    ::v-deep &Button {
      @include btn();
      background: $COLOR_KEY;
      border: none;
      border-radius: $GRID;
      box-shadow: $DEPTH_LV_0;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      margin-left: $GRID;
      padding: 3px 0;
      top: 7px;
      width: $GRID * 8.75;
    }
  }
  & + & {
    margin-top: $GRID * 1.5;
  }
}
</style>
