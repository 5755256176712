<template>
  <div class="contactDetail" :class="cssClass">
    <div class="contactDetail_descWrap">
      <span class="contactDetail_agent material-icons">support_agent</span>
      <p class="contactDetail_desc">
        <span>{{ descText }}</span>
      </p>
      <action-button
        v-if="isShowButton"
        class="contactDetail_descButton"
        text="お問い合わせ"
        @clickHandler="click"
      />
      <p v-else class="contactDetail_descSub">
        <span>状況を確認して<br />こちらからご連絡いたします</span>
      </p>
    </div>
    <div class="contactDetailBox">
      <p class="contactDetailBox_txt">
        お急ぎの場合は、
        <br />お電話でもお問い合わせできます
      </p>
      <p class="contactDetailBox_tel">
        <span class="contactDetailBox_telIcon material-icons">phone</span>
        <span class="contactDetailBox_telNum fontNum">{{ tel }}</span>
      </p>
      <p class="contactDetailBox_time">受付時間：{{ time }}</p>
    </div>
  </div>
</template>

<script>
import MASTER_CONFIG from "@/assets/common/MasterConfig";
// component
import ActionButton from "@/components/atoms/common/ActionButton";

export default {
  components: {
    ActionButton,
  },
  props: {
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    isShowButton: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {};
  },
  computed: {
    cssClass: function() {
      return {
        isHorizontal: this.isHorizontal,
      };
    },
    tel: function() {
      return MASTER_CONFIG.tel;
    },
    time: function() {
      return MASTER_CONFIG.time;
    },
    descText: function() {
      return this.isShowButton
        ? "ご不明な点があれば、\r\nお気軽にお問い合わせください！"
        : "お問い合わせ内容を\r\nお知らせください";
    },
  },
  methods: {
    click: function() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
$DETAIL_BOX_WIDTH: $GRID * 16;
.contactDetail {
  @include gradientMain();
  border-radius: $BOX_RADIUS;
  display: inline-block;
  padding: $GRID * 0.5;
  text-align: center;
  &_agent {
    font-size: 48px;
  }
  &_desc {
    font-size: 16px;
    padding: $SUB_GRID 0;
    text-align: center;
    white-space: pre-wrap;
    &Button {
      margin: 18px auto auto;
    }
    &Sub {
      font-size: 14px;
    }
    &Wrap {
      width: $DETAIL_BOX_WIDTH;
    }
  }
  > * + * {
    margin-top: $GRID;
  }
}
.contactDetailBox {
  background: rgba(#fff, 0.5);
  border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $GRID 0;
  width: $DETAIL_BOX_WIDTH;
  &_txt {
    font-size: 13px;
  }
  &_tel {
    &Icon {
      font-size: 30px;
    }
    &Num {
      font-size: 36px;
      letter-spacing: -0.05em;
    }
  }
  &_time {
    font-size: 11px;
  }
}
.isHorizontal {
  display: inline-flex;
  padding: $GRID;
  width: auto;
  .contactDetail {
    &_agent {
      font-size: $GRID * 2.5;
    }
    &_desc {
      padding-top: $GRID;
      &Button {
        margin-top: $GRID * 1.5;
      }
      &Sub {
        padding: 0;
      }
      &Wrap {
        margin: auto auto auto auto;
      }
    }
  }
  .contactDetailBox {
    &_tel {
      padding: $GRID 0 $SUB_GRID;
    }
  }
  > * + * {
    margin-left: $GRID * 0.5;
    margin-top: 0;
  }
}
</style>
