<template>
  <div class="jobFilter_wrap">
    <filter-block
      v-if="isShowFilter"
      class="jobFilter"
      :sort-item="sortData"
      @update="sort"
    >
      <template #filter-l>
        <filter-input
          v-if="isShowParent"
          class="hallId"
          ref="hallId"
          name="hall_id"
          placeholder="式場ID"
          @update="filter"
        />
        <filter-input
          class="keyword"
          ref="keyword"
          name="keyword"
          placeholder="フェア名・プラン名・特典名・画像名"
          @update="filter"
        />
        <div v-if="isShowExecuted" class="filterBlockItem">
          <input-period
            name="period"
            :disabled-dates="{ from: new Date(), to: null }"
            :is-required="true"
            :period="{
              from: filterQuery.period.from,
              to: filterQuery.period.to,
            }"
            @update="filter"
          />
        </div>
        <div v-if="isShowExecuted" class="filterBlockItem isSetTit">
          <p class="filterBlockItemTit">実行状態：</p>
          <filter-selectbox
            :value="filterQuery.status"
            :options="master.status"
            @input="filter('status', $event)"
          />
        </div>
        <div class="filterBlockItem isSetTit">
          <p class="filterBlockItemTit">対象：</p>
          <filter-selectbox
            :value="filterQuery.type"
            :options="master.queueable_type"
            @input="filter('type', $event)"
          />
        </div>
        <div class="filterBlockItem isSetTit">
          <p class="filterBlockItemTit">媒体：</p>
          <filter-selectbox
            :value="filterQuery.media_id"
            :options="mediaNames"
            @input="filter('media_id', $event)"
          />
        </div>
      </template>
      <template #filter-b>
        <action-button
          v-if="isShowExecuted"
          class="jobFilterButton"
          text="検索する"
          @clickHandler="filterApi"
        />
      </template>
    </filter-block>
    <div class="checkActual" :class="{ isShowFilter }">
      <filter-checkbox
        v-if="isShowParent && isViewableAnyHall"
        label="is_actual"
        text="テストアカウントを除外する"
        :value="filterQuery.is_actual"
        @update="filterCheck"
      />
    </div>
  </div>
</template>

<script>
// component
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import FilterBlock from "@/components/molecules/common/FilterBlock.vue";
import FilterCheckbox from "@/components/atoms/common/FilterCheckbox.vue";
import FilterInput from "@/components/atoms/common/FilterInput.vue";
import FilterSelectbox from "@/components/atoms/common/FilterSelectbox.vue";
import InputPeriod from "@/components/molecules/common/InputPeriod.vue";
// store
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    ActionButton,
    FilterBlock,
    FilterCheckbox,
    FilterInput,
    FilterSelectbox,
    InputPeriod,
  },
  computed: {
    ...mapState("job/filter", ["filterType"]),
    ...mapGetters("job", ["master", "isShowParent"]),
    ...mapGetters("job/filter", ["filterQuery", "sortData", "isClearedFilter"]),
    ...mapGetters("common", ["isViewableAnyHall"]),
    mediaNames: function () {
      if (this.master.media) {
        const names = Object.assign({}, this.master.media);
        Object.keys(this.master.media).forEach((k) => {
          names[k] = this.master.media[k].name;
        });
        return names;
      } else {
        return {};
      }
    },
    isShowExecuted: function () {
      return this.filterType == "executed";
    },
    isShowFilter: function () {
      return this.isShowParent || this.isShowExecuted;
    },
  },
  watch: {
    filteredData: {
      handler() {
        if (this.isClearedFilter) {
          this.$refs.keyword.$el.value = "";
          this.clearedFilter();
        }
      },
    },
    isClearedFilter: {
      immediate: false, // 初期化時は発火しない
      handler() {
        if (this.isClearedFilter) {
          this.$refs.keyword.$el.value = "";
          this.clearedFilter();
        }
      },
    },
  },
  mounted() {
    if (this.isClearedFilter) {
      this.clearedFilter();
    }
  },
  methods: {
    ...mapActions("job", ["getExecutedJobsApi"]),
    ...mapActions("job/filter", [
      "updateFilter",
      "updateSortKey",
      "clearedFilter",
    ]),
    filterApi: async function () {
      if (await this.getExecutedJobsApi()) {
        this.$emit("change");
      }
    },
    filterCheck: function (key) {
      this.filter(key, !this.filterQuery[key]);
    },
    filter: function (label, value) {
      const data = { label, value };
      this.updateFilter(data);
      if (this.isShowExecuted) {
        // 更新履歴はAPI実行時にフィルタを行う
      } else {
        this.$emit("change");
      }
    },
    sort: function (value) {
      this.updateSortKey(value);
    },
  },
};
</script>

<style scoped lang="scss">
.jobFilter {
  margin-bottom: $GRID;
  .filterBlockItem > .filterCheck {
    margin-left: $SUB_GRID;
  }
  .keyword {
    width: 100%;
  }
  .hallId {
    width: $GRID * 7;
  }
  &Button {
    margin: $GRID * 0.5 0 0 auto;
  }
  &_wrap {
    .checkActual {
      margin-bottom: $SUB_GRID;
      text-align: right;
    }
  }
}
</style>
