import { httpClient } from "@/lib/httpClient";
import API_CODE from "@/assets/common/ApiCode";

import commonModule from "@/store/common";
import modalModule from "@/store/modal";

const JobsControl = {
  namespaced: true,
  modules: {
    common: commonModule,
    modal: modalModule,
  },
  state: {
    isLoading: true,
    isMediaSync: false,
    isDisabled: false,
    jobsNum: 0,
    canPauseToSync: false,
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },
    isMediaSync: (state) => {
      return state.isMediaSync;
    },
    isDisabled: (state) => {
      return state.isDisabled;
    },
    jobsNum: (state) => {
      return state.jobsNum;
    },
    canPauseToSync: (state) => {
      return state.canPauseToSync;
    },
  },
  mutations: {
    setLoadingFinish(state) {
      state.isLoading = false;
    },
    setJobsNum(state, { data }) {
      state.jobsNum = data.length;
    },
    setIsMediaSync(state, { is_media_sync }) {
      state.isMediaSync = is_media_sync;
    },
    setIsDisable(state, isDisabled) {
      state.isDisabled = isDisabled;
    },
    setCanPauseToSync(state, payload) {
      state.canPauseToSync = payload;
    },
  },
  actions: {
    //  待機中のJOB一覧を取得
    async getJobsData({ commit, dispatch, getters }) {
      if (!getters["common/isAuthorized"]({ name: "jobs", type: "view" })) {
        return;
      }
      const payload = { apiUrl: "/jobs/incomplete", params: { contains_in_sync: true } };
      const success = await dispatch(
        "setJobsData",
        await dispatch("common/getInitialData", payload)
      );
      if (success == true) {
        commit("setLoadingFinish");
      }
    },
    // 取得データを state に反映
    async setJobsData({ commit }, { data }) {
      if (!data || !data.data) return false;
      commit("setJobsNum", { data: data.data });
      commit("setIsMediaSync", { is_media_sync: data.is_media_sync });
      commit("setCanPauseToSync", data.can_pause_to_sync);
      return true;
    },
    // 反映を開始・停止
    async changeReflect({ commit, dispatch, state }) {
      dispatch("modal/closeModal"); // 停止確認モーダルを閉じる
      commit("setIsDisable", true);
      const u = "settings";
      await dispatch("patchData", {
        url: `${u}`,
        params: { is_media_sync: !state.isMediaSync },
      });
    },
    async patchData({ commit, dispatch, state }, payload) {
      const { url, params } = payload;
      const response = await httpClient.patch(url, params).catch((error) => {
        commit("common/" + commonTypes.ERROR);
        const e = {
          isShow: true,
          text: error,
        };
        dispatch("modal/showErrorModal", e);
      });
      if (response && response.status === API_CODE.response.success) {
        commit("setIsMediaSync", { is_media_sync: !state.isMediaSync });
      } else {
        const e = {
          isShow: true,
          text: "反映処理に失敗しました　時間をおいて再度お試しください",
        };
        dispatch("modal/showErrorModal", e);
      }
      commit("setIsDisable", false);
    },
    updateIsDisable({ commit, dispatch, state }, value) {
      commit("setIsDisable", value);
    },
  },
};

export default JobsControl;
