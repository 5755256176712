<template>
  <div class="inputPeriod">
    <datepicker
      name="period_from"
      v-model="from"
      :date="selfFrom"
      :disabled-dates="disabledDates"
      :is-required="isRequired"
    />
    <span class="separate">～</span>
    <datepicker
      name="period_to"
      v-model="to"
      :date="selfTo"
      :disabled-dates="disabledDates"
      :is-required="isRequired"
    />
  </div>
</template>

<script>
// component
import Datepicker from "@/components/atoms/common/Datepicker.vue";

export default {
  components: {
    Datepicker,
  },
  props: {
    name: {
      type: String,
      default: "period",
    },
    period: {
      type: Object,
      default: () => {
        return {
          from: null,
          to: null,
        };
      },
    }, // String型 "yyyy-MM-dd"
    disabledDates: {
      type: Object,
      default: () => {
        return {
          from: null,
          to: null,
        };
      },
    }, // Date型 toより前、from以降の期間を選択できない
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      selfFrom: this.period.from,
      selfTo: this.period.to,
    };
  },
  computed: {
    from: {
      get: function() {
        return this.selfFrom;
      },
      set: function(v) {
        this.selfFrom = v;
        if (v) {
          const from = this.toDate(this.selfFrom);
          const to = this.toDate(this.selfTo);
          if (to && to.getTime() < from.getTime()) {
            this.selfTo = this.selfFrom;
          }
        }
        this.update();
      },
    },
    to: {
      get: function() {
        return this.selfTo;
      },
      set: function(v) {
        this.selfTo = v;
        if (v) {
          const from = this.toDate(this.selfFrom);
          const to = this.toDate(this.selfTo);
          if (from && to.getTime() < from.getTime()) {
            this.selfFrom = this.selfTo;
          }
        }
        this.update();
      },
    },
  },
  watch: {
    period: {
      immediate: false, // 初期化時は発火しない
      handler() {
        this.selfFrom = this.period.from;
        this.selfTo = this.period.to;
      },
    },
  },
  methods: {
    toDate: function(v) {
      if (v) {
        const date = v.split("-").map((str) => parseInt(str, 10));
        return new Date(date[0], date[1] - 1, date[2]);
      } else {
        return null;
      }
    },
    update: function() {
      const period = {
        from: this.selfFrom,
        to: this.selfTo,
      };
      this.$emit("update", this.name, period);
    },
  },
};
</script>

<style scoped lang="scss">
.inputPeriod {
  display: flex;
  align-items: center;
  .separate {
    margin: 0 $SUB_GRID;
  }
}
</style>
