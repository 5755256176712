<template>
  <div class="datepicker" :class="customClass">
    <datepicker
      v-model="setDate"
      :name="name"
      :disabled="isDisabled"
      :disabled-dates="disabledDates"
      :inline="isShowOnlyCalender"
      :language="ja"
      :required="isRequired"
      :clear-button="!isRequired && clearButton"
      :format="format"
      :maximum-view="maximumView"
      :minimum-view="minimumView"
      :initial-view="initialView"
    />
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    Datepicker,
  },
  model: {
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    clearButton: {
      type: Boolean,
      default: true,
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
    format: {
      type: [String, Function],
      default: "yyyy-MM-dd",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isShowOnlyCalender: {
      type: Boolean,
      default: false,
    },
    maximumView: {
      type: String,
      default: "day",
    },
    minimumView: {
      type: String,
      default: "day",
    },
    initialView: {
      type: String,
      default: "day",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ja: ja,
      temp: this.date, // datepicker 入力検知用
    };
  },
  computed: {
    setDate: {
      get() {
        return this.date;
      },
      set(value) {
        let formatValue = null;
        if (value) {
          // 0埋め
          const month = ("0" + (value.getMonth() + 1)).slice(-2);
          const day = ("0" + value.getDate()).slice(-2);
          formatValue = value.getFullYear() + "-" + month + "-" + day;
        }
        this.temp = value;
        this.$emit("input", formatValue);
      },
    },
    hasRequiredError() {
      if (!this.isRequired) {
        return false;
      }
      return this.setDate ? false : true;
    },
    customClass() {
      return {
        disabled: this.isDisabled,
        hasError: this.hasRequiredError || this.hasError,
        fullWidth: this.fullWidth,
      };
    },
  },
  watch: {
    setDate: {
      immediate: true,
      handler() {
        const hasError = this.hasRequiredError || this.hasError;
        this.$emit("check", !hasError);
      },
    },
    hasRequiredError(newValue) {
      const hasError = newValue || this.hasError;
      this.$emit("check", !hasError);
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  display: inline-block;
  font-size: 14px;

  .vdp-datepicker__calendar {
    width: 28 * 7px;
    z-index: $LAYER_DATEPICKER;
    .cell {
      height: 28px;
      line-height: 28px;
    }
  }

  .sat {
    color: $COLOR_SATURDAY;
  }

  .sun {
    color: $COLOR_SUNDAY;
  }

  .vdp-datepicker__clear-button {
    line-height: 30px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 30px;
  }

  input[type="text"] {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: block;
    outline: none;
    padding: 7px 10px 6px;
    width: 110px;
  }

  &.disabled {
    input[type="text"] {
      background: $BG_COLOR_SUB;
      color: $COLOR_SUB_LIGHT_TEXT;
      cursor: not-allowed;
    }
  }

  &.hasError {
    input[type="text"] {
      background: #fee;
    }
  }

  &.fullWidth .vdp-datepicker__calendar {
    width: 100%;
  }
}
</style>
