<template>
  <div class="logFilter_wrap">
    <filter-block class="logFilter">
      <template #filter-l>
        <filter-input
          v-if="isShowParent"
          class="hallId"
          name="hallId"
          placeholder="式場ID"
          @update="setFilter"
        />
        <filter-input
          class="title"
          name="title"
          placeholder="フェア名・プラン名・特典名・画像名"
          @update="setFilter"
        />
        <div class="filterBlockItem">
          <input-period
            :disabled-dates="disabledDates"
            :is-required="true"
            :period="{
              from: filterParams.periodFrom || today,
              to: filterParams.periodTo || today,
            }"
            @update="setFilter"
          />
        </div>
        <div class="filterBlockItem isSetTit">
          <p class="filterBlockItemTit">アカウント名：</p>
          <filter-selectbox
            :value="filterParams.userId"
            :options="usersId"
            :force-emit="true"
            @input="setFilter('userId', $event)"
          />
        </div>
        <div class="filterBlockItem isSetTit">
          <p class="filterBlockItemTit">対象：</p>
          <filter-selectbox
            :value="filterParams.type"
            :options="master.operationable_type"
            :force-emit="true"
            @input="setFilter('type', $event)"
          />
        </div>
      </template>
      <template #filter-b>
        <action-button
          class="logFilterButton"
          text="検索する"
          :disabled="!isFilterChanged(filterParams)"
          @clickHandler="filterSearch"
        />
      </template>
    </filter-block>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { startOfToday, endOfToday, startOfMonth, subMonths, format } from "date-fns";

import { userName } from "@/store/job/operationLogs";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import FilterBlock from "@/components/molecules/common/FilterBlock.vue";
import FilterInput from "@/components/atoms/common/FilterInput.vue";
import FilterSelectbox from "@/components/atoms/common/FilterSelectbox.vue";
import InputPeriod from "@/components/molecules/common/InputPeriod.vue";

export default {
  components: {
    ActionButton,
    FilterBlock,
    FilterInput,
    FilterSelectbox,
    InputPeriod,
  },
  data() {
    return {
      today: format(startOfToday(), "y-M-d"),
      filterParams: {},
    };
  },
  computed: {
    ...mapGetters({
      isShowParent: "job/isShowParent",
      users: "job/operationLogs/users",
      master: "job/operationLogs/master",
      filter: "job/operationLogs/filter",
      isFilterChanged: "job/operationLogs/isFilterChanged",
    }),
    usersId() {
      return this.users.reduce(
        (usersId, user) => ({
          ...usersId,
          [user.id]: userName(user),
        }),
        {}
      );
    },
    disabledDates() {
      // 3ヶ月前の月初～本日まで選択可能、それ以外は選択不可にする
      return {
        from: endOfToday(),
        to: startOfMonth(subMonths(new Date(), 3)),
      };
    },
  },
  async created() {
    await this.init();
    this.filterParams = this.filter;
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    ...mapActions("job/operationLogs", ["init", "updateFilter", "reset"]),
    setFilter(key, value) {
      if (key === "period") {
        this.filterParams.periodFrom = value.from === this.today ? null : value.from;
        this.filterParams.periodTo = value.to === this.today ? null : value.to;
        return;
      }
      if (!value) {
        this.filterParams[key] = null;
        return;
      }
      this.filterParams[key] = value;
    },
    filterSearch() {
      this.updateFilter(this.filterParams);
    },
  },
};
</script>

<style scoped lang="scss">
.logFilter {
  margin-bottom: $GRID;
  .filterBlockItem > .filterCheck {
    margin-left: $SUB_GRID;
  }
  .title {
    width: 100%;
  }
  .hallId {
    width: $GRID * 7;
  }
  &Button {
    margin: $GRID * 0.5 0 0 auto;
  }
}
</style>
