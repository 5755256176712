<template>
  <ul class="radioList" :class="addClass">
    <li
      v-for="(item, i) in items"
      class="radioList_item"
      :key="i"
      @click="clickRadio(i)"
    >
      <label class="radioWrapper">
        <input class="inputRadio" type="radio" v-model="selfValue" :value="i" />
        <span class="radioText">{{ item }}</span>
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  model: {
    event: "input",
  },
  props: {
    value: [String, Number, Boolean],
    items: Object,
    isRequired: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isBoolean: {
      // 注意：trueにする場合、itemsのキーをtrueとfalseのみで設定する必要あり
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    noChange: {
      // 値を直接変更せずにemitで親に伝える
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      tmpIsValid: null,
    };
  },
  computed: {
    selfValue: {
      get() {
        let value = this.value;
        if (this.isBoolean) {
          value = value === true ? "true" : "false";
        }
        this.checkValidation(value);
        return value;
      },
      set(value) {
        if (this.noChange) {
          return;
        }
        this.sendInput(value);
      },
    },
    addClass() {
      return {
        hasError: !this.tmpIsValid,
        isReadOnly: this.isReadOnly,
        noChange: this.noChange,
      };
    },
  },
  methods: {
    checkValidation(value) {
      let isValid;
      if (!this.isRequired) {
        isValid = true;
      } else {
        isValid = value !== null && value !== "" ? true : false;
      }
      if (isValid !== this.tmpIsValid) {
        this.$emit("check", isValid);
        this.tmpIsValid = isValid;
      }
    },
    clickRadio(value) {
      this.$emit("click", this.isBoolean ? value === "true" : value);
    },
    sendInput(value) {
      if (this.isBoolean) {
        const result = value === "true";
        this.$emit("input", result);
      } else {
        this.$emit("input", Number(value));
      }
    },
  },
};
</script>

<style scoped lang="scss">
$body: "radioList";
.#{$body} {
  display: flex;
  &.hasError {
    .inputRadio {
      box-shadow: 0px 0px 3px rgba($COLOR_ALERT, 0.8);
    }
  }
  &.isReadOnly {
    cursor: not-allowed;
    .#{$body}_item {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  &.noChange {
    .#{$body}_item {
      cursor: pointer;
    }
    .radioWrapper {
      pointer-events: none;
    }
  }
  &_item {
    margin-right: 1em;
  }
}

.radioWrapper {
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 5px;
}

.inputRadio {
  cursor: pointer;
  margin: 0;
}

.radioText {
  margin-left: 5px;
}
</style>
