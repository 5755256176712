import API_CODE from "@/assets/common/ApiCode";
import { getSelectedHallId, selectHall } from "@/assets/common/PageConfig";
import * as commonTypes from "@/store/common/mutationTypes";
import { httpClient } from "@/lib/httpClient";

const actions = {
  /**
   * state の初期化
   */
  async getInitialData({ commit, dispatch, state, getters }) {
    await dispatch("common/checkPagePermission");
    await dispatch("setLinkPermission");
    const isParentAccount =
      getters["common/isViewableAnyHall"] || getters["common/isViewableOwnHall"];
    commit("SET_IS_SHOW_PARENT", isParentAccount && !getSelectedHallId());
    commit("SET_HALL_ID", isParentAccount);
    dispatch("filter/initialize"); // フィルタ初期化
    const resultWait = await dispatch("getWaitingJobsApi");
    const resultExec = await dispatch("getExecutedJobsApi");
    const success = resultExec && resultWait;
    if (success) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
    } else {
      dispatch("showErrorModal", "get");
    }
    const userableType = state.common.account.userable_type;
    const hallId = state.common.hall.hall_id;
    if (userableType !== "Admin" || hallId != null) {
      dispatch("utility/jobs/getJobsData"); // 待機中ジョブの取得・表示
    }
  },
  /**
   * 実行済みの処理リストを取得するAPIを実行(get)
   */
  async getExecutedJobsApi({ commit, dispatch, state }) {
    if (state.filter.filterType == "executed") {
      commit("modal/setUpdateModal", { isShow: true, text: "検索中です…" });
    }
    // 検索項目からクエリパラメータ作成
    let params = {
      [`${state.queryName["period"]}_from`]: state.filter.filter.period.from,
      [`${state.queryName["period"]}_to`]: state.filter.filter.period.to,
    };
    Object.keys(state.filter.filter).forEach((k) => {
      if (k != "period" && state.filter.filter[k]) {
        params[state.queryName[k]] = state.filter.filter[k];
      }
    });
    const url = "/logs/done";
    const response = await httpClient.get(url, { params });
    const data = await dispatch("getResponseData", response);
    if (data) {
      if (data.master) {
        await commit("UPDATE_MASTER", data.master);
      }
      if (data.data) {
        commit("UPDATE_EXECUTED_JOBS", await dispatch("setAdditionalData", data.data));
      }
    }
    commit("modal/setUpdateModal", { isShow: false });
    return response && response.status === API_CODE.response.success;
  },
  /**
   * 待機中の処理リストを取得するAPIを実行(get)
   */
  async getWaitingJobsApi({ commit, dispatch }) {
    const params = { contains_in_sync: false };
    const response = await httpClient.get("/jobs/incomplete", { params });
    const data = await dispatch("getResponseData", response);
    if (data) {
      if (data.master) {
        commit("UPDATE_MASTER", data.master);
      }
      if (data.data) {
        commit("UPDATE_WAITING_JOBS", data.data);
      }
    }
    return response && response.status === API_CODE.response.success;
  },
  /**
   * 初期データに追加
   * @param {Object} data 初期データ
   */
  async setAdditionalData({ state }, data) {
    return data.map((d) => {
      // isDisabledLink 追加
      let type = d.queueable_type.toLowerCase();
      const authorizedKey =
        type == "venue"
          ? "setting"
          : Object.keys(state.isAuthorizedLink).find((k) => type.indexOf(k) !== -1);
      if (authorizedKey) {
        d.isDisabledLink = !state.isAuthorizedLink[authorizedKey].edit;
      }
      return d;
    });
  },
  // タブ切り替え
  updateTabKey({ commit }, key) {
    commit("SET_TAB_KEY", key);
  },
  /**
   * API の response.data を取得する
   * @param {*} param0
   * @param {*} response レスポンス
   */
  getResponseData({}, response) {
    let r = response;
    if (r) {
      if (!r.data && r.response) {
        r = r.response; // catch されないエラーの場合
      }
      if (r.data) {
        return r.data;
      }
    }
    return null;
  },
  /**
   * エラーモーダルのメッセージを変更する
   * @param {*} param0
   * @param {String} text メッセージ
   */
  setErrorText({ commit }, text) {
    commit("UPDATE_ERROR_TEXT", text);
  },
  /**
   * エラーモーダルを表示する
   * @param {*} param0
   * @param {*} type セット済みのメッセージが無い場合に type 別デフォルトメッセージを表示
   */
  showErrorModal({ commit, state }, type) {
    commit("common/" + commonTypes.ERROR);
    const errorModal = {
      isShow: true,
      text: state.errorText || state.setup.errorTexts[type],
    };
    commit("modal/setErrorModal", errorModal);
  },
  /**
   * ページ移動
   * @param {Object} payload { path, hallId }
   */
  movePage({}, payload) {
    const { path, hallId } = payload;
    if (hallId) selectHall(hallId);
    window.location.href = path;
  },
  // 各 link の表示権限
  async setLinkPermission({ commit, state, getters }) {
    Object.keys(state.isAuthorizedLink).forEach((unit) => {
      let value = {};
      Object.keys(state.isAuthorizedLink[unit]).forEach((k) => {
        if (k == "permissionName") return;
        value[k] = getters["common/isAuthorized"]({
          name: state.isAuthorizedLink[unit].permissionName,
          type: k,
        });
      });
      commit("SET_IS_AUTHORIZED_LINK", { name: unit, value });
    });
  },
};
export default actions;
