import { httpClient } from "@/lib/httpClient";
import API_CODE from "@/assets/common/ApiCode";

import commonModule from "@/store/common";
import modalModule from "@/store/modal";

const ActiveHallUsers = {
  namespaced: true,
  modules: {
    common: commonModule,
    modal: modalModule,
  },
  state: {
    users: [],
  },
  getters: {
    users: (state) => {
      return state.users;
    },
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
  },
  actions: {
    async getActiveHallUsers({ commit, getters, dispatch }) {
      const params = { in_operation: 1 };
      const response = await httpClient.get("/users", { params });
      if (response && response.status === API_CODE.response.success) {
        const userId = getters["common/userId"];
        let list = response.data.data.filter((user) => user.id !== userId);
        commit("setUsers", list);
      } else {
        dispatch("modal/showErrorModal", {
          text: "ログインユーザーの取得に失敗しました",
        });
      }
    },
  },
};

export default ActiveHallUsers;
