/**
 * ページ内で画像選択用モーダルを一括管理する用のモジュール
 * - ImageSelectWrapper.vueとセットで使う
 * - その際に、ModuleのnamespaceをImageSelector.vueにprops経由で渡すこと（デフォルトは`imageSelect`）
 * - 基本的な機能を`organisms/common/imageSelector.vue`にまとめているので、それを使うと簡単な操作が可能
 * - 自分なりにカスタマイズして使用したい場合は以下を参考に
 *   - gettersやactionsの実行はnamespaceをつけて行う
 *   - 基本的な操作はgettersと、actions内のstartSelectとsetByNameSetだけで事足りるはず
 *   - 画像を選択したりデータを取り出したりするときには、マッピング用の一意の文字列をnameとして指定する必要あり
 */

const CURRENT_DEFAULT = Object.freeze({
  name: null,
  selected: -1,
  types: [],
  mediaIds: [],
  needAllUrls: true,
});

const ImageSelect = {
  namespaced: true,
  state: {
    isOpen: false,
    images: {},
    current: { ...CURRENT_DEFAULT },
    originals: {},
    isSelectableImage: () => {},
  },
  getters: {
    originals: ({ originals }) => originals,
    image: (state, getters) => (
      name,
      targetMediaIds,
      targetUrls,
      needAllUrls = true
    ) => {
      const image = state.originals[state.images[name]] || null;
      if (!image || (!targetMediaIds && !targetUrls)) {
        return image;
      }
      // 媒体IDやURLが指定してある場合は、選択可能な場合のみ画像データを返す
      const isSelectable = getters.isSelectableImage(
        image,
        targetMediaIds,
        targetUrls,
        needAllUrls
      );
      return isSelectable === true ? image : null;
    },
    imageById: (state, getters) => (
      id,
      targetMediaIds,
      targetUrls,
      needAllUrls = true
    ) => {
      const image = state.originals[id] || null;
      if (!image || (!targetMediaIds && !targetUrls)) {
        return image;
      }
      // 媒体IDやURLが指定してある場合は、選択可能な場合のみ画像データを返す
      const isSelectable = getters.isSelectableImage(
        image,
        targetMediaIds,
        targetUrls,
        needAllUrls
      );
      return isSelectable === true ? image : null;
    },
    isSelectableImage: (state) => (
      image,
      targetMediaIds,
      targetUrls,
      needAllUrls = true
    ) => {
      return image
        ? state.isSelectableImage(
            image,
            targetMediaIds,
            targetUrls,
            needAllUrls
          )
        : false;
    },
    imageId: (state, getters) => (name) => {
      const image = getters.image(name);
      return image && image.id ? image.id : null;
    },
    thumb: (state, getters) => (name) => {
      const image = getters.image(name);
      if (!image) {
        return null;
      }
      return image.urls.thumb || image.urls.compressed || image.urls.originals;
    },
    thumbById: ({ originals }) => (imageId) => {
      const image = originals[imageId];
      if (!image) {
        return null;
      }
      return image.urls.thumb || image.urls.compressed || image.urls.originals;
    },
  },
  mutations: {
    isOpen(state, isOpen) {
      state.isOpen = isOpen;
    },
    current(state, params) {
      Object.keys(state.current).forEach((item) => {
        state.current[item] =
          item in params ? params[item] : CURRENT_DEFAULT[item];
      });
    },
    currentImage(state, imageId) {
      let images = { ...state.images };
      images[state.current.name] = imageId;
      state.images = { ...images };
    },
    imageByNameSet(state, nameSet) {
      let images = { ...state.images };
      Object.keys(nameSet).forEach((name) => {
        images[name] = nameSet[name];
      });
      state.images = { ...images };
    },
    originals(state, data) {
      state.originals = { ...data };
    },
    functions(state, data) {
      Object.keys(data).forEach((key) => {
        if (!state[key]) {
          return;
        }
        state[key] = data[key];
      });
    },
  },
  actions: {
    /**
     * モーダルを開いて画像選択を開始する
     * @param {*} params 選択開始時に以下を含めたデータが必要
     * - name: String 必須。データを判別するための任意の文字列。gettersで同じ文字列を指定することで、選択した画像のデータを取り出せる。
     * - selected: Number 任意。既に選択済みの画像IDがあれば入れる。一覧で今選択されているものが分かるようになる。
     * - types: Array 任意。設定済みのurlsの種類で制限をかけたい場合に使用する
     *   （例：["1:1"]とすると"1:1"のurlがある画像だけ選択可能になる）
     */
    startSelect({ state, commit, getters }, params) {
      if (!params.name) {
        return false;
      }
      commit("current", params);
      commit("isOpen", true);
    },

    /**
     * APIから取得した場合など、すでに設定済みの画像IDがあって前もってサムネイル画像などのデータを取得したい時に使用する
     * @param {*} nameSet データ判別用のnameをキーに、画像IDを値に設定したオブジェクト
     */
    setByNameSet({ commit }, nameSet) {
      commit("imageByNameSet", nameSet);
    },

    closeModal({ commit, dispatch }) {
      commit("isOpen", false);
      dispatch("resetSelect");
    },
    resetSelect({ commit }) {
      commit("current", CURRENT_DEFAULT);
    },
    setImageId({ state, commit, dispatch }, ImageId) {
      if (state.current.name === null) {
        return false;
      }
      commit("currentImage", ImageId);
      dispatch("closeModal");
    },
    setOriginalImages({ commit }, data) {
      const originals = {};
      Object.values(data).forEach((image) => {
        originals[image.id] = image;
      });
      commit("originals", originals);
    },
    setFunctionStates({ commit }, data) {
      commit("functions", data);
    },
  },
};

export default ImageSelect;
