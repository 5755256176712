<template>
  <div class="listUnitMenu" v-on-clickaway="away">
    <button
      type="button"
      class="listUnitMenu_btn"
      :class="{ isActive: isShow, isDisabled }"
      @click="changeIsShow()"
    >
      <i class="material-icons">{{ isShow ? "close" : "more_vert" }}</i>
    </button>
    <div class="listUnitMenu_menu" v-show="isShow">
      <slot name="menu" />
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      isShow: false,
    };
  },
  computed: {},
  methods: {
    changeIsShow: function() {
      this.isShow = !this.isShow;
    },
    away: function() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.listUnitMenu {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: $LAYER_CONTENT_MENU;
  &_btn {
    @include Box();
    -webkit-appearance: none;
    align-items: center;
    background: rgba($COLOR_KEY, 0.1);
    border: none;
    box-shadow: none;
    color: $COLOR_SUB_LIGHT_TEXT;
    display: flex;
    height: $GRID * 2;
    justify-content: center;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    width: $GRID * 2;
    &:hover {
      opacity: 0.8;
    }
    &.isActive {
      background: #fff;
    }
    &.isDisabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &_menu {
    background: #fff;
    box-shadow: $DEPTH_LV_0;
    padding: $GRID * 2 0 0;
  }
}
</style>
