<template>
  <filter-block class="imgFilter" title="画像" :class="{ isModalWindow }">
    <template #filter-l>
      <filter-input
        class="inputTextbox"
        ref="keyword"
        name="keyword"
        placeholder="ID・画像名・ファイル名"
        @update="filter"
      />
      <div class="filterBlockItem isSetTit">
        <p class="filterBlockItemTit">版権期限：</p>
        <filter-selectbox
          v-model="filterItem.license"
          :value="filterItem.license"
          :options="filterMaster.license"
        />
      </div>
      <div class="filterBlockItem isSetTit">
        <p class="filterBlockItemTit">フェア、プラン、特典での利用状況：</p>
        <filter-selectbox
          v-model="filterItem.in_use"
          :value="filterItem.in_use"
          :options="filterMaster.in_use"
        />
      </div>
      <div class="filterBlockItem isSetTit">
        <p class="filterBlockItemTit">ゼクシィに連携状態：</p>
        <filter-selectbox
          v-model="filterItem.linked_zexy"
          :value="filterItem.linked_zexy"
          :options="filterMaster.media_status"
        />
      </div>
      <div class="filterBlockItem isSetTit">
        <p class="filterBlockItemTit">マイナビに連携状態：</p>
        <filter-selectbox
          v-model="filterItem.linked_mynavi"
          :value="filterItem.linked_mynavi"
          :options="filterMaster.media_status"
        />
      </div>
      <div class="filterBlockItem isCheckbox">
        <filter-checkbox
          label="check_trimming"
          text="トリミング設定されていないもの"
          :value="filterItem.check_trimming"
          @update="filterCheck"
        />
      </div>
      <div class="filterBlockItem isCheckbox">
        <filter-checkbox
          label="check_no_tag"
          text="タグが設定されていないもの"
          :value="filterItem.check_no_tag"
          @update="filterCheck"
        />
      </div>
    </template>
    <template #filter-r>
      <div class="filterBlockItem">
        <div class="tagTitle">
          <i class="material-icons">local_offer</i>
          <span>タグ</span>
          <action-button
            v-if="!isModalWindow"
            class="editBtn"
            text="編集する"
            :is-select="true"
            @clickHandler="isShow = true"
          />
        </div>
        <filter-checkbox
          v-for="(v, k) in filterTags"
          :key="k"
          :label="String(k)"
          :text="v.name"
          :value="isSelectedTag(v.id)"
          :is-btn-type="true"
          @update="filterArray('tags', v.id)"
        />
      </div>
      <modal-window
        v-if="isShow"
        close-text="キャンセル"
        @close="isShow = false"
      >
        <template #text>
          <p>
            タグを編集しますか？
            <br />※式場基本設定に移動します
          </p>
        </template>
        <template #footerBtn>
          <action-button
            text="編集する"
            @clickHandler="movePage({ path: '/hall-init-setting/#image-tag' })"
          />
        </template>
      </modal-window>
    </template>
  </filter-block>
</template>

<script>
// component
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import FilterBlock from "@/components/molecules/common/FilterBlock.vue";
import FilterCheckbox from "@/components/atoms/common/FilterCheckbox.vue";
import FilterInput from "@/components/atoms/common/FilterInput.vue";
import FilterSelectbox from "@/components/atoms/common/FilterSelectbox.vue";
import ModalWindow from "@/components/molecules/common/ModalWindow.vue";
// store
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    ActionButton,
    FilterBlock,
    FilterCheckbox,
    FilterInput,
    FilterSelectbox,
    ModalWindow,
  },
  props: {
    isModalWindow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    ...mapState("filter", ["filterMaster"]),
    ...mapGetters("filter", ["filterItem", "isClearedFilter", "sortData"]),
    ...mapGetters(["filterTags"]),
  },
  methods: {
    ...mapActions("filter", [
      "clearedFilter",
      "updateFilter",
      "updateFilterArray",
      "updateFilterCheck",
      "updateCheckedId",
    ]),
    ...mapActions(["movePage"]),
    filter: function (label, value) {
      let data = { label, value };
      this.updateFilter(data);
    },
    filterArray: function (label, value) {
      let data = { label, value };
      this.updateFilterArray(data);
    },
    filterCheck: function (label, value) {
      let data = { label, value };
      this.updateFilterCheck(data);
    },
    isSelectedTag(tagId) {
      return this.filterItem.tags.includes(tagId);
    },
  },
  watch: {
    isClearedFilter: {
      immediate: false, // 初期化時は発火しない
      handler() {
        if (this.isClearedFilter) {
          this.$refs.keyword.$el.value = "";
          this.clearedFilter();
        }
      },
    },
  },
  mounted() {
    if (this.isClearedFilter) {
      this.clearedFilter();
    }
  },
};
</script>

<style scoped lang="scss">
.imgFilter {
  color: $COLOR_SUB_TEXT;
  font-size: 14px;
  margin-bottom: $GRID;
  width: 100%;
  &.isModalWindow {
    margin: $GRID * 0.25 $GRID * 0.25 $GRID;
    width: calc(100% - #{$GRID} * 0.5);
  }
  .material-icons {
    font-size: 16px;
    margin-right: $GRID * 0.25;
  }
  .tag {
    &Title {
      align-items: center;
      display: flex;
      margin-bottom: $SUB_GRID;
      .editBtn {
        margin-left: $GRID;
      }
    }
  }
  .inputTextbox {
    width: 100%;
  }
  ::v-deep .filterBlock {
    &Tit {
      flex-shrink: 0;
      width: $GRID * 7;
    }
    &Inr-l {
      flex-shrink: 0;
      width: $GRID * 20;
    }
  }
  .filterBlockItemTit {
    width: $GRID * 8;
  }
}
</style>
