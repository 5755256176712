import { httpClient, setForcedBaseUrl } from "@/lib/httpClient";
import * as commonTypes from "@/store/common/mutationTypes";
import { DemoAccountType } from "@/assets/hall-admin/demo-account-type";
import { StatusTypes } from "@/store/hall-admin/status-types";
import API_CODE from "@/assets/common/ApiCode.js";
import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";
import { selectHall } from "@/assets/common/PageConfig.js";

const PERMISSION = {
  import: "startImport",
  associate: "associateImport",
  create: "completeData",
  confirm: "publish",
  halls: "halls",
  settings: "settings",
  mediaAccounts: "mediaAccounts",
};

// デモアカウントを生成する際のプランやフェアの数
const DATA_SET_NUMBER = {
  [DemoAccountType.HALL]: 1,
  [DemoAccountType.VENDOR]: 10,
};

const actions = {
  /*
   * 初期データを受け取る
   ***********************************/
  /**
   * 初期値の取得
   */
  async getInitialData(
    { commit, dispatch, state, getters },
    coremoneHallsOnly = false
  ) {
    await dispatch("common/checkPagePermission");

    const payload = {
      apiUrl: "/halls",
      params: { coremone_halls_only: coremoneHallsOnly ? 1 : 0 },
    };
    const response = await dispatch("common/getInitialData", payload);
    const success = await dispatch("setHallList", response);

    // edit.any.*** の権限確認
    Object.keys(PERMISSION).forEach((type) => {
      const value = getters["common/isAuthorized"]({
        name: PERMISSION[type],
        type: "edit",
      });
      commit("SET_IS_AUTHORIZED", { type, value });
    });
    if (!state.isAuthorized.halls) commit("filter/SET_RESTRICTED_MASTER");
    if (success) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
      if (coremoneHallsOnly) dispatch("getWholeHallList");
    } else {
      commit("common/" + commonTypes.ERROR);
      dispatch("showErrorModal");
    }
  },
  /**
   * 式場一覧（全ての式場）を取得する
   */
  async getWholeHallList({ dispatch, commit }) {
    const halls = await dispatch("common/getInitialData", {
      apiUrl: "/halls",
    });

    await dispatch("setHallList", halls);
    commit("SET_WHOLE_HALL_LIST_LOADED");
  },
  /**
   * 式場アカウントリストを state に反映
   * @param {Object} response
   */
  async setHallList({ commit }, { status, data }) {
    if (status === API_CODE.response.success) {
      if (data) {
        if (data.data) {
          let halls = data.data;
          // client_name を { media_id: client_name } の形式で別途保存
          halls = halls.map((hallData) => {
            const clientNameByMedia = {};
            hallData.media.forEach((mediaData) => {
              clientNameByMedia[mediaData.media_id] = mediaData.client_name;
            });
            hallData.clientNameByMedia = clientNameByMedia;
            return hallData;
          });
          await commit("SET_HALL_DATA", data.data);
          commit("SET_HALL_COUNT");
        }
        if (data.master) commit("SET_HALL_MASTER", data.master);
        return true;
      }
    }
    return false;
  },
  /**
   * 利用開始前式場の情報を取得する
   */
  async fetchPotentialHalls({ dispatch }, hallIds = []) {
    const params = hallIds.length > 0 ? { hall_ids: hallIds.join(",") } : {};
    const { status, data } = await dispatch("common/getInitialData", {
      apiUrl: `/potential-halls`,
      params,
    });
    if (status !== API_CODE.response.success) {
      const text = "式場情報の取得に失敗しました";
      dispatch("modal/showErrorModal", { text, isShow: true });
      return;
    }
    const potentialHalls = data.data;
    if (potentialHalls.length === 0) {
      const text = "該当の式場が見つかりませんでした";
      dispatch("modal/showErrorModal", { text, isShow: true });
      return;
    }
    return potentialHalls;
  },

  /*
   * 設定
   ***********************************/
  /**
   * APIを実施する
   */
  async request(
    { commit, dispatch },
    { url, method, payload, successStatus, text }
  ) {
    const modalPayload = { isShow: true, text: "更新中です…" };
    commit("modal/setUpdateModal", modalPayload);
    const { data, status } = await httpClient[method](url, payload);
    if (status !== successStatus) {
      const errorText = data.message || text;
      dispatch("modal/showErrorModal", { text: errorText, isShow: true });
      return false;
    }
    return data || true;
  },
  /**
   * 式場マスタアカウント新規作成(開始前⇒準備中)
   * @param {Object} payload { hall_id, media_ids, feature_types }
   */
  async addMasterAccount({ commit, dispatch }, payload) {
    const success = await dispatch("request", {
      payload,
      url: "/halls/init",
      method: "post",
      successStatus: API_CODE.response.created,
      text: "初期アカウントの作成に失敗しました",
    });
    if (!success) {
      return false;
    }
    commit("modal/setCompleteText", "初期アカウントを作成しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
    return true;
  },
  /**
   * インポート開始
   * @param {Object} payload { hall_id, import_target }
   */
  async startImport({ commit, dispatch }, payload) {
    const success = await dispatch("request", {
      payload,
      url: "/halls/import/start",
      method: "post",
      successStatus: API_CODE.response.success,
      text: "インポート開始に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "インポート開始しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
    commit("filter/SET_FILTER", {
      label: "usageState",
      value: "account_created_at",
    });
    commit("filter/SET_TAB_KEY", "account_created_at");
  },
  /**
   * データ作成を完了
   * @param {Object} payload { hall_id }
   */
  async completeCreateData({ commit, dispatch }, payload) {
    const success = await dispatch("request", {
      payload,
      url: "/halls/maintenance/complete",
      method: "post",
      successStatus: API_CODE.response.success,
      text: "メンテナンスの完了に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "メンテナンスを完了しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * 式場にデータを公開
   * @param {Object} payload { hall_id }
   */
  async publish({ commit, dispatch }, payload) {
    const success = await dispatch("request", {
      payload,
      url: "/halls/ready",
      method: "post",
      successStatus: API_CODE.response.success,
      text: "データの公開に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "データを公開しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * ジョブ反映を開始(準備中⇒利用中)
   * @param {Object} payload { hall_id }
   */
  async reflect({ commit, dispatch }, { hall_id }) {
    setForcedBaseUrl(hall_id); // 式場ID を API ベース URLにセット
    const success = await dispatch("request", {
      url: "/settings",
      method: "post",
      payload: { is_media_sync: true },
      successStatus: API_CODE.response.success,
      text: "利用開始に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "利用開始しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * パスワード再発行
   * @param {Object} payload { hall_id }
   */
  async reset({ commit, dispatch }, payload) {
    const success = await dispatch("request", {
      payload,
      url: "/halls/password/reset",
      method: "post",
      successStatus: API_CODE.response.success,
      text: "パスワードの再発行に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "パスワードを再発行しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * コメントを保存
   * @param {Object} payload { hall_id, comment }
   */
  async save({ commit, dispatch }, payload) {
    const data = await dispatch("request", {
      payload,
      url: "/halls/comment",
      method: "put",
      successStatus: API_CODE.response.success,
      text: "保存に失敗しました",
    });
    if (!data) {
      return;
    }
    commit("UPDATE_HALL_COMMENT", data);
    commit("modal/setCompleteText", "保存しました");
    dispatch("modal/showCompleteModal");
  },

  /**
   * 対象媒体を取得
   * @param {Object} payload { hall_id }
   */
  async getTargetMedia(_, { hall_id }) {
    setForcedBaseUrl(hall_id); // 式場ID を API ベース URLにセット
    const { status, data } = await httpClient.get("/media/accounts");
    if (data && data.data) {
      return Object.values(data.data).map((d) => d.media.media_id);
    } else {
      return [];
    }
  },
  /**
   * 対象媒体を更新
   * @param {Object} payload { hall_id, media_ids }
   */
  async updateTargetMedia({ commit, dispatch }, payload) {
    const success = await dispatch("request", {
      payload,
      url: "/halls/media/reset",
      method: "post",
      successStatus: API_CODE.response.success,
      text: "保存に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "保存しました");
    dispatch("modal/showCompleteModal");
  },
  /**
   * デモアカウントを作成
   * @param {Object} payload { hallName }
   */
  async putDemoAccount({ commit, dispatch }, { hallName, accountType }) {
    const dataset_number = DATA_SET_NUMBER[accountType];
    const payload = { dataset_number, hall_name: hallName };
    const success = await dispatch("request", {
      payload,
      url: "/halls/demo",
      method: "post",
      successStatus: API_CODE.response.created,
      text: "保存に失敗しました",
    });
    if (!success) {
      return false;
    }
    commit("modal/setCompleteText", "保存しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
    return true;
  },
  /**
   * 式場利用状況設定
   * @param {Object} payload { hall_id, statusType }
   */
  async resetStatus({ commit, dispatch }, { hall_id, statusType }) {
    const domains = {
      [StatusTypes.ACCOUNT_CREATED]: "media-account",
      [StatusTypes.PREPARATION_STARTED]: "import",
      [StatusTypes.IMPORT_ENDED]: "associate",
    };
    const domain = domains[statusType];
    const success = await dispatch("request", {
      url: `/halls/${domain}/reset`,
      method: "post",
      payload: { hall_id },
      successStatus: API_CODE.response.success,
      text: "式場利用状況更新に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "式場利用状況を更新しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * 式場利用停止
   * @param {Object} payload { hall_id, suspended_at }
   */
  async suspend({ commit, dispatch }, payload) {
    const success = await dispatch("request", {
      payload,
      url: `/halls/suspend`,
      method: "post",
      successStatus: API_CODE.response.success,
      text: "式場利用終了日の更新に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "式場利用終了日を更新しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * 式場データ削除
   * @param {Object} payload { hall_id }
   */
  async deleteHall({ commit, dispatch }, { hall_id }) {
    const success = await dispatch("request", {
      url: `/halls/reset`,
      method: "post",
      payload: { hall_id },
      successStatus: API_CODE.response.success,
      text: "削除に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "削除しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * サービスの利用開始
   * @param {Object} payload { hall_id, feature_types }
   */
  async startFeatureUsage({ commit, dispatch }, payload) {
    setForcedBaseUrl(null);
    const success = await dispatch("request", {
      payload,
      url: `/halls/start-using`,
      method: "post",
      successStatus: API_CODE.response.success,
      text: "利用開始に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "利用開始しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * サービスの利用終了
   * @param {Object} payload { hall_id, feature_types }
   */
  async stopFeatureUsage({ commit, dispatch }, payload) {
    setForcedBaseUrl(null);
    const success = await dispatch("request", {
      payload,
      url: `/halls/stop-using`,
      method: "post",
      successStatus: API_CODE.response.success,
      text: "利用終了に失敗しました",
    });
    if (!success) {
      return;
    }
    commit("modal/setCompleteText", "利用終了しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /*
   * 共通
   ***********************************/
  /**
   * API の response.data を取得する
   * @param {Object}} response レスポンス
   */
  /**
   * エラーモーダルのメッセージを変更する
   * @param {String} text メッセージ
   */
  setErrorText({ commit }, text) {
    commit("SET_ERROR_TEXT", text);
  },
  /**
   * エラーモーダルを表示する
   * @param {*} type セット済みのメッセージが無い場合に type 別デフォルトメッセージを表示
   */
  showErrorModal({ commit, state }, type) {
    const defaultText = MESSAGE_CONFIG.response[type] || MESSAGE_CONFIG.error;
    commit("common/" + commonTypes.ERROR);
    const errorModal = {
      isShow: true,
      text: state.errorText || defaultText,
    };
    commit("modal/setErrorModal", errorModal);
  },
  /**
   * ページ移動
   * @param {Object} payload { path, hall_id }
   */
  movePage({}, payload) {
    const { path, hallId } = payload;
    if (hallId) selectHall(hallId);
    window.location.href = path;
  },

  async jobRetry({ commit, dispatch }, { hallId, jobId }) {
    setForcedBaseUrl(hallId);
    commit("modal/setUpdateModal", { isShow: true });
    const url = `/jobs/${jobId}/retry`;
    const response = await httpClient.post(url);
    const isSuccess = response && response.status == API_CODE.response.success;
    if (isSuccess) {
      commit("modal/setCompleteText", "更新しました");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("modal/showErrorModal", { text: MESSAGE_CONFIG.error });
    }
  },
};

export default actions;
