<template>
  <div class="importTab">
    <action-button
      v-for="v in list"
      :key="v.id"
      :is-select="true"
      :text="v.name"
      @clickHandler="scroll(v.id)"
    />
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import API_CODE from "@/assets/common/ApiCode.js";
import deepCopy from "@/lib/deepCopy";
// component
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
  },
  props: {
    order: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    list: function () {
      let l = [{ id: "top", name: "結合済み" }];
      deepCopy(this.order)
        .map((v) => {
          v.id = "m-" + v.id;
          return v;
        })
        .forEach((v) => l.push(v));
      return l;
    },
  },
  methods: {
    scroll: function (hash) {
      VueScrollTo.scrollTo(`#${hash}`, "mainBlock", {
        offset: -200,
        x: true,
        y: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.importTab {
  align-items: flex-start;
  display: flex;
  * + * {
    margin-left: $SUB_GRID;
  }
}
</style>
