import API_CODE from "@/assets/common/ApiCode";
import { getSelectedHallId } from "@/assets/common/PageConfig.js";
import SERVICE_TYPE from "@/assets/common/service-type";
import { usageTargets, serviceType, serviceStatus } from "@/assets/hall-admin/coremonet-config";

import { getLogPermissionName } from "@/store/common/helper";

/**
 * 共通 getter
 *
 * 基本的に role による判定を推奨しません
 * 各機能別に権限を確認してください
 ***********************************/
const getters = {
  account: ({ account }) => account,

  /**
   * 現在ログイン中ユーザーのロール
   */
  role: (state) => {
    return state.account ? state.account.role : null;
  },
  /**
   * 現在ログイン中ユーザーのID
   */
  userId: (state) => {
    return state.account ? state.account.id : null;
  },
  /**
   * 現在ログイン中ユーザーが自身の式場のマスタアカウントかどうか
   */
  isMasterAccount: (state) => {
    return state.account && state.account.is_master;
  },
  /**
   * 初期設定中であるか
   * 初期設定は権限による判定が難しい箇所があるため role で判定
   * 管理・企業⇒式場場合は選択式場の !!usage_started_at
   */
  isInitialSetting: (state) => {
    if (!state.account || !state.account.role) return false;
    // 初期設定 role
    const initRole = [API_CODE.role.temporary, API_CODE.role.initial, API_CODE.role.waiting];
    return initRole.indexOf(state.account.role) !== -1;
  },
  /**
   * 自身に紐づく一部の式場リストを閲覧できるか
   */
  isViewableOwnHall: (state) => {
    if (!state.account || !state.permissions) return false;
    const f = state.permissions.halls;
    return !!f && Array.isArray(f.own) && f.own.length >= 1; // halls.own.view
  },
  /**
   * 全式場リストを閲覧できるか
   */
  isViewableAnyHall: (state) => {
    if (!state.account || !state.permissions) return false;
    const f = state.permissions.halls;
    return !!f && Array.isArray(f.any) && f.any.length >= 1; // halls.any.view
  },
  /**
   * 各機能の権限があるか
   * @param {Object} permission { name: 機能名, type: 'edit' など, isHallList: 各式場の編集, 設定 }
   */
  isAuthorized: (state, getters) => (permission) => {
    const { name, type, isHallList } = permission;
    if (!name) return false;
    if (state.account === null || state.permissions === null) return false;

    // ロールが存在しない場合にロールを再設定する
    const generateTarget = () => {
      if ("isHallList" in permission && !isHallList) return "self";
      if (getters.isViewableOwnHall) return "own";
      if (getters.isViewableAnyHall) return "any";
      return "self";
    };
    const target = permission.target || generateTarget();

    const permissions = (state.permissions[name] || {})[target];
    if (!Array.isArray(permissions)) return false;
    // edit は full 権限
    if (permissions.includes("edit")) return true;
    return type && permissions.includes(type);
  },
  /**
   * 表示ID, 名前
   */
  displayInfo: (state) => {
    if (!state.account) return {};
    return {
      userable_id: state.account.userable_id, // ログインID
      display_group_name: state.account.display_group_name, // 所属グループ
      name: state.account.name, // ログインユーザー
      display_name: state.account.display_name, // ログインユーザー（表示名）
      hall_id: getSelectedHallId(), // 選択式場ID（選択時のみ）
      hall_name: state.account.hall_name, // 選択式場名（選択時のみ）
    };
  },
  /**
   * 式場種別（hall, company, admin）
   */
  hallType: (state) => {
    if (!state.account || !state.account.userable_type) return false;
    if (state.account.userable_type == "CompanyAccount") {
      return "company";
    } else if (state.account.userable_type == "HallInfoAccount") {
      return state.account.userable_id < 1000000 ? "hall" : "admin";
    } else {
      return state.account.userable_type;
    }
  },
  /**
   * ログ表示用
   */
  isLogAuthorized: (s, getters) => (type) => {
    const name = getLogPermissionName(type);
    if (!name) {
      return false;
    }
    return getters.isAuthorized({
      name,
      type: "edit",
    });
  },
  /**
   * プランと媒体側のプランIDを紐づけできるかどうかを取得する
   */
  isEditableMediaPlanId: (state) => {
    if (!state.account || !state.permissions) {
      return false;
    }

    const plans = state.permissions.plans;
    if (!plans || !Array.isArray(plans.any)) {
      return false;
    }

    return plans.any.includes("edit");
  },
  /**
   * コレモネっとが利用可能かどうかを取得する
   */
  enabledCoremonet: (_, getters) => {
    return getters.isAuthorized({ name: "coremonet", type: "edit" });
  },
  isCoremonetViewable: (_, getters) => {
    return getters.isAuthorized({ name: "coremonet", type: "view" });
  },
  /**
   * フェア、プランでコレモネっとが連携可能かどうかを取得する
   */
  isCoremonetLinkable: (_, getters) => {
    return getters.enabledCoremonet || getters.isCoremonetViewable;
  },
  /**
   * コレモネっとの開発ベンダか
   */
  isVendor: (_, getters) => getters.role === "system_vendor",

  loading: ({ loading }) => loading,

  /**
   * 式場の利用状況に関するgetter
   */
  hall: ({ hall }) => hall,
  isUsed: ({ hall }) => {
    return hall.usage_started_at === undefined ? null : !!hall.usage_started_at;
  },
  notices: ({ hall, master }) => {
    if (Array.isArray(hall.notices) && master.media) {
      return hall.notices.map((v) => {
        const mediaName = v.media_ids.map((mediaId) => master.media[mediaId].name).join("、");
        v.displayText = `${v.message}（${mediaName}）`;
        return v;
      });
    }
    return hall.notices;
  },
  medias: ({ master }) => master.media,
  coremonetStatus: ({ hall }) => {
    if (!hall) return null;

    // 利用開始
    if (hall.in_usage_target.filter((target) => usageTargets.includes(target)).length) {
      return serviceStatus.IN_SERVICE;
    }

    // 利用準備中、または停止中
    if (hall.in_preparation_target.filter((target) => usageTargets.includes(target)).length) {
      const service = hall.services[serviceType];
      return !service || service.started_at
        ? serviceStatus.IN_PREPARATION
        : serviceStatus.SUSPENDED;
    }

    // 未設定
    return serviceStatus.NOT_IN_SERVICE;
  },
  beforeUsageStarted: ({ hall }) => {
    const { data_created_at, usage_started_at } = hall;
    return data_created_at === null && usage_started_at === null;
  },
  serviceMedia:
    ({ hall }) =>
    (serviceType) => {
      const services = hall.services || {};
      const { service_media } = services[serviceType] || {};
      return service_media || {};
    },
  isLinkServiceToMedia: (_, getters) => (serviceType, mediaId) => {
    const serviceMedia = getters.serviceMedia(serviceType);
    const serviceMediaList = Object.keys(serviceMedia).map((mediaId) => Number(mediaId));
    return serviceMediaList.includes(mediaId);
  },
  isLinkFairToZexy: (_, getters) =>
    getters.isLinkServiceToMedia(SERVICE_TYPE.fair, API_CODE.media.zexy),
};

export default getters;
