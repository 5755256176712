import Vue from "vue";
import Vuex from "vuex";

// Store Module
import commonModule from "@/store/common";
import utilityModule from "@/store/utility";
import modalModule from "@/store/modal";

import filterModule from "@/store/image-list/FilterModule";
import setupModule from "@/store/image-list/SetupModule";
import uploadModule from "@/store/image-list/UploadModule";
import statusChecker from "@/store/modules/StatusChecker";

import actions from "@/store/image-list/actions";
import getters from "@/store/image-list/getters";
import mutations from "@/store/image-list/mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common: commonModule,
    filter: filterModule,
    utility: utilityModule,
    modal: modalModule,
    setup: setupModule,
    upload: uploadModule,
    statusChecker,
  },
  state: {
    count: {},
    data: {},
    errorText: "",
    filterTags: [],
    format: {},
    master: {},
    medias: {},
    newTags: [],
    checkedWidth: 1080,
    urlType: "",
    urlTypeMaster: { "1:1": "1:1", "4:3": "4:3" },
  },
  actions: actions,
  getters: getters,
  mutations: mutations,
});
