import { httpClient } from "@/lib/httpClient";
import API_CODE from "@/assets/common/ApiCode";
import deepCopy from "@/lib/deepCopy";
import * as commonTypes from "@/store/common/mutationTypes";

const actions = {
  /**
   * 画像削除
   * @param {Array} ids 画像IDリスト
   */
  async deleteImages({ commit, dispatch }, ids) {
    commit("modal/setUpdateModal", { isShow: true });
    let success = [];
    for (let id of ids) {
      let result = await dispatch("deleteImageApi", id);
      if (result) success.push(id);
    }
    if (ids.length === success.length) {
      commit("DELETE_IMAGES", ids);
      dispatch("filter/initialize"); // フィルターの初期化 表示画像リスト filteredData を更新
      commit("modal/setDeleteText");
      dispatch("modal/showCompleteModal");
      dispatch("utility/jobs/getJobsData"); // 待機中ジョブの取得・表示
    } else {
      dispatch("showErrorModal", "delete");
    }
    commit("modal/setUpdateModal", { isShow: false });
  },
  /**
   * 画像削除API実行(delete)
   * @param {Number} id 画像ID
   */
  async deleteImageApi({ dispatch }, id) {
    let url = "/images/" + id;
    const response = await httpClient.delete(url).catch((error) => console.log(error));
    await dispatch("getResponseData", response);
    return response && response.status === API_CODE.response.deleted;
  },
  /**
   * state の初期化
   * @param {Boolean} isShowModal モーダルを表示するか
   */
  async getInitialData({ commit, dispatch, state }, isShowModal = true) {
    if (isShowModal && !state.common.loading) {
      commit("modal/setUpdateModal", { isShow: true });
    }
    await dispatch("common/checkPagePermission");
    let resultImage = await dispatch("getImageListApi");
    let resultMedia = await dispatch("getMediaListApi");
    commit("modal/setUpdateModal", { isShow: false });
    if (resultImage && resultMedia) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
      dispatch("utility/jobs/getJobsData"); // 待機中ジョブの取得・表示
    } else if (isShowModal) {
      dispatch("showErrorModal", "get");
    }
    dispatch("filter/initialize"); // フィルターの初期化
    return resultImage && resultMedia;
  },
  /**
   * 画像リスト取得API実行(get)
   */
  async getImageListApi({ commit, dispatch }) {
    const url = "/images";
    const response = await httpClient.get(url);
    const data = await dispatch("getResponseData", response);
    if (data) {
      if (data.master) {
        commit("UPDATE_MASTER", data.master);
        if (data.master.format) commit("UPDATE_FORMAT", data.master.format);
      }
      if (data.count) {
        commit("UPDATE_COUNT", data.count);
      }
      if (data.data) {
        commit("UPDATE_IMAGES", await dispatch("setAdditionalData", data.data)); // 初期データに追加
      }
    }
    return response && response.status === API_CODE.response.success;
  },
  /**
   * 媒体リスト取得API実行(get)
   */
  async getMediaListApi({ commit, dispatch }) {
    const url = "/media/accounts";
    const response = await httpClient.get(url);
    const data = await dispatch("getResponseData", response);
    if (data && data.master) commit("UPDATE_MEDIAS", data.master);
    return response && response.status === API_CODE.response.success;
  },
  /**
   * 画像更新API実行(patch)
   * @param {Object} payload { id: 画像ID, param: { 要素名: 値 } })
   */
  async patchImageApi({ commit }, payload) {
    const { id, param } = payload;
    let url = "/images/" + id;
    const response = await httpClient.patch(url, param).catch((error) => console.log(error));
    if (response && response.data && param.keyName == "license_status") {
      commit("UPDATE_IMAGES_ITEM", {
        ids: [id],
        keyName: "license_status",
        value: response.data.license_status,
      });
    }
    return response && response.status === API_CODE.response.success;
  },
  /**
   * 画像マスタータグ新規登録(post)
   */
  async postImageTagApi({ commit, dispatch, state }) {
    let url = "/tags/1";
    const param = { names: state.newTags };
    const response = await httpClient.post(url, param).catch((error) => console.log(error));
    const result = response && response.status === API_CODE.response.created;
    const data = await dispatch("getResponseData", response);
    if (result && data && Array.isArray(data)) {
      commit("UPDATE_NEW_TAGS", []);
      commit("UPDATE_MASTER_TAG", { isAdd: true, tags: data });
      return data;
    } else {
      return [];
    }
  },
  /**
   * 画像の要素を一括更新
   * @param {Object} payload { ids: 画像IDリスト, keyName: 要素名, value: 値 }
   */
  async setImagesItem({ commit, dispatch }, payload) {
    const { ids, keyName, value } = payload;
    commit("modal/setUpdateModal", { isShow: true });
    let param = { [keyName]: value };
    let success = [];
    for (let id of ids) {
      let result = await dispatch("patchImageApi", { id, param });
      if (result) success.push(id);
    }
    if (ids.length === success.length) {
      commit("UPDATE_IMAGES_ITEM", payload);
      commit("modal/setUpdateText");
      dispatch("modal/showCompleteModal");
      dispatch("resetSelectedIds");
      dispatch("utility/jobs/getJobsData"); // 待機中ジョブの取得・表示
    } else {
      dispatch("showErrorModal", "save");
    }
    commit("modal/setUpdateModal", { isShow: false });
  },
  /**
   * 画像のタグを一括更新
   * @param {Object} payload { ids: 更新する画像ID, value: タグIDリスト}
   */
  async setImagesTag({ commit, dispatch, state }, payload) {
    const { ids, value } = payload;
    commit("modal/setUpdateModal", { isShow: true });
    if (state.newTags.length >= 1) {
      const newTags = await dispatch("postImageTagApi"); // マスタータグに新規追加
      if (newTags.length >= 1) {
        newTags.forEach((d) => {
          value.push(d.id);
        });
      }
    }
    const param = { tags: value };
    let success = [];
    for (let id of ids) {
      let result = await dispatch("patchImageApi", { id, param }); // tags更新
      if (result) success.push(id);
    }
    if (ids.length === success.length) {
      commit("UPDATE_IMAGES_ITEM", { ids, keyName: "tags", value });
      commit("modal/setUpdateText");
      dispatch("modal/showCompleteModal");
      dispatch("resetSelectedIds");
    } else {
      dispatch("showErrorModal", "save");
    }
    commit("modal/setUpdateModal", { isShow: false });
  },
  /**
   * 新規登録するタグ名 state.newTags を更新
   * @param {Array} payload タグ名リスト
   */
  setNewTags({ commit }, payload) {
    commit("UPDATE_NEW_TAGS", payload);
  },
  /**
   * 初期データに追加（版権期限、使用中リスト）
   * @param {Object} data 初期データ
   */
  async setAdditionalData({ state }, data) {
    Object.values(data).forEach((item) => {
      // 使用数
      const used_data = [];
      Object.keys(item.used_at).forEach((usedAtKey) => {
        const contents = deepCopy(item.used_at[usedAtKey]);
        if (contents.length === 0) return;
        Object.values(contents).forEach((content) => {
          Object.assign(content, { type: usedAtKey });
          if (usedAtKey === "fair_contents") {
            Object.assign(content, { detail: "コンテンツ" });
          }
          if (usedAtKey === "report_plus") {
            Object.assign(content, { detail: "レポートプラス" });
          }
          if (usedAtKey === "fair_recommended_point") {
            Object.assign(content, { detail: "おすすめポイント(コレモネっと)" });
          }
          if (usedAtKey === "fair_optional") {
            Object.assign(content, { detail: "複数画像" });
          }
          used_data.push(content);
        });
      });
      Object.assign(item, { used_data });
    });
    return data;
  },
  /**
   * 媒体アルバム連携を一括更新
   * @param {Object} payload payload { ids: 更新する画像ID, mediaId: 対象の媒体ID }
   */
  async uploadMedia({ commit, dispatch, state }, payload) {
    commit("modal/setUpdateModal", { isShow: true });
    const { ids, mediaId } = payload;
    const selectedData = state.data
      .filter((v) => ids.indexOf(v.id) !== -1)
      .map((v) => {
        if (v.media.indexOf(mediaId) === -1) v.media.push(mediaId);
        return v;
      });
    const succeedIds = [];
    for (let v of selectedData) {
      const param = { media: v.media };
      const result = await dispatch("patchImageApi", { id: v.id, param });
      if (result) succeedIds.push(v.id);
    }
    commit("modal/setUpdateModal", { isShow: false });
    if (selectedData.length === succeedIds.length) {
      commit("UPDATE_IMAGES_MEDIA", payload);
      commit("modal/setCompleteText", "更新しました");
      dispatch("modal/showCompleteModal");
      dispatch("utility/jobs/getJobsData"); // 待機中ジョブの取得・表示
    } else {
      dispatch("showErrorModal", "save");
    }
  },
  /**
   * 画像一括トリミング
   * @param {Object} payload payload { image_ids: 更新する画像ID, crop_types: クロップタイプ }
   */
  async bulkTrimImage({ commit, dispatch }, { crop_types, image_ids }) {
    commit("modal/setUpdateModal", { isShow: true });

    const maxImageCount = 10;
    const apiCallCount = Math.ceil(image_ids.length / maxImageCount);

    const url = "/images/bulk-trim";
    const promises = Array.from({ length: apiCallCount }, (_, i) => {
      const sliceBegin = maxImageCount * i;
      const sliceEnd = sliceBegin + maxImageCount;
      return httpClient.put(url, {
        crop_types,
        image_ids: image_ids.slice(sliceBegin, sliceEnd),
      });
    });

    const responses = await Promise.all(promises);
    const isSuccess = responses.every(
      (response) => response && response.status === API_CODE.response.success
    );
    commit("modal/setUpdateModal", { isShow: false });

    if (isSuccess) {
      const images = responses.map(({ data }) => data.images).flat();
      images.forEach((image) => {
        commit("UPDATE_IMAGES_ITEM", {
          ids: [image.id],
          keyName: "urls",
          value: image.urls,
        });

        const cropTypes = {
          zexy: "1",
          mynavi: "2",
        };
        if (crop_types.includes(cropTypes.zexy)) {
          commit("UPDATE_IMAGES_ITEM", {
            ids: [image.id],
            keyName: "trimmed_zexy",
            value: true,
          });
        }
        if (crop_types.includes(cropTypes.mynavi)) {
          commit("UPDATE_IMAGES_ITEM", {
            ids: [image.id],
            keyName: "trimmed_mynavi",
            value: true,
          });
        }
      });
      dispatch("resetSelectedIds");
      commit("modal/setCompleteText", "更新しました");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("showErrorModal", "save");
    }
  },
  /**
   * 画像一括差し替え
   * @param {Object} payload
   * payload { from_image_id: 差し替え前画像ID, to_image_id: 差し替え後画像ID }
   */
  async replaceImage({ commit, dispatch }, payload) {
    commit("modal/setUpdateModal", { isShow: true });

    const url = "/images/replace";
    const response = await httpClient.put(url, payload);
    const isSuccess = response && response.status === API_CODE.response.success;
    commit("modal/setUpdateModal", { isShow: false });

    if (isSuccess) {
      Object.values(payload).forEach(async (image) => {
        const url = `/images/${image}`;
        const response = await httpClient.get(url, { image });
        const isSuccess = response && response.status === API_CODE.response.success;
        if (isSuccess) {
          let data = await dispatch("setAdditionalData", [response.data.data]);
          data = data[0];
          commit("UPDATE_IMAGES_ITEM", {
            ids: [data.id],
            keyName: "used_data",
            value: data.used_data,
          });
        }
      });
      commit("modal/setCompleteText", "更新しました");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("showErrorModal", "save");
    }

    return isSuccess;
  },

  /*
   * 共通
   ***********************************/
  /**
   * API の response.data を取得する
   * @param {Object} response レスポンス
   */
  getResponseData({}, response) {
    let r = response;
    if (r) {
      if (!r.data && r.response) {
        r = r.response; // catch されないエラーの場合
      }
      if (r.data) {
        return r.data;
      }
    }
    return null;
  },
  /**
   * エラーモーダルのメッセージを変更する
   * @param {String} text メッセージ
   */
  setErrorText({ commit }, text) {
    commit("UPDATE_ERROR_TEXT", text);
  },
  /**
   * エラーモーダルを表示する
   * @param {String} type セット済みのメッセージが無い場合に type 別デフォルトメッセージを表示
   */
  showErrorModal({ commit, state }, type) {
    commit("common/" + commonTypes.ERROR);
    const errorModal = {
      isShow: true,
      text: state.errorText || state.setup.errorTexts[type],
    };
    commit("modal/setErrorModal", errorModal);
  },
  /**
   * ページ移動
   * @param {Object} { path: 移動先, isShowTab: 別タブ }
   */
  movePage({}, payload) {
    const { path, isShowTab } = payload;
    if (isShowTab) {
      window.open(path, "_blank");
    } else {
      window.location.href = path;
    }
  },
  /**
   * 表示画像の url の種類を変更
   * @param {String} type url の種類
   */
  setUrlType({ commit }, type) {
    commit("UPDATE_URL_TYPE", type);
  },
  /**
   * 選択している ID をリセット
   */
  resetSelectedIds({ dispatch }) {
    setTimeout(() => {
      dispatch("filter/updateCheckIdAll", false);
    }, 2500);
  },
};
export default actions;
