<template>
  <div class="logIcon" :class="classType">
    <icon-sync v-if="iconType == 'autorenew'" />
    <i v-else-if="iconType" class="material-icons">{{ iconType }}</i>
    <p v-if="textType" class="text">{{ textType }}</p>
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";
import IconSync from "@/components/atoms/common/IconSync.vue";

export default {
  components: {
    IconSync,
  },
  props: {
    icon: String,
    text: String,
    status: Number,
    master: {
      type: Object,
      default: () => {},
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isShowShort: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      icons: {
        // status
        [API_CODE.linkStatus.inSync]: "autorenew",
        [API_CODE.linkStatus.public]: "check_circle",
        [API_CODE.linkStatus.locked]: "locked",
        [API_CODE.linkStatus.waiting]: "pause_circle_filled",
        [API_CODE.linkStatus.notice]: "notification_important",
        [API_CODE.linkStatus.error]: "error",
        [API_CODE.linkStatus.fatalError]: "error",
      },
      shortTexts: {
        [API_CODE.linkStatus.error]: "エラー",
        [API_CODE.linkStatus.fatalError]: "エラー",
      },
    };
  },
  computed: {
    classType: function() {
      const c = [];
      Object.keys(this.statusType).forEach((k) => {
        if (this.statusType[k]) c.push(k);
      });
      if (this.isHorizontal) c.push("isHorizontal");
      if (this.isSmall) c.push("isSmall");
      return c;
    },
    iconType: function() {
      if (this.icon) return this.icon;
      if (this.status) return this.icons[this.status];
    },
    textType: function() {
      if (this.text) return this.text;
      if (this.status) {
        const isShort = this.isShowShort && this.shortTexts[this.status];
        if (isShort) return this.shortTexts[this.status];
        if (this.master) return this.master[this.status];
      }
    },
    statusType: function() {
      return {
        inSync: this.status == API_CODE.linkStatus.inSync,
        isPublic: this.status == API_CODE.linkStatus.public,
        isLocked: this.status == API_CODE.linkStatus.locked,
        isWaiting: this.status == API_CODE.linkStatus.waiting,
        isNotice: this.status == API_CODE.linkStatus.notice,
        isError: this.status >= API_CODE.linkStatus.error, // 90 以上
      };
    },
  },
};
</script>

<style scoped lang="scss">
.logIcon {
  & {
    align-items: center;
    color: $COLOR_KEY;
    display: flex;
    flex-direction: column;
    height: $GRID * 3;
    justify-content: center;
    position: relative;
    text-align: center;
  }
  .material-icons {
    font-size: 24px;
  }
  .text {
    font-size: 10px;
    line-height: 1.3;
    position: relative;
  }
  &.isHorizontal {
    flex-direction: row;
    justify-content: flex-start;
    .text {
      margin-left: 2px;
    }
  }
  &.isSmall {
    .material-icons {
      font-size: 16px;
    }
    &.isHorizontal {
      .text {
        font-size: 12px;
      }
    }
  }
  &.inSync,
  &.isWaiting {
    color: $COLOR_SUB_TEXT;
  }
  &.isPublic {
    color: $COLOR_SUBMIT;
  }
  &.isNotice {
    color: $COLOR_WARNING;
  }
  &.isError {
    color: $COLOR_ALERT;
  }
}
</style>
