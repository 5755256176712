<template>
  <div class="select-box" :class="modifierClass">
    <select
      class="select-box__input"
      :key="value"
      :value="value"
      :disabled="disabled"
      @change="onChange"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option[valueKey]"
        :disabled="option.disabled"
        :style="{ 'font-family': fontStyle[option[valueKey]] || null }"
      >
        {{ option[labelKey] }}
      </option>
    </select>
    <i v-if="loading" class="material-icons icon-sync">sync</i>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    options: {
      type: Array,
      default: () => [],
    },
    valueKey: {
      type: String,
      default: "value",
    },
    labelKey: {
      type: String,
      default: "label",
    },
    fontStyle: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modifierClass: function() {
      return {
        "select-box--disabled": this.disabled,
        "select-box--has-error": this.hasError,
      };
    },
  },
  methods: {
    onChange: function(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.select-box {
  display: inline-block;
  position: relative;

  &--disabled {
    cursor: default;
    opacity: 0.3;
    pointer-events: none;
  }

  &--has-error {
    & .select-box__input {
      background-color: $BG_COLOR_ALERT;
    }
  }

  &:after {
    border-style: solid;
    border-color: #bc9e74 transparent transparent transparent;
    border-width: 6px 4px 0 4px;
    content: "";
    display: block;
    margin-top: -3px;
    position: absolute;
    right: 8px;
    top: 50%;
  }
}

.select-box__input {
  @include commonInputField();
  cursor: pointer;
  font-size: 1em;
  min-width: 2rem;
  overflow: hidden;
  padding: 3px $GRID 3px 8px;
  text-overflow: ellipsis;
  width: 100%;
  option {
    background-color: #fff;
    padding: $SUB_GRID;
    &:disabled {
      color: #bbb;
    }
  }
}

.icon-sync {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  font-size: 20px;
  position: absolute;
  right: -30px;
  top: 10%;

  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
