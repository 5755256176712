<template>
  <div>
    <modal-image-select
      v-show="isOpen"
      :selected="selected"
      :restrict-media-ids="restrictMediaIds"
      :restrict-types="restrictTypes"
      :image-ids="imageIds"
      :need-all-urls="needAllUrls"
      :request-image-data="true"
      @close="finishSelect"
      @input="setImageId"
      @init="setOriginalImages"
    />
  </div>
</template>

<script>
/**
 * 画像選択用モーダルの設置と、画像選択機能用モジュールとの繋ぎ込み用コンポーネント
 * - ページのどこか1カ所に設置すればOK
 * - 画像選択用モジュールの名前を`imageSelect`にするか、propsを通してmoduleNameに任意のモジュール名を指定すること
 */

import ModalImageSelect from "@/components/organisms/common/ModalImageSelect.vue";

// Store
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    ModalImageSelect,
  },
  props: {
    moduleName: {
      type: String,
      default: "imageSelect",
    },
  },
  data() {
    return {
      //isOpen: false
    };
  },
  computed: {
    ...mapState({
      isOpen(state) {
        return state[this.moduleName].isOpen;
      },
      selected(state) {
        return state[this.moduleName].current.selected;
      },
      restrictMediaIds(state) {
        return state[this.moduleName].current.mediaIds;
      },
      restrictTypes(state) {
        return state[this.moduleName].current.types;
      },
      imageIds(state) {
        return state[this.moduleName].ids;
      },
      needAllUrls(state) {
        return state[this.moduleName].current.needAllUrls;
      },
    }),
  },
  methods: {
    ...mapActions({
      closeModal(dispatch) {
        dispatch(this.moduleName + "/closeModal");
      },
      setImageId(dispatch, data) {
        dispatch(this.moduleName + "/setImageId", data);
      },
      setOriginalImages(dispatch, data) {
        dispatch(this.moduleName + "/setOriginalImages", data.images);
        dispatch(this.moduleName + "/setFunctionStates", data.functions);
      },
    }),
    finishSelect() {
      this.closeModal();
    },
  },
};
</script>

<style scoped></style>
