<template>
  <nav class="jobStatus">
    <p v-if="isLoading" class="jobStatus_load isLoading">
      <icon-sync class="jobStatus_statusIcon" />
      <span class="jobStatus_statusText">反映状態を取得中…</span>
    </p>
    <div v-else class="jobStatus_wrap">
      <div class="jobStatus_wrap">
        <p class="jobStatus_status" :class="{ isMediaSync }">
          <span class="jobStatus_statusText">{{ statusText }}</span>
        </p>
        <p class="jobStatus_jobs">
          媒体連携 完了待ちの処理
          <span class="jobStatus_jobsNum fontNum"><a href="/job">{{ jobsNum }}</a></span
          >件
        </p>
      </div>
      <action-button
        v-if="canPauseToSync"
        class="jobStatus_btn"
        :text="btnText"
        :is-alert="isMediaSync"
        :is-save="!isMediaSync"
        :is-disabled="isDisabled"
        @clickHandler="reflect"
      />
    </div>
  </nav>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import IconSync from "@/components/atoms/common/IconSync.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ActionButton,
    IconSync,
  },
  computed: {
    ...mapGetters("utility/jobs", [
      "isLoading",
      "isMediaSync",
      "isDisabled",
      "jobsNum",
      "canPauseToSync",
    ]),
    statusText: function () {
      return this.isMediaSync ? "反映中" : "反映 停止中";
    },
    btnText: function () {
      return this.isMediaSync ? "反映を停止する" : "反映を開始する";
    },
  },
  methods: {
    ...mapActions("utility/jobs", ["changeReflect"]),
    ...mapActions({ showConfirm: "utility/jobs/modal/showConfirmModal" }),
    reflect: function () {
      if (this.isMediaSync) {
        const payload = {
          text: "反映を停止すると媒体にデータが連携されません。\r\n停止しますか？",
          status: 2,
          actionName: "utility/jobs/changeReflect",
          actionBtnText: "停止する",
        };
        this.showConfirm(payload);
      } else {
        this.changeReflect();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.jobStatus {
  // @include gradientMain();
  background-color: $BG_COLOR_NAV;
  border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
  box-shadow: $DEPTH_LV_0;
  height: $GRID * 2.25;
  padding: $SUB_GRID;
  &_wrap,
  &_load {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
  &_load {
    color: $COLOR_KEY;
    justify-content: center;
  }
  &_status {
    align-items: center;
    background: $BG_COLOR_SUB;
    border: 1px solid #c7c8cd;
    border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
    box-shadow: $DEPTH_LV_0_INSET;
    color: $COLOR_ALERT;
    display: flex;
    height: 100%;
    justify-content: center;
    width: $GRID * 7;
    &.isMediaSync {
      background: $BG_COLOR_MEDIA_SETTING;
      border-color: darken($BG_COLOR_MEDIA_SETTING, 8%);
      color: $COLOR_KEY;
    }
    &Icon {
      font-size: 18px;
      .isLoading & {
        font-size: 22px;
      }
    }
    &Text {
      font-size: 11px;
      padding: 0 $SUB_GRID * 2 0 $SUB_GRID;
      .isLoading & {
        font-size: 13px;
        font-weight: bold;
        padding: 0 0 0 $GRID * 0.5;
      }
    }
  }
  &_jobs {
    color: $COLOR_TEXT;
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    margin: 0 $SUB_GRID;
    text-decoration: none;
    &::after {
      background: $COLOR_KEY;
      content: " ";
      display: none;
      height: 1px;
      width: 100%;
    }
  }
  &_jobsNum {
    display: inline-block;
    font-size: 13px;
    margin: 0 $SUB_GRID 0 $GRID * 0.5;
  }
  & &_btn {
    font-size: 10px;
    padding: 3px 0;
    position: static;
    transition: opacity 0.2s;
    width: $GRID * 6.5;
    ::v-deep &.btn {
      position: static;
    }
    ::v-deep .btnText {
      position: static;
    }
    &:hover {
      opacity: 0.75;
    }
    &::before {
      content: none;
    }
  }
}
</style>
