import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_TYPE from "@/assets/common/benefit-type";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";
import { NgWordType } from "@/assets/common/NgWordType";

import deepCopy from "@/lib/deepCopy";
import typeToString from "@/lib/typeToString";

import {
  deleteMediaValue,
  deleteUnneededProperties,
  deleteHiddenInputValues,
  fillBenefitType,
  selectValidperiod,
} from "@/store/benefit/helper";

const getters = {
  /******************************
   * 編集する対象の情報を取得する
   ******************************/
  selectedData: ({ selectedData }) => selectedData,
  editingId: ({ selectedData }) => selectedData.id,
  mediaIds: ({ selectedData }) => selectedData.media_ids,
  groupType: ({ selectedData }) => selectedData.group_type,
  benefitTypes: ({ selectedData }) => selectedData.type,
  benefitType:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.type[mediaId],
  titles: ({ selectedData }) => selectedData.title,
  title:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.title[mediaId],
  descriptions: ({ selectedData }) => selectedData.description,
  description:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.description[mediaId],
  limitedToFair:
    ({ selectedData }) =>
    (mediaId = API_CODE.media.wp) =>
      selectedData.is_limited_to_fair[mediaId],
  limitedToWebtsuku:
    ({ selectedData }) =>
    (mediaId = API_CODE.media.wp) =>
      selectedData.is_limited_to_webtsuku[mediaId],
  periodFroms: ({ selectedData }) => selectedData.valid_from,
  periodFrom:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.valid_from[mediaId],
  periodUntils: ({ selectedData }) => selectedData.valid_until,
  periodUntil:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.valid_until[mediaId],
  periodTexts: ({ selectedData }) => selectedData.valid_period_text,
  periodText:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.valid_period_text[mediaId],
  imageIds: ({ selectedData }) => selectedData.image_id,
  imageId:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.image_id[mediaId],
  mediaOnly:
    ({ selectedData }) =>
    (mediaId = API_CODE.media.wp) =>
      selectedData.is_media_only[mediaId],
  recommended:
    ({ selectedData }) =>
    (mediaId = API_CODE.media.wp) =>
      selectedData.recommended[mediaId],
  usedCount: ({ selectedData }) => selectedData.used_count,
  usedFairs: ({ selectedData }) => selectedData.used_fairs,
  licenseStatuses: ({ selectedData }) => selectedData.license_status,
  licenseStatus:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.license_status[mediaId],
  linkStatuses: ({ selectedData }) => selectedData.link_statuses,
  publishStatus: ({ selectedData }) => selectedData.status,
  updatedAt: ({ selectedData }) => selectedData.updated_at,
  // フロントエンドだけで管理するstate
  targetId: ({ targetId }) => targetId,
  periodTypes: ({ periodTypes }) => periodTypes,
  periodType:
    ({ periodTypes }) =>
    (mediaId) =>
      periodTypes[mediaId],
  applicationFroms: ({ selectedData }) => selectedData.application_from,
  applicationFrom:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.application_from[mediaId],
  applicationUntils: ({ selectedData }) => selectedData.application_until,
  applicationUntil:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.application_until[mediaId],
  publicationFroms: ({ selectedData }) => selectedData.publication_from,
  publicationFrom:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.publication_from[mediaId],
  publicationUntils: ({ selectedData }) => selectedData.publication_until,
  publicationUntil:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.publication_until[mediaId],
  isHighlighteds: ({ selectedData }) => selectedData.is_highlighted,
  isHighlighted:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.is_highlighted[mediaId],
  detailTypes: ({ selectedData }) => selectedData.detail_type,
  detailType:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.detail_type[mediaId],
  discountTypes: ({ selectedData }) => selectedData.discount_type,
  discountType:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.discount_type[mediaId],
  notesList: ({ selectedData }) => selectedData.notes,
  notes:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.notes[mediaId],
  priceNotesList: ({ selectedData }) => selectedData.price_notes,
  priceNotes:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.price_notes[mediaId],
  linkTitles: ({ selectedData }) => selectedData.link_title,
  linkTitle:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.link_title[mediaId],
  linkUrls: ({ selectedData }) => selectedData.link_url,
  linkUrl:
    ({ selectedData }) =>
    (mediaId) =>
      selectedData.link_url[mediaId],
  isByMedia: ({ isByMedia }) => isByMedia,
  isByMedium: (_, getters) => (formName) => {
    const getterNames = {
      [BENEFIT_GROUP_TYPE.fair]: "fairBenefitIsByMedia",
      [BENEFIT_GROUP_TYPE.wp]: "wpBenefitIsByMedia",
      [BENEFIT_GROUP_TYPE.hall]: "hallBenefitIsByMedia",
    };
    const getterName = getterNames[getters.groupType];
    return getters[getterName](formName);
  },
  fairBenefitIsByMedia:
    ({ isByMedia }) =>
    (formName) => {
      if (formName !== "valid_from") return isByMedia[formName];

      // ゼクシィ・みんな・マイナビ用と式場特典で適用期間の設定を取得する場合
      return isByMedia.valid_from || isByMedia.valid_until || isByMedia.valid_period_text;
    },
  wpBenefitIsByMedia:
    ({ isByMedia }) =>
    (formName) =>
      isByMedia[formName],
  hallBenefitIsByMedia:
    ({ isByMedia }) =>
    (formName) => {
      const alwaysTrueItems = [
        "is_highlighted",
        "application_from",
        "detail_type",
        "discount_type",
        "price_notes",
        "link_title",
        "link_url",
      ];
      return alwaysTrueItems.includes(formName) ? true : isByMedia[formName];
    },
  sort: ({ selectedData }) => selectedData.sort,
  publishedBenefits: ({ selectedData }) => selectedData.published_at,
  scheduledBenefits: ({ selectedData }) => selectedData.scheduled_at,
  /******************************
   * 編集画面の初期設定
   ******************************/
  // 初期データを取得する
  template:
    (_, getters) =>
    (groupType = 1) => {
      const mediaIds = getters.mediaIdsByGroupType(groupType);
      const initialValue = (value = null) => {
        return mediaIds.reduce(
          (initialValue, mediaId) => ({
            ...initialValue,
            [mediaId]: value,
          }),
          { [API_CODE.mediaCommon]: value }
        );
      };

      return {
        media_ids: [],
        group_type: groupType,
        type: initialValue(),
        title: initialValue(),
        description: initialValue(),
        is_limited_to_fair: initialValue(),
        is_limited_to_webtsuku: initialValue(),
        valid_from: initialValue(),
        valid_until: initialValue(),
        valid_period_text: initialValue(),
        application_from: initialValue(),
        application_until: initialValue(),
        publication_from: initialValue(),
        publication_until: initialValue(),
        is_highlighted: initialValue(),
        detail_type: initialValue(),
        discount_type: initialValue(),
        notes: initialValue(),
        price_notes: initialValue(),
        link_title: initialValue(),
        link_url: initialValue(),
        image_id: initialValue(),
        is_media_only: initialValue(),
        recommended: initialValue(),
        license_status: initialValue(),
        link_statuses: initialValue(0),
        status: API_CODE.status.public,
        sort: initialValue(0),
        published_at: [],
        scheduled_at: [],
      };
    },
  // 選択した特典のデータとテンプレートを結合する
  mergeSelectedDataAndTemplate: () => (template, originalData) => {
    const mergeData = Object.entries(template).reduce(
      (selectedData, [key, initialValue]) => {
        const valueType = typeToString(initialValue);
        const isObject = valueType === "Object";
        const value = originalData[key];
        const mergedValue = isObject ? { ...initialValue, ...value } : value;

        return {
          ...selectedData,
          [key]: mergedValue,
        };
      },
      { id: originalData.id }
    );
    return mergeData;
  },
  // 媒体個別の初期状態を取得する
  initialByMedia:
    ({ selectedData }, getters) =>
    (key) => {
      const groupType = getters.groupType;
      const isByMedia = (key) => {
        const item = selectedData[key];
        const mediaIds = getters.mediaIdsByGroupType(groupType);
        const commonIsNull = item[API_CODE.mediaCommon] === null;
        const mediaHasValue = mediaIds.some(
          (mediaId) => item[mediaId] !== null && item[mediaId] !== undefined
        );
        return commonIsNull && mediaHasValue;
      };

      // ゼクシィ・みんな・マイナビ用の適用期間については、開始日と終了日の両方をチェックする
      const checkOtherPeriod = ["valid_from", "valid_until"];
      const needCheck = checkOtherPeriod.includes(key);
      if (groupType === BENEFIT_GROUP_TYPE.fair && needCheck) {
        return checkOtherPeriod.some((periodKey) => isByMedia(periodKey));
      }
      return isByMedia(key);
    },
  /******************************
   * マスタから情報を取得する
   ******************************/
  detailTypeList: ({ master }) => master.detail_type,
  discountTypeList: ({ master }) => master.discount_type,

  /******************************
   * その他
   ******************************/
  // 項目を表示するかどうかを取得する
  showInput: (_, getters) => (key) => {
    return getters.selectedOnlyMedia(key).length > 0;
  },
  hasMedia: ({ selectedData }) => {
    return selectedData.media_ids.length > 0;
  },
  // 特定の媒体向けの項目の「※～～用」という注釈を取得する
  onlyMediaName: (_, getters) => (key) => {
    const onlyMedia = getters["rules/onlyMedia"](key) || [];
    const parseText = (targetMedia) => {
      return targetMedia.map((media) => getters.mediaShortName(media)).join("、");
    };
    return onlyMedia.length > 0 ? `※${parseText(onlyMedia)}用` : "";
  },
  // 以下の条件に従って媒体個別で表示する媒体を取得する
  // 1. 項目を表示できる媒体であること
  // 2. 掲載媒体で選択されていること
  selectedOnlyMedia:
    ({ selectedData }, getters) =>
    (key) => {
      const onlyMedia = getters["rules/onlyMedia"](key);
      const selectedMedia = deepCopy(selectedData.media_ids);
      if (!onlyMedia) return selectedMedia.sort((a, b) => a - b);

      return onlyMedia.filter((media) => selectedMedia.indexOf(media) !== -1).sort((a, b) => a - b);
    },
  // 保存用APIに渡すためのパラメータを取得する
  benefitParamsForSave: ({ selectedData, isByMedia }, getters) => {
    let params = deepCopy(selectedData);
    params = deleteMediaValue(params, isByMedia);
    params = deleteUnneededProperties(params);
    params = {
      ...params,
      ...deleteHiddenInputValues(params, getters.showInput),
    };

    const groupType = getters.groupType;
    if (groupType === BENEFIT_GROUP_TYPE.fair || groupType === BENEFIT_GROUP_TYPE.hall) {
      params = selectValidperiod(params, getters.periodTypes);
    }

    if (groupType === BENEFIT_GROUP_TYPE.wp || groupType === BENEFIT_GROUP_TYPE.hall) {
      params = fillBenefitType(params, getters.mediaIds);
    }

    const mediaIdsByGroupType = getters.mediaIdsByGroupType(params.group_type);
    params.media_ids = params.media_ids.filter((mediaId) => mediaIdsByGroupType.includes(mediaId));

    if (!params.status) params.status = 2;
    return params;
  },
  mediaShortName: (state) => (id) => {
    return id == 0 ? "共通" : state.master.media[id].short_name;
  },
  optionLevel: (_, getters) => (keyNames, mediaId) => {
    const isRequired = keyNames.some((key) => getters["rules/isRequired"](key, mediaId));
    return isRequired ? 2 : 1;
  },
  // 特定の媒体に限定する注釈を表示するかどうかを取得する
  needNote: (_, getters) => (key) => {
    const groupType = getters.groupType;
    const mediaIds = getters.mediaIdsByGroupType(groupType);
    const onlyMedia = getters["rules/onlyMedia"](key);

    return mediaIds.length !== onlyMedia.length;
  },
  // NGワードを取得する
  ngWord: (_, getters) => (keyName) => {
    const types = [NgWordType.COMMON, NgWordType.BENEFIT];
    const mediaIds = getters.selectedOnlyMedia(keyName);

    return getters["ngCheck/targetWord"](mediaIds, types);
  },
  imageByMediaId: (_, getters) => (mediaId) => {
    const imageId = getters.imageId(mediaId);
    if (!imageId) {
      return {};
    }
    return getters.image(imageId);
  },
  // コレモネっと設定の値を取得する
  coremonetGroupType: (_, getters) => getters["hpSetting/benefitGroupType"],
  // コレモネっと設定の値とgroup_typeに従って、媒体のID一覧をフィルタリングする
  filteredMediaIdsByCoremonet: (_, getters) => (ids, groupType) => {
    const coremonetGroupType = getters.coremonetGroupType;
    const needFiltering = coremonetGroupType && coremonetGroupType !== 3;
    if (needFiltering && groupType !== coremonetGroupType) {
      return ids.filter((mediaId) => {
        return mediaId !== API_CODE.media.homepage;
      });
    }

    return ids;
  },
  // 式場特典のリンクタイトル・リンクURLの必須判定を取得する
  requiredMynaviLink: (s, getters) => {
    const linkTitle = getters.linkTitle(API_CODE.media.mynavi);
    const linkUrl = getters.linkUrl(API_CODE.media.mynavi);
    return linkTitle != null || linkUrl != null;
  },
  // 対象の式場特典が媒体連携されているかの判定を取得する
  isLinked: (_, getters) => (mediaId) => {
    const publishedBenefits = getters.publishedBenefits.filter(
      ({ media_id }) => media_id === mediaId
    );
    const scheduledBenefits = getters.scheduledBenefits.filter(
      ({ media_id }) => media_id === mediaId
    );

    return publishedBenefits.length > 0 || scheduledBenefits.length > 0;
  },
  // 対象の式場特典が媒体に掲載されているかの判定を取得する
  isPublished: (_, getters) => (mediaId) => {
    const publishedBenefits = getters.publishedBenefits.filter(
      ({ media_id }) => media_id === mediaId
    );

    return publishedBenefits.length > 0;
  },

  isContract: (s, getters) => {
    return getters.benefitType(API_CODE.mediaCommon) === BENEFIT_TYPE.contract;
  },

  usedFairs: ({ usedFairs }) => usedFairs,
};

export default getters;
