<template>
  <div :data-id="image.id">
    <slot :select-image="selectImage"></slot>
  </div>
</template>

<script>
/**
 * 画像選択用モーダルの起動から選択した画像詳細を返すところまで、一括で行うコンポーネント
 * - 以下の2つのファイルが必須
 *   - store内に画像選択用モジュール（`store/modules/ImageSelect.js`）
 *   - ページのどこか1カ所にモーダル設置とモジュール連動用コンポーネント（`organisms/common/ImageSelectWrapper.vue`）
 * - v-modelで指定した場所に選択した画像の詳細が返される
 *   - @selectで詳細データを受け取ることも可能
 * - 画像詳細データの`suggested_thumb`に、サムネイルに最適と思われる画像urlを格納
 * - v-slotで任意のスロット名を指定して、`{スロット名}.selectImage`をモーダルを起動したいイベントと関連付けること
 * ```
  <image-selector
    v-model="image" // 画像データを受け取る場所
    :selected="selected" // 設定済みの画像IDがあれば渡しておくことができる
    :types="types" // urlsの種類をarrayで指定しておくと、その全ての画像urlが設定されているものだけ選択できるようになる
    v-slot="slotName" // 任意のスロット名
  >
    <button
      type="button"
      @click="imageSelect.selectImage" // `{スロット名}.selectImage`とイベントを関連付けることでモーダルが起動する
    >hoge</buttontype="button">
  </image-selector>
 * ```
 */

import { mapState, mapGetters, mapActions } from "vuex";

let autoNumber = 0; // コンポーネント内で固有のIDを持たせるための通し番号

export default {
  components: {},
  model: {
    event: "select",
  },
  props: {
    event: {
      type: String,
      default: "click",
    },
    moduleName: {
      type: String,
      default: "imageSelect",
    },
    selected: {
      type: Number,
      default: null,
    },
    types: {
      type: Array,
      default: null,
    },
    mediaIds: {
      type: Array,
      default: null,
    },
    needAllUrls: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      keyname: null,
      prefix: "_selector_",
      tmpId: null,
    };
  },
  created() {
    this.getNumbering();
    this.setByNameSet(this.selected);
  },
  watch: {
    selected: {
      immediate: true,
      handler(id) {
        this.setByNameSet(id);
      },
    },
  },
  computed: {
    image() {
      const template = {
        id: null,
        suggested_thumb: null,
      };
      if (this.keyname === null) {
        return template;
      }
      // 媒体IDやURLタイプが指定してある場合に、画像が選択出来ない場合はnullで返るようにする
      let image = this.$store.getters[this.moduleName + "/image"](
        this.keyname,
        this.mediaIds,
        this.types,
        this.needAllUrls
      );
      if (image) {
        image.suggested_thumb = this.$store.getters[this.moduleName + "/thumb"](
          this.keyname
        );
      } else {
        image = template;
      }
      if (this.tmpId !== image.id) {
        this.$emit("select", image);
        this.tmpId = image.id;
      }
      return image;
    },
  },
  methods: {
    ...mapActions({
      startSelect(dispatch, data) {
        dispatch(this.moduleName + "/startSelect", data);
      },
      setByNameSet(dispatch, id) {
        let set = {};
        set[this.keyname] = id;
        dispatch(this.moduleName + "/setByNameSet", set);
      },
    }),
    getNumbering() {
      autoNumber += 1;
      this.keyname = this.prefix + autoNumber;
    },
    selectImage() {
      const params = {
        name: this.keyname,
        selected: this.selected || -1,
        types: this.types,
        mediaIds: this.mediaIds,
        needAllUrls: this.needAllUrls,
      };
      this.startSelect(params);
    },
  },
};
</script>

<style scoped></style>
