const mutations = {
  DELETE_IMAGES(state, ids) {
    state.data = state.data.filter((img) => ids.indexOf(img.id) === -1);
  },
  UPDATE_COUNT(state, payload) {
    state.count = payload;
  },
  UPDATE_ERROR_TEXT(state, payload) {
    state.errorText = payload;
  },
  UPDATE_FORMAT(state, payload) {
    state.format = payload;
  },
  UPDATE_IMAGES(state, payload) {
    state.data = payload;
  },
  UPDATE_IMAGES_ITEM(state, { ids, keyName, value }) {
    ids.forEach((id) => {
      const data = state.data.find((data) => data.id === id);
      if (data) data[keyName] = value;
    });
  },
  UPDATE_IMAGES_MEDIA(state, { ids, mediaId }) {
    state.data = state.data.map((v) => {
      if (ids.indexOf(v.id) === -1) return v;
      if (v.media.indexOf(mediaId) === -1) v.media.push(mediaId);
      return v;
    });
  },
  UPDATE_MASTER(state, payload) {
    state.master = payload;
    state.filterTags = Object.keys(state.master.tags).map((id) => {
      return { id, name: state.master.tags[id] };
    });
  },
  UPDATE_MASTER_TAG(state, { isAdd, tags }) {
    if (isAdd) {
      tags.forEach((d) => {
        state.master.tags[d.id] = d.name;
        state.filterTags.push(d);
      });
    } else {
      tags.forEach((d) => {
        delete state.master.tags[d.id];
        state.filterTags.some((v, i) => {
          if (v.id == d.id) return state.filterTags.splice(i, 1);
        });
      });
    }
  },
  UPDATE_MEDIAS(state, payload) {
    state.medias = payload;
  },
  UPDATE_NEW_TAGS(state, payload) {
    state.newTags = payload;
  },
  /**
   * 表示画像の URL 種類
   */
  UPDATE_URL_TYPE(state, payload) {
    state.urlType = payload;
  },
};
export default mutations;
