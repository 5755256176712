<template>
  <div class="jobUnit_wrap">
    <div class="jobUnit">
      <p class="id fontNum">{{ job.id }}</p>
      <p v-if="isShowParent" class="hall fontNum">{{ job.hall_id }}</p>
      <log-icon
        class="status"
        :status="job.status"
        :master="master.status"
        :is-horizontal="true"
        :is-small="true"
      />
      <log-icon
        class="type"
        :icon="icon"
        :text="job.header"
        :is-horizontal="true"
        :is-small="true"
      />
      <div class="title">
        <span v-if="!job.queueable_title">{{ emptyText }}</span>
        <span v-else-if="isDisabledLink">{{ title }}</span>
        <span
          v-else
          class="title_link"
          @click="
            movePage({
              path: link,
              hallId: isParentAccount ? job.hall_id : null,
            })
          "
          >{{ title }}</span
        >
      </div>
      <ul class="media">
        <li
          class="media_item"
          v-for="(v, i) in master.media"
          :class="mediaClass(v.id)"
          :key="i"
        >
          <span>{{ v.short_name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import { getLogPath, getLogPermissionName } from "@/store/common/helper";

import LogIcon from "@/components/molecules/common/LogIcon.vue";

export default {
  components: {
    LogIcon,
  },
  props: {
    job: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters("common", [
      "isViewableOwnHall",
      "isViewableAnyHall",
      "isLogAuthorized",
    ]),
    ...mapState("job", ["linkStatusCode"]),
    ...mapGetters("job", ["master", "isShowParent"]),
    status: function() {
      let icon = "";
      const type = this.master.status[this.job.status];
      if (this.job.status === this.linkStatusCode.inSync) {
        icon = "more_horiz";
      } else if (this.job.status === this.linkStatusCode.public) {
        icon = "check_circle";
      } else if (this.job.status === this.linkStatusCode.waiting) {
        icon = "pause_circle_filled";
      } else if (this.job.status >= this.linkStatusCode.error) {
        icon = "warning";
      }
      return { icon, type };
    },
    emptyText: function() {
      return this.job.queueable_type === "MediaAccountConnect" ||
        this.job.queueable_type.includes("NewInfo")
        ? ""
        : "（削除されています）";
    },
    isParentAccount: function() {
      return this.isViewableAnyHall || this.isViewableOwnHall;
    },
    link: function() {
      const l = getLogPath(this.job.queueable_type);
      if (
        [
          "PlanOrder",
          "MediaAccountConnect",
          "NewInfo",
          "HallInfoNewInfo",
        ].includes(this.job.queueable_type)
      ) {
        return l;
      }

      return l + this.job.queueable_id;
    },
    icon: function() {
      const type = this.job.queueable_type;
      if (type === "FairRecommended" || type === "FairDaily") {
        return "date_range";
      }

      const permission = getLogPermissionName(this.job.queueable_type);
      if (permission === "fairs") return "flag";
      if (permission === "benefits") return "stars";
      if (permission === "plans") return "cake";
      if (permission === "images") return "photo";
      if (permission === "settings") return "tune";
      if (permission === "mediaAccounts") return "settings_input_component";
      if (permission === "hallInfo") return "campaign";

      return null;
    },
    title: function() {
      if (this.job.queueable_title == undefined) return this.emptyText;
      return this.job.queueable_id
        ? `${this.job.queueable_id}：${this.job.queueable_title}`
        : this.job.queueable_title;
    },
    isDisabledLink: function() {
      return !this.isLogAuthorized(this.job.queueable_type);
    },
  },
  methods: {
    ...mapActions("job", ["movePage"]),
    mediaClass: function(id) {
      if (this.job.media_id) {
        return {
          isActive: this.job.media_id.indexOf(id) !== -1,
        };
      } else if (this.job.media) {
        let media = this.job.media.find((v) => v.media_id === id);
        if (media && media.length >= 1) media = media[0];
        let c = [];
        if (media) {
          if (media.status >= this.linkStatusCode.error) {
            c.push("isError");
          } else if (media.status == this.linkStatusCode.waiting) {
            c.push("isWaiting");
          } else if (media.status) {
            c.push("isActive");
          }
        }
        return c.join(" ");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.jobUnit {
  & {
    align-items: center;
    background: $BG_COLOR_SUB;
    border-radius: $GRID * 0.5;
    color: $COLOR_KEY;
    display: flex;
    font-size: 14px;
    min-height: $GRID * 3;
    width: 100%;
  }
  &_wrap {
    display: flex;
    margin: auto 2px;
    & + & {
      margin-top: 2px;
    }
  }
  > * {
    margin-left: $GRID * 0.5;
  }
  .status {
    flex: 0 0 auto;
    width: $GRID * 7;
  }
  .id {
    color: $COLOR_SUB_TEXT;
    flex: 0 0 auto;
    min-width: $GRID * 3.5;
    &::before {
      content: "#";
    }
  }
  .media {
    display: flex;
    font-size: 11px;
    margin: $SUB_GRID $GRID $SUB_GRID auto;
    flex: 0 0 auto;
    flex-wrap: wrap; // 3 x n で並べて表示
    width: $GRID * 11 + 4px;
    &_item {
      border: 1px solid $COLOR_DISABLE_TEXT; // グレーアウトで表示
      color: $COLOR_DISABLE_TEXT;
      list-style: none;
      text-align: center;
      min-width: $GRID * 3.5;
      margin: 1px;
      display: block;
      &.isActive {
        border: 1px solid $COLOR_KEY;
        color: $COLOR_KEY;
      }
      &.isWaiting {
        border: 1px solid $COLOR_SUB_TEXT;
        color: $COLOR_SUB_TEXT;
      }
      &.isError {
        border: 1px solid $COLOR_ALERT;
        color: $COLOR_ALERT;
      }
    }
  }
  .title {
    font-size: 12px;
    margin: 0 $GRID;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &_link {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .type {
    flex: 0 0 auto;
    width: $GRID * 8.5;
  }
  .hall {
    margin: auto 0;
    min-width: $GRID * 3.5;
  }
}
</style>
