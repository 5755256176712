import API_CODE from "@/assets/common/ApiCode";
import MESSAGE_CONFIG from "@/assets/common/MessageConfig";

import { httpClient } from "@/lib/httpClient";

import * as commonTypes from "@/store/common/mutationTypes";
import * as types from "@/store/benefit/mutationTypes";

const actions = {
  // 初期データを受け取る
  async getInitialData({ state, commit, dispatch, rootState }) {
    await dispatch("common/checkPagePermission");
    dispatch("hpSetting/fetchSetting");
    dispatch("publishing/fetchHallBenefits");
    dispatch("checkUsage");
    await dispatch("fetchSettingApi");
    const apiUrl = "/benefits";
    let success = await dispatch(
      "setInitialData",
      await dispatch("common/getInitialData", { apiUrl })
    );
    // ハッシュタグ管理
    if (rootState.route && rootState.route.hash) {
      const a = {
        hash: rootState.route.hash,
        id: rootState.route.hash.replace("#b-", ""),
      };
      commit(types.SET_HASH, a);
    }
    // クエリ解析
    let inSync;
    if (rootState.route && Object.keys(rootState.route.query).length) {
      // 編集モード
      if ("edit" in rootState.route.query && Number(state.anchor.id)) {
        commit(types.UPDATE_LOAD_EDIT, true);
        const d = await dispatch("common/getInitialData", {
          apiUrl: apiUrl + "/" + state.anchor.id,
        });
        if (d && d.status == API_CODE.response.success) {
          // ID取得で同期中かを確認
          inSync = Object.values(d.data.data.link_statuses).some(
            (status) => status == API_CODE.linkStatus.inSync
          );
          if (!inSync) {
            // 編集項目を初期化
            dispatch("ngCheck/getInitialData");
            dispatch("setSelectedData", d.data.data);
          }
        }
      }
      if (!inSync) {
        // 検索項目に存在する場合はセット
        if ("in_use" in rootState.route.query) {
          commit("filter/inUseQuery", true);
        }
        if ("license" in rootState.route.query) {
          commit("filter/validQuery", rootState.route.query.license);
        }
        if ("group_type" in rootState.route.query) {
          const groupTypes = rootState.route.query.group_type.split(",").map(Number);
          commit("filter/groupTypeQuery", groupTypes);
        }
      }
    }
    // 初期データ取得後
    if (success == true && !inSync) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
      dispatch("ngCheck/getInitialData");
      dispatch("utility/jobs/getJobsData");
    } else if (inSync) {
      dispatch("modal/showErrorModal", {
        text: "媒体とデータを同期中のため編集できません",
        path: "/benefit",
      });
    } else {
      dispatch("modal/showErrorModal", { text: MESSAGE_CONFIG.error });
    }
  },
  /**
   * 式場基本設定のデータを取得する
   */
  async fetchSettingApi({ commit, dispatch }) {
    const { data, status } = await dispatch("common/getInitialData", {
      apiUrl: "/settings",
    });
    if (status === API_CODE.response.success) {
      if (data.data) commit("UPDATE_SETTING", data.data);
    }
  },
  // 初期データを state に反映
  async setInitialData({ commit }, { data }) {
    if (!data) return false;
    commit(types.SET_BENEFIT_DATA, { benefit: data.data });
    commit(types.SET_MASTER_DATA, { master: data.master });
    return true;
  },
  // 編集モードを切り替え
  updateLoadEdit({ commit }, isEdit) {
    commit(types.UPDATE_LOAD_EDIT, isEdit);
  },
  // 式場の利用状況チェック
  checkUsage({ getters, commit }) {
    commit(types.SET_USAGE, getters["common/isUsed"]);
  },
  // 削除 URL作成
  async deleteData({ commit, dispatch }, payload) {
    commit("modal/setUpdateModal", { isShow: true });
    const { id } = payload;
    const u = "benefits";
    await dispatch("deleteBenefitData", {
      url: `${u}/${id}`,
      param: payload,
    });
  },
  // 削除
  async deleteBenefitData({ commit, dispatch }, { url, param }) {
    const response = await httpClient.delete(url).catch((error) => {
      commit("common/" + commonTypes.ERROR);
      const e = {
        isShow: true,
        text: error,
      };
      dispatch("modal/showErrorModal", e);
    });
    if (response && response.status === API_CODE.response.deleted) {
      commit(types.DELETE_BENEFIT, param);
      commit("modal/setDeleteText");
      dispatch("modal/showCompleteModal");
      dispatch("utility/jobs/getJobsData");
    } else {
      const e = {
        isShow: true,
        text: "特典の削除に失敗しました　時間をおいて再度お試しください",
      };
      dispatch("modal/showErrorModal", e);
    }
  },
  // 公開、非公開を設定
  async setPublishStatus({ commit, dispatch, getters }, { id, status }) {
    commit("modal/setUpdateModal", { isShow: true });

    const url = `benefits/${id}/${status === 1 ? "unpublish" : "publish"}`;
    const response = await httpClient.put(url);
    const isSuccess = response && response.status === API_CODE.response.success;

    if (isSuccess) {
      const benefit = getters.benefit(id);
      commit(types.UPDATE_BENEFIT, {
        id,
        data: { ...benefit, status },
      });

      commit("modal/setUpdateText");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("modal/showErrorModal", { text: MESSAGE_CONFIG.error });
    }
  },
  // ウエパの特典の公開・非公開を一括で更新する
  async putWpBenefitsInBulk({ commit, dispatch }, { mediaId, publishedIds, unpublishedIds }) {
    commit("modal/setUpdateModal", { isShow: true }, { root: true });

    const succeedUnpublish = await dispatch("putWpPublishStatusInBulk", {
      publishStatus: API_CODE.status.private,
      benefitIds: unpublishedIds,
    });
    if (!succeedUnpublish) return false;

    const succeedPublish = await dispatch("putWpPublishStatusInBulk", {
      publishStatus: API_CODE.status.public,
      benefitIds: publishedIds,
    });
    if (!succeedPublish) return false;

    const orderPayload = { mediaId, benefitIds: publishedIds };
    const succeedUpdateOrder = await dispatch("bulkUpdateOrder", orderPayload);
    if (!succeedUpdateOrder) return false;

    commit("modal/setCompleteText", "公開が完了しました");
    dispatch("modal/showCompleteModal");
    return true;
  },
  // ウエパの特典の公開ステータスを一括で更新する
  async putWpPublishStatusInBulk({ commit, dispatch, getters }, { publishStatus, benefitIds }) {
    if (benefitIds.length === 0) return true;

    const isPublished = publishStatus === API_CODE.status.public;
    const endpoint = isPublished ? "bulk-publish" : "bulk-unpublish";
    const url = `benefits/${endpoint}`;
    const params = { benefit_ids: benefitIds };
    const { status } = await httpClient.put(url, params);
    const failed = status !== API_CODE.response.success;

    if (failed) {
      const text = "式場特典の公開に失敗しました　時間をおいて再度お試しください";
      dispatch("modal/showErrorModal", { text, isShow: true }, { root: true });
      return false;
    }

    benefitIds.forEach((benefitId) => {
      const { group_type } = getters.benefit(benefitId);
      commit(types.UPDATE_STATUS, { benefitId, group_type, publishStatus });
    });
    return true;
  },
  // 公開中の特典の並び順を一括で更新する
  async bulkUpdateOrder({ commit, dispatch, getters }, { mediaId, benefitIds }) {
    const url = "benefits/order";
    const params = { media_id: mediaId, benefit_ids: benefitIds };
    const { status } = await httpClient.put(url, params);
    const failed = status !== API_CODE.response.success;

    if (failed) {
      const text = "特典の並び替えに失敗しました　時間をおいて再度お試しください";
      dispatch("modal/showErrorModal", { text, isShow: true }, { root: true });
      return false;
    }

    benefitIds.forEach((benefitId, index) => {
      const { group_type } = getters.benefit(benefitId);
      const sort = index + 1;
      commit(types.UPDATE_SORT, { benefitId, mediaId, group_type, sort });
    });
    return true;
  },
  // フェアの紐付けを差し替え
  async replaceLinkedFairs({ commit, dispatch }, { oldBenefit, newBenefit }) {
    commit("modal/setUpdateModal", { isShow: true });

    const url = `benefits/${oldBenefit.id}/replace`;
    const params = { new_benefit_id: newBenefit.id };
    const response = await httpClient.put(url, params);
    const isSuccess = response && response.status === API_CODE.response.success;

    if (isSuccess) {
      // 表示データの紐付け情報を更新
      const fairs = [...new Set([...newBenefit.used_fairs, ...oldBenefit.used_fairs])];
      commit(types.UPDATE_USED_FAIRS, { benefit: newBenefit, fairs });
      commit(types.UPDATE_USED_FAIRS, { benefit: oldBenefit, fairs: [] });

      commit("modal/setUpdateText");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("modal/showErrorModal", { text: MESSAGE_CONFIG.error });
    }

    return isSuccess;
  },
  // フェアの紐付けを解除
  async deleteLinkedFairs({ commit, dispatch }, { benefit }) {
    commit("modal/setUpdateModal", { isShow: true });

    const url = `benefits/${benefit.id}/link-fairs`;
    const params = { fair_ids: [] };
    const response = await httpClient.put(url, params);
    const isSuccess = response && response.status === API_CODE.response.success;

    if (isSuccess) {
      commit(types.UPDATE_USED_FAIRS, { benefit, fairs: [] });
      commit("modal/setUpdateText");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("modal/showErrorModal", { text: MESSAGE_CONFIG.error });
    }

    return isSuccess;
  },
  // 式場特典かウエパの特典が保存された際の確認モーダルで「はい」がクリックされた時の処理
  // 式場特典公開モーダルを開く
  guideToPublishModal({ commit }, { id: mediaId }) {
    commit(types.SHOW_PUBLISH_MODAL, mediaId);
  },
};

export default actions;
