<template>
  <transition name="slideUp">
    <div class="floatLog" v-if="isShow" :class="{ isSetAssist }">
      <div class="floatLogHead">
        <i class="floatLogHead_icon material-icons">history</i>
        <p class="floatLogHead_title">{{ title }}</p>
        <button type="button" class="floatLogHead_cancel" @click="close">
          <i class="material-icons">cancel</i>
        </button>
      </div>
      <div class="floatLog_main">
        <log-unit
          v-for="v in showLog"
          :key="v.id"
          :data="v"
          :master="master"
          @update="update"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";
import LogUnit from "@/components/molecules/common/LogUnit.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    LogUnit,
  },
  props: {
    title: String,
    log: {
      type: Array,
      default: () => [],
    },
    notice: {
      type: Array,
      default: () => [],
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    isSetAssist: {
      type: Boolean,
      default: false,
    },
    master: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("common", ["isLogAuthorized"]),
    icon: function() {
      return "description";
    },
    showLog: function() {
      return this.log
        .concat(
          this.notice.map((v) => {
            v.isBeforeConfirm = true;
            return v;
          })
        )
        .map((v) => {
          if (v.no_link || !v.queueable_type) return v;
          v.isDisabledLink = !this.isLogAuthorized(v.queueable_type);
          return v;
        });
    },
  },
  methods: {
    close: function() {
      this.$emit("close");
    },
    update: function(id) {
      this.$emit("update", { media_id: this.showLog[0].media_id, log_id: id });
    },
  },
};
</script>

<style scoped lang="scss">
.floatLog {
  background: $BG_COLOR_FLOOR;
  border-radius: $BOX_RADIUS $BOX_RADIUS 0 0;
  box-shadow: $DEPTH_LV_2;
  bottom: 0;
  left: 50%;
  margin-left: $GRID * 1.5;
  overflow: hidden;
  position: fixed;
  transform: translateX(-50%);
  transition: margin $NAVI_SHOW_SPEED;
  z-index: $LAYER_FLOAT_WINDOW;
  width: $MAX_WIDTH + $GRID * 2;
  @media screen and (min-width: $MAIN_WIDTH) {
    margin-left: $NAVI_WIDTH * 0.5;
  }
  &.isAlert {
    background: $BG_COLOR_ALERT;
  }
  &.isNotice {
    background: $BG_COLOR_WARNING;
  }
  &.isSetAssist {
    @media screen and (min-width: $MAIN_L_WIDTH + $GRID) {
      margin-left: 0;
    }
  }
  &Head {
    align-items: center;
    background: #fff;
    box-shadow: $DEPTH_LV_0;
    box-sizing: border-box;
    color: $COLOR_KEY;
    display: flex;
    padding: 8px 14px;
    position: relative;
    width: 100%;
    &_icon {
      font-size: 30px;
      margin-right: $GRID * 0.5;
      &.isAlert {
        color: $COLOR_ALERT;
      }
      &.isNotice {
        color: $COLOR_WARNING;
      }
    }
    &_title {
      font-size: 14px;
      font-weight: bold;
      line-height: 30px;
    }
    &_cancel {
      -webkit-appearance: none;
      border: none;
      color: $COLOR_SUB_LIGHT_TEXT;
      outline: none;
      position: absolute;
      right: $GRID;
      text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      top: 10px;
      &:hover {
        text-shadow: none;
      }
    }
  }
  &_main {
    box-sizing: border-box;
    max-height: $GRID * 22.5;
    min-height: $GRID * 22.5;
    overflow-y: auto;
    padding: $GRID;
  }
}
.slideUp-leave-active,
.slideUp-enter-active {
  transition: bottom 0.3s;
}
.slideUp-enter,
.slideUp-leave-to {
  bottom: -$GRID * 25;
}
</style>
