<template>
  <label class="filterCheckbox" :class="{ isBtnType: isBtnType }">
    <input-checkbox
      class="filterCheckbox_input"
      v-model="selfIsSelected"
      :is-selected="selfIsSelected"
    />
    <span class="filterCheckbox_text">{{ text }}</span>
  </label>
</template>

<script>
import InputCheckbox from "@/components/atoms/common/InputCheckbox.vue";

export default {
  components: {
    InputCheckbox,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: Boolean,
    text: String,
    isBtnType: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {};
  },
  computed: {
    selfIsSelected: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        if (this.label) {
          this.$emit("update", this.label, value);
        } else {
          this.$emit("update", value);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.filterCheckbox {
  align-items: center;
  display: inline-flex;
  &_input {
    .isBtnType & {
      border: none;
      height: 0;
      overflow: hidden;
      width: 0;
      &.isChecked + .filterCheckbox_text {
        background: $COLOR_KEY;
        border-color: $COLOR_KEY;
        color: #fff;
      }
      &:not(.isChecked) + .filterCheckbox_text:hover {
        box-shadow: 0 0 4px 1px rgba(#ccc, 0.5) inset;
      }
    }
  }
  &_text {
    color: $COLOR_SUB_TEXT;
    font-size: 14px;
    margin: 2px 0 0 $GRID * 0.5;
    .isBtnType & {
      border: 1px solid #ccc;
      border-radius: $BOX_RADIUS * 0.5;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      margin: 0;
      padding: 3px 10px;
    }
  }
  &.isBtnType {
    font-size: 0;
    margin: $GRID * 0.25 $GRID * 0.5 $GRID * 0.25 0;
  }
}
</style>
