<template>
  <div
    class="selectedImgBlock"
    :class="{ isShadow: isShadow }"
    :style="{ width: imgSize }"
  >
    <benefit-img :path="path" :height="imgSize" :width="imgSize" />
    <button
      type="button"
      class="selectedImgBlock_reset"
      v-show="path && !isLocked"
      @click="resetImg"
    >
      <i class="material-icons">cancel</i>
    </button>
    <action-btn
      class="selectedImgBlock_submit"
      :text="btnText"
      :is-large="isLargeBtn"
      @clickHandler="changeImg()"
    />
  </div>
</template>

<script>
import ActionBtn from "@/components/atoms/common/ActionButton.vue";
import BenefitImg from "@/components/atoms/common/FairImg.vue";

export default {
  components: {
    ActionBtn,
    BenefitImg,
  },
  props: {
    path: String,
    isShadow: {
      type: Boolean,
      default: false,
    },
    isLargeBtn: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    imgSize: function () {
      let size = "";
      if (this.isLargeBtn) {
        size = this.isShadow ? 200 + 16 : 200;
      } else {
        size = this.isShadow ? 153 + 16 : 153;
      }
      return size + "px";
    },
    btnText: function () {
      return this.path ? "変更する" : "設定する";
    },
  },
  methods: {
    changeImg: function () {
      this.$emit("change");
    },
    resetImg: function () {
      this.$emit("reset");
    },
  },
};
</script>

<style scoped lang="scss">
.selectedImgBlock {
  overflow: hidden;
  position: relative;
  &_reset {
    -webkit-appearance: none;
    border: none;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.8);
    position: absolute;
    right: $GRID * 0.5 - 1;
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
    top: $GRID * 0.5 - 1;
  }
  &_submit {
    margin: $GRID auto 0;
  }
  &.isShadow {
    box-shadow: $DEPTH_LV_1;
    padding-bottom: $GRID;
  }
}
</style>
