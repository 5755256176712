/**
 * 検索用モジュール
 */
// lib
import arrayToggle from "@/lib/arrayToggle";
import deepCopy from "@/lib/deepCopy";
// assets
import API_CODE from "@/assets/common/ApiCode";

const Filter = {
  namespaced: true,
  state: {
    checkedIds: [],
    filter: {
      license: "", // 版権期限
      check_trimming: false, // トリミング設定
      check_no_tag: false, // タグが設定されていない
      keyword: "", // キーワード検索
      in_use: "", // 使用状態
      linked_zexy: "", // ゼクシィに連携状態
      linked_mynavi: "", // マイナビに連携状態
      tags: [], // タグ
    },
    filterMaster: {
      license: {
        end: "終了",
        soon: "終了間近",
        all: "終了または終了間近",
      },
      in_use: {
        in_use: "使用中のもの",
        not_in_use: "使用されていないもの",
      },
      media_status: {
        in_use: "連携中のもの",
        not_in_use: "連携されていないもの",
      },
    },
    sort: {
      active: "1",
      key: {
        1: {
          key: "id",
          order: "desc",
          text: "登録日 新しい順",
        },
        2: {
          key: "id",
          order: "asc",
          text: "登録日 古い順",
        },
      },
    },
    unselectableIds: [], // 選択不可
    isClearedFilter: false,
  },
  getters: {
    checkedData: (state, getters, rootState) => {
      return rootState.data.filter((d) => state.checkedIds.indexOf(d.id) !== -1);
    },
    checkedIds: (state) => {
      return state.checkedIds;
    },
    filteredData: (state, getters, rootState) => {
      let data = deepCopy(rootState.data); // 画像リスト
      const filter = state.filter;
      const sort_active = state.sort.active;
      const sort_key = state.sort.key[sort_active].key;
      const sort_order = state.sort.key[sort_active].order;

      // フィルタリング(検索)
      // キーワード検索
      if (filter.keyword != "") {
        let keywordArray = filter.keyword.replace(/\u3000/g, " ").split(" "); // スペース区切り
        keywordArray.forEach((word) => {
          data = data.filter((d) => {
            const hasKeywordName = d.name && d.name.indexOf(word) !== -1;
            const hasKeywordFileName =
              d.original_file_name && d.original_file_name.indexOf(word) !== -1;
            const hasKeywordId = String(d.id).indexOf(word) !== -1;
            return hasKeywordName || hasKeywordFileName || hasKeywordId;
          });
        });
      }
      // 版権期限
      if (filter.license != "") {
        data = data.filter((d) => {
          if (filter.license == "all") return d.license_status >= 1;
          else if (filter.license == "soon") return d.license_status == 1;
          else if (filter.license == "end") return d.license_status == 2;
        });
      }
      // 使用中
      if (filter.in_use != "") {
        data = data.filter((d) => {
          const inUse = Object.values(d.used_at).some((usedAt) => usedAt.length > 0);

          return filter.in_use === "in_use" ? inUse : !inUse;
        });
      }
      // 媒体連携状態
      if (filter.linked_zexy != "") {
        data = data.filter(({ media }) => {
          const mediaInUse = media.includes(API_CODE.media.zexy);
          return filter.linked_zexy == "in_use" ? mediaInUse : !mediaInUse;
        });
      }
      if (filter.linked_mynavi != "") {
        data = data.filter(({ media }) => {
          const mediaInUse = media.includes(API_CODE.media.mynavi);
          return filter.linked_mynavi == "in_use" ? mediaInUse : !mediaInUse;
        });
      }
      // トリミング設定
      if (filter.check_trimming) {
        data = data.filter((d) =>
          d.trimming.some(
            (trim) =>
              trim.crop_x === null ||
              trim.crop_y === null ||
              trim.height === null ||
              trim.width === null
          )
        );
      }
      // タグ
      if (filter.tags.length > 0) {
        const tags = filter.tags.map(Number);
        data = data.filter((img) =>
          // フィルタリングしているタグIdが全て選択されていれば true, なければ false
          tags.every((tagId) => img.tags.indexOf(tagId) !== -1)
        );
        data = data.map((img) => {
          const noSelect = img.tags.filter((tagID) => tags.indexOf(tagID) === -1);
          img.tags = tags.concat(noSelect); // 選択タグの後に未選択タグをマージ
          return img;
        });
      }
      // タグが設定されていない
      if (filter.check_no_tag) {
        data = data.filter((d) => !Array.isArray(d.tags) || d.tags.length <= 0);
      }

      // ソート
      data = data.slice().sort(function (a, b) {
        a = a[sort_key];
        b = b[sort_key];
        // asc:昇順(1,2,3) desc:降順(3,2,1)
        if (sort_order == "desc") {
          return a === b ? 0 : a > b ? -1 : 1;
        } else if (sort_order == "asc") {
          return a === b ? 0 : a > b ? 1 : -1;
        }
      });
      return data;
    },
    filterItem: (state) => {
      return state.filter;
    },
    isClearedFilter: (state) => {
      return state.isClearedFilter;
    },
    isSelectableAllIds: (state, getters, rootState) => {
      return rootState.data.some((img) => state.unselectableIds.indexOf(img.id) === -1);
    },
    sortData: (state) => {
      return state.sort;
    },
    unselectableIds: (state, getters) => {
      return state.unselectableIds.filter(
        (id) => getters.filteredData.map((v) => v.id).indexOf(id) !== -1
      );
    },
  },
  mutations: {
    /**
     * フィルターの初期化
     */
    INITIALIZE_FILTER(state) {
      state.filter = {
        license: "",
        check_trimming: false,
        check_no_tag: false,
        keyword: "",
        in_use: "",
        linked_zexy: "",
        linked_mynavi: "",
        tags: [],
      };
      state.sort.active = "1";
      state.checkedIds = [];
      state.isClearedFilter = true;
      state.unselectableIds = [];
    },
    /**
     * 選択済みID
     */
    SET_CHECK_ID(state, payload) {
      const { id, isChecked } = payload;
      const index = state.checkedIds.indexOf(id);
      if (isChecked && index === -1) {
        state.checkedIds.push(id); // isCheckedが true であれば checkedIds に追加
      } else if (!isChecked && index !== -1) {
        state.checkedIds.splice(index, 1); // isCheckedが false であれば checkedIds から削除
      }
    },
    /**
     * ID 全選択 or 全解除
     */
    SET_CHECK_ID_ALL(state, payload) {
      let { ids, isChecked } = payload;
      if (isChecked) {
        if (state.checkedIds.length >= 1) {
          ids = ids.filter((id) => state.checkedIds.indexOf(id) === -1); // 新規に選択したID
        }
        state.checkedIds = state.checkedIds.concat(ids);
      } else {
        // フィルタリングに関わらず選択中の画像を全解除
        state.checkedIds = [];
        // // フィルタリングされた画像のみ選択解除
        // state.checkedIds = state.checkedIds.filter(
        //   id => ids.indexOf(id) === -1
        // ); // 選択済みIDリストからidsを除く
      }
    },
    /**
     * フィルタリング
     */
    SET_FILTER(state, payload) {
      const { label, value } = payload;
      state.filter[label] = value;
    },
    /**
     * フィルタリング（配列）
     */
    SET_FILTER_ARRAY(state, payload) {
      const { label, value } = payload;
      arrayToggle(state.filter[label], value).sort((a, b) => a - b);
    },
    /**
     * フィルタリング（チェックボックス）
     */
    SET_FILTER_CHECK(state, payload) {
      const { label } = payload;
      state.filter[label] = !state.filter[label];
    },
    /** inputタグの値を初期化  */
    SET_IS_CLEARED_FILTER(state, payload) {
      state.isClearedFilter = payload;
    },
    /**
     * ソート切り替え
     */
    SET_SORT_KEY(state, key) {
      state.sort.active = key;
    },
    /**
     * 全選択時に除外するID
     */
    SET_UNSELECTED_ID(state, payload) {
      arrayToggle(state.unselectableIds, payload);
    },
  },
  actions: {
    // フィルターの初期化完了
    clearedFilter({ commit }) {
      commit("SET_IS_CLEARED_FILTER", false);
    },
    /**
     * フィルターの初期化
     */
    initialize({ commit, dispatch, rootState, state }) {
      commit("INITIALIZE_FILTER");
      if (rootState.route) {
        // URLクエリパラメータから検索項目の初期値をセット
        for (let k of Object.keys(rootState.route.query)) {
          if (k in state.filter) {
            if (k == "check_trimming") {
              // 検索項目（チェックボックス）に存在する場合はセット
              dispatch("updateFilterCheck", {
                label: k,
                value: rootState.route.query[k],
              });
            }
            if (["license", "in_use", "linked_zexy", "linked_mynavi"].includes(k)) {
              // 検索項目に存在する場合はセット
              dispatch("updateFilter", {
                label: k,
                value: rootState.route.query[k],
              });
            }
          }
        }
      }
      // 選択不可の画像IDリストを更新
      Object.keys(rootState.data).forEach((k) => {
        const status = rootState.data[k].link_statuses;
        const inSyncMedia = Object.keys(status).some(
          (mediaId) =>
            status[mediaId] === API_CODE.linkStatus.inSync ||
            status[mediaId] === API_CODE.linkStatus.locked
        );
        if (inSyncMedia) commit("SET_UNSELECTED_ID", rootState.data[k].id);
      });
    },
    // 選択済みID
    updateCheckedId({ commit }, data) {
      commit("SET_CHECK_ID", data);
    },
    // IDを全選択 or 全解除
    updateCheckIdAll({ commit, getters, state }, isChecked) {
      let ids = [];
      ids = getters.filteredData.map((img) => img.id);
      if (state.unselectableIds.length >= 1) {
        ids = ids.filter((id) => state.unselectableIds.indexOf(id) === -1);
      }
      let param = { ids, isChecked };
      commit("SET_CHECK_ID_ALL", param);
    },
    // フィルタリング
    updateFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    // フィルタリング（配列）
    updateFilterArray({ commit }, data) {
      commit("SET_FILTER_ARRAY", data);
    },
    // フィルタリング（チェックボックス）
    updateFilterCheck({ commit }, data) {
      commit("SET_FILTER_CHECK", data);
    },
    // ソート切り替え
    updateSortKey({ commit }, key) {
      commit("SET_SORT_KEY", key);
    },
  },
};
export default Filter;
