<template>
  <button
    type="button"
    class="circleIconBtn"
    :class="classType"
    :disabled="isDisabled"
    :title="title"
    @click="click"
  >
    <i class="material-icons">{{ icon }}</i>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    isAlert: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSetting: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    classType: function() {
      const type = {
        isAlert: this.isAlert,
        isSetting: this.isSetting,
      };
      return type;
    },
  },
  methods: {
    click: function(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped lang="scss">
.circleIconBtn {
  & {
    background: $BG_COLOR_BOX;
    border: 1px solid $COLOR_SUB_LIGHT_TEXT;
    border-radius: 50%;
    color: $COLOR_SUB_LIGHT_TEXT;
    height: $GRID * 1.75;
    width: $GRID * 1.75;
    @include btn(rgba($COLOR_SUB_LIGHT_TEXT, 0.1));
    &.isAlert {
      @include btn(rgba($COLOR_ALERT, 0.1));
    }
    &.isSetting {
      @include btn(rgba($COLOR_SUBMIT, 0.1));
    }
  }
  &::before {
    border-radius: 50%;
    bottom: 0;
    height: 0;
    transition: height 0.2s, width 0.2s;
    width: 0;
  }
  &:disabled {
    box-shadow: none;
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    border-color: $COLOR_SUB_LIGHT_TEXT;
    color: $COLOR_SUB_LIGHT_TEXT;
    &.isAlert {
      border-color: $COLOR_ALERT;
      color: $COLOR_ALERT;
    }
    &.isSetting {
      border-color: $COLOR_SUBMIT;
      color: $COLOR_SUBMIT;
    }
    &::before {
      height: 100%;
      width: 100%;
    }
  }
  .material-icons {
    font-size: 16px;
  }
}
</style>
