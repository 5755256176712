<template>
  <ul class="selectedInfo_list">
    <li>
      <span class="fontNum">#{{ targetId }}</span>
      <span v-if="isDailySetting" class="selectedInfo_daily">（日毎設定）</span>
    </li>
    <li>
      {{ selectedUnit }}
      <p
        class="selectedInfo_error"
        v-if="isError"
        :class="{ isFatal: errorData.isFatalError }"
      >
        <i class="material-icons">error</i>
        {{ errorData ? errorData.text : "" }}
      </p>
    </li>
    <li>
      <link-button
        class="selectedInfo_linkBtn"
        text="編集する"
        v-if="!isFairGroup && canEdit"
        :path="`/fair-master-edit/${targetId}`"
        :is-sub="true"
      />
    </li>
  </ul>
</template>

<script>
import LinkButton from "@/components/atoms/common/LinkButton.vue";

export default {
  components: {
    LinkButton,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    errorData: {
      type: Object,
      default: () => {},
    },
    isFairGroup: {
      type: Boolean,
      default: false,
    },
    isDailySetting: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    selectedUnit: {
      type: String,
      default: "",
    },
    targetId: {
      type: Number,
      default: null,
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.selectedInfo {
  &_list {
    list-style: none;
  }
  &_linkBtn {
    margin-top: $SUB_GRID * 2;
  }
  &_dates {
    list-style: none;
    margin-top: $SUB_GRID * 1;
  }
  &_date {
    margin-bottom: $SUB_GRID * 2;
    word-wrap: break-word;
  }
  &_daily {
    font-size: 12px;
  }
  &_error {
    align-items: center;
    border: 1px solid $COLOR_ALERT;
    border-radius: 4px;
    color: $COLOR_ALERT;
    display: flex;
    font-size: 12px;
    margin: $GRID 0;
    padding: $SUB_GRID;
    .material-icons {
      display: inline-block;
      font-size: 18px;
      margin-right: $SUB_GRID;
    }
    &.isFatal {
      background-color: $COLOR_ALERT;
      color: #fff;
    }
  }
}
</style>
