// Store Module
import commonModule from "@/store/common";
import modalModule from "@/store/modal";
import utilityModule from "@/store/utility";

import filterModule from "@/store/hall-admin/FilterModule";
import serviceSetting from "@/store/hall-admin/ServiceSetting";

import actions from "@/store/hall-admin/main/actions";
import getters from "@/store/hall-admin/main/getters";
import mutations from "@/store/hall-admin/main/mutations";

const hallAdminModule = {
  namespaced: true,
  modules: {
    common: commonModule,
    modal: modalModule,
    utility: utilityModule,
    filter: filterModule,
    serviceSetting,
  },
  state: {
    hall: {
      count: null,
      list: null,
      master: null,
      isWholeListLoaded: false,
    },
    errorText: "",
    // edit.any.*** の権限
    isAuthorized: {
      // 各 step の権限
      // input: false,
      import: false, // startImport
      associate: false, // associateImport
      create: false, // completeData
      confirm: false, // publish
      // その他権限
      halls: false, // 開始前⇒準備中 開始権限
      settings: false, // 準備中⇒利用中 開始権限
      mediaAccounts: false, // 対象媒体 変更権限
    },
  },
  getters,
  mutations,
  actions,
};

export default hallAdminModule;
