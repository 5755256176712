<template>
  <dl
    v-if="count > 0"
    class="useCount used"
    :class="{ isLarge, 'need-link': needLink }"
    @click="$emit('click')"
  >
    <dt class="useCount__count">
      {{ statusText }}中<span v-if="showCount" class="fontNum-b">{{
        count
      }}</span>
    </dt>
    <dd v-if="needLink" class="material-icons">open_in_new</dd>
  </dl>
  <p class="useCount empty" v-else :class="{ isLarge }">{{ statusText }}なし</p>
</template>

<script>
export default {
  props: {
    statusText: {
      type: String,
      default: "使用",
    },
    count: {
      type: Number,
      default: 0,
    },
    showCount: {
      type: Boolean,
      default: true,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    needLink: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.useCount {
  @include benefitExtraLabel();
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 12px;
  padding: 0 $GRID;

  &.need-link {
    padding: 0 $SUB_GRID;
    cursor: pointer;

    .material-icons {
      font-size: 14px;
    }

    &::before {
      background: $COLOR_KEY;
      content: "";
      height: 100%;
      left: 0;
      margin: auto;
      opacity: 0.1;
      position: absolute;
      right: 0;
      top: 0;
      transition: width 0.2s;
      width: 0;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  &.used {
    gap: $GRID;
    font-weight: bold;

    .fontNum-b {
      margin-left: 5px;
      font-size: 15px;
    }
  }

  &.empty {
    background: #fff;
    border-color: #f7f7f7;
    color: $COLOR_DISABLE_TEXT;
    text-align: center;
    padding: 0 $GRID;
  }

  &.isLarge {
    width: $GRID * 6.5;
    height: 32px;
    padding: ($GRID * 0.25) ($SUB_GRID * 1.2);

    &.used {
      gap: 0;
      justify-content: space-between;
    }

    &.empty {
      padding: 5px 0;
    }
  }

  &__count {
    display: flex;
    align-items: center;
  }
}
</style>
