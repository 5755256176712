<template>
  <div class="tabs">
    <div class="tabs__items">
      <div
        v-for="(item, index) in entries"
        class="tabs__item"
        :key="index"
        :class="tabItemClass(item[indexKey])"
        @click="onClickTab(item[indexKey])"
      >
        <slot :name="`header_${item[indexKey]}`"></slot>
        <span v-if="!hasSlot(`header_${item[indexKey]}`)">{{
          item[labelKey]
        }}</span>
      </div>
    </div>
    <div
      class="tabs__contents"
      :class="{ 'tabs__contents--bg-color': hasBgColor }"
    >
      <div
        class="tabs__content"
        v-for="(item, index) in entries"
        :key="index"
        :class="{
          'tabs__content--current': currentTab === item[indexKey],
        }"
      >
        <slot v-if="currentTab === item[indexKey]" :name="index"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      defualt: null,
    },
    entries: {
      type: [Array, Object],
      default: () => [],
    },
    initTab: {
      type: Number,
      defualt: 1,
    },
    indexKey: {
      type: String,
      default: "id",
    },
    labelKey: {
      type: String,
      default: "short_name",
    },
    hasBgColor: {
      type: Boolean,
      default: false,
    },
    displayedIds: {
      type: Array,
      default: () => [1, 2, 3, 4, 5, 6],
    },
  },
  data() {
    return {
      selectedTab: 1,
    };
  },
  computed: {
    hasValue() {
      return this.value !== null && this.value !== undefined;
    },
    currentTab: {
      get() {
        return this.hasValue ? this.value : this.selectedTab;
      },
      set(value) {
        if (this.hasValue) {
          this.$emit("input", value);
        } else {
          this.selectedTab = value;
        }
      },
    },
  },
  created() {
    this.selectedTab = this.initTab;
  },
  methods: {
    onClickTab(tabId) {
      const isDisplayed = this.displayedIds.includes(tabId);
      if (!isDisplayed || tabId === this.currentTab) return;
      this.currentTab = tabId;
    },
    hasSlot(slotName) {
      return this.$slots[slotName] != null;
    },
    tabItemClass(tabId) {
      const hasSlot = this.hasSlot(`header_${tabId}`);
      const isDisplayed = this.displayedIds.includes(tabId);
      return {
        "tabs__item--current": this.currentTab === tabId,
        "tabs__item--disabled": !isDisplayed,
        "tabs__item--no-padding": hasSlot,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  width: 100%;

  &__items {
    display: flex;
  }

  &__item {
    padding: ($GRID * 0.5) $GRID;
    border-radius: ($SUB_GRID * 0.5) ($SUB_GRID * 0.5) 0 0;
    cursor: pointer;

    &--current {
      background-color: $BG_COLOR_BOX;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &--no-padding {
      padding: 0;
    }
  }

  &__contents {
    padding: $GRID;
    border: 2px solid $BG_COLOR_BOX;
    border-radius: $SUB_GRID * 0.5;
    width: 100%;

    &--bg-color {
      background: #fff;
    }
  }
}
</style>
