<template>
  <input
    type="text"
    class="filterInput"
    :name="name"
    :placeholder="placeholder"
    @input="updateValue"
  />
</template>

<script>
export default {
  props: {
    name: String,
    placeholder: String
  },
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    updateValue: function(e) {
      this.$emit("update", this.name, e.target.value);
    }
  }
};
</script>

<style scoped lang="scss">
.filterInput {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: block;
  outline: none;
  padding: 7px 10px 6px;
  width: 300px;
}
</style>