<template>
  <validating-textarea
    v-model="value"
    :is-full-size="true"
    :is-textarea="true"
    :is-required="isRequired"
    :use-error-code="useErrorCode"
    :ng-word="getNgWord"
    :max-length="maxLength"
    :text="text"
    :placeholder="placeholder"
    :rows="rows"
    :media-ids="[media]"
    @check="$emit('check', $event)"
  />
</template>

<script>
import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";

import { NgWordType } from "@/assets/common/NgWordType";

import { mapGetters } from "vuex";

export default {
  components: {
    ValidatingTextarea,
  },
  props: {
    keyName: String,
    text: String,
    placeholder: {
      text: String,
      default: null,
    },
    rows: {
      type: Number,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    useErrorCode: {
      type: Boolean,
      default: true,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    media: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["groupType", "selectedOnlyMedia"]),
    ...mapGetters("ngCheck", ["targetWord"]),
    value: {
      get() {
        return this.text;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getNgWord() {
      const types = [NgWordType.COMMON, NgWordType.BENEFIT];
      return this.targetWord(this.selectedOnlyMedia(this.keyName), types);
    },
  },
};
</script>
