/**
 * 検索用モジュール
 */
import { IS_PRODUCTION_SERVER } from "@/assets/common/ServerConfig";
import { getSelectedHallId } from "@/assets/common/PageConfig.js";

const Filter = {
  namespaced: true,
  state: {
    master: {
      filterType: {
        waiting: "waitingJobs",
        executed: "executedJobs",
      },
    },
    filter: {
      keyword: "", // キーワード検索
      period: {
        from: "",
        to: "",
      }, // 実行期間
      status: "", // 実行状態
      type: "", // 対象
      media_id: "", // 媒体
      hall_id: "", // 式場ID
      is_actual: false, // 実際の式場のみフィルタするか（テストアカウント除外）
    },
    sort: {
      active: "1",
      key: {
        "1": {
          key: "requested_at",
          order: "desc",
          text: "実行日時 新しい順",
        },
        "2": {
          key: "requested_at",
          order: "asc",
          text: "実行日時 古い順",
        },
      },
    },
    isClearedFilter: false,
    filterType: "waiting",
  },
  getters: {
    filterQuery: (state) => {
      return state.filter;
    },
    filteredData: (state, getters, rootState) => {
      let data = rootState.job[state.master.filterType[state.filterType]];
      const filter = state.filter;
      const sort_active = state.sort.active;
      const sort_key = state.sort.key[sort_active].key;
      const sort_order = state.sort.key[sort_active].order;
      // 待機中ジョブはフロント側でフィルタ
      if (state.filterType == "waiting") {
        // 式場ID検索
        if (filter.hall_id != "") {
          // 完全一致
          data = data.filter((d) => d.hall_id == filter.hall_id);
        }
        // キーワード検索
        if (filter.keyword != "") {
          // 部分一致. AND/OR 検索非対応
          data = data.filter(
            (d) =>
              d.queueable_title &&
              d.queueable_title.indexOf(filter.keyword) !== -1
          );
        }
        // 実行状態
        if (filter.status) {
          data = data.filter((d) => d.status == filter.status);
        }
        // 対象
        if (filter.type) {
          data = data.filter((d) => d.queueable_type == filter.type);
        }
        // 媒体
        if (filter.media_id) {
          data = data.filter(
            (d) =>
              d.media_id && d.media_id.indexOf(Number(filter.media_id)) !== -1
          );
        }
      }
      // 実際の式場のみフィルタするか（テストアカウント除外）
      if (filter.is_actual) {
        data = data.filter((d) => d.hall_id < 900000);
      }
      // ソート
      data = data.slice().sort(function(a, b) {
        a = a[sort_key];
        b = b[sort_key];
        // asc:昇順(1,2,3) desc:降順(3,2,1)
        if (sort_order == "desc") {
          return a === b ? 0 : a > b ? -1 : 1;
        } else if (sort_order == "asc") {
          return a === b ? 0 : a > b ? 1 : -1;
        }
      });
      return data;
    },
    filterItem: (state) => {
      return state.filter;
    },
    isClearedFilter: (state) => {
      return state.isClearedFilter;
    },
    sortData: (state) => {
      return state.filterType == "executed" ? state.sort : {};
    },
  },
  mutations: {
    /**
     * フィルターの初期化
     */
    INITIALIZE_FILTER(state, { hallId, isParentPage }) {
      state.filter = {
        keyword: "", // キーワード検索
        period: {
          from: dateToText(new Date()),
          to: dateToText(new Date()),
        }, // 実行期間
        status: "", // 実行状態
        type: "", // 対象
        media_id: "", // 媒体
        hall_id: hallId || "", // 式場ID
        is_actual: IS_PRODUCTION_SERVER && isParentPage, // 実際の式場のみフィルタするか（テストアカウント除外）
      };
      state.sort.active = "1";
      state.isClearedFilter = true;
    },
    /**
     * 初期化フラグのセット
     */
    SET_IS_CLEARED_FILTER(state, payload) {
      state.isClearedFilter = payload;
    },
    /**
     * フィルタリング
     */
    SET_FILTER(state, payload) {
      const { label, value } = payload;
      state.filter[label] = value;
    },
    /**
     * ソート切り替え
     */
    SET_SORT_KEY(state, key) {
      state.sort.active = key;
    },
    /**
     * フィルター対象のデータを変更
     */
    SET_FILTER_TYPE(state, type) {
      state.filterType = type;
    },
  },
  actions: {
    // フィルターの初期化完了
    clearedFilter({ commit }) {
      commit("SET_IS_CLEARED_FILTER", false);
    },
    // フィルターの初期化
    initialize({ commit, rootState, rootGetters }) {
      const isParentAccount =
        rootGetters["common/isViewableAnyHall"] ||
        rootGetters["common/isViewableOwnHall"];
      commit("INITIALIZE_FILTER", {
        hallId: rootState.job.hallId,
        isParentPage: isParentAccount && getSelectedHallId() == null,
      });
    },
    // フィルタリング
    updateFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    // ソート切り替え
    updateSortKey({ commit }, key) {
      commit("SET_SORT_KEY", key);
    },
    // フィルター対象のデータを変更
    updateFilterType({ commit }, type) {
      commit("SET_FILTER_TYPE", type);
    },
  },
};
export default Filter;

/**
 * 日付をYYYY-MM-DDに変換
 * @param {Date} date 日付
 */
const dateToText = (date) => {
  const Y = date.getFullYear();
  const m = ("0" + (date.getMonth() + 1)).slice(-2);
  const d = ("0" + date.getDate()).slice(-2);
  return `${Y}-${m}-${d}`;
};
