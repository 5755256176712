<template>
  <unit-menu :is-disabled="isDisabled" ref="menu">
    <template #menu>
      <menu-btn
        v-if="showWepaPublishButton"
        :text="`ウエパ${isPublished ? 'から非公開に' : 'に公開'}する`"
        icon="lock"
        @clickHandler="emit('menuToggleBenefitPublishStatus')"
      />
      <menu-btn
        v-scroll-to="'#scrollTop'"
        text="コピーして編集する"
        icon="edit"
        @clickHandler="emit('menuCopyBenefit')"
      />
      <menu-btn
        v-if="canCopyAsHallBenefit"
        v-scroll-to="'#scrollTop'"
        text="コピーして式場特典を編集する"
        icon="edit"
        @clickHandler="emit('menuCopyAsHallBenefit')"
      />
      <menu-btn
        text="削除する"
        icon="delete"
        :is-disabled="isInUsed"
        @clickHandler="emit('menuDeleteBenefit')"
      />
    </template>
  </unit-menu>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import UnitMenu from "@/components/molecules/common/ListUnitMenu.vue";
import MenuBtn from "@/components/atoms/common/MenuButton.vue";

export default {
  components: {
    UnitMenu,
    MenuBtn,
  },
  props: {
    benefit: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInUsed: {
      type: Boolean,
      default: false,
    },
    showWepaPublishButton: {
      type: Boolean,
      default: false,
    },
    canCopyAsHallBenefit: {
      type: Boolean,
      default: false,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emit(event) {
      this.$refs.menu.changeIsShow();
      this.$emit(event);
    },
  },
};
</script>
