import imageCompression from "browser-image-compression";
import { httpClient } from "@/lib/httpClient";
import API_CODE from "@/assets/common/ApiCode";

/**
 * ファイルを読み込む
 * @param {File} file 選択したファイル
 */
const loadFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
};
const compressFile = async (file, commit) => {
  const payload = { isShow: true, text: "画像を圧縮中です。" };
  commit("modal/setUpdateModal", payload, { root: true });

  const options = {
    maxSizeMB: 4,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return new File([compressedFile], file.name, {
      type: file.type,
    });
  } catch (error) {
    console.log("image compress error", error, file);
    return null;
  } finally {
    commit("modal/setUpdateModal", { isShow: false }, { root: true });
  }
};
/**
 * 画像を読み込む
 * @param {String} src 画像のパス、URL
 */
const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (e) => reject(e);
    image.src = src;
  });
};
/**
 * 画像 アップロード モジュール
 */
const Upload = {
  namespaced: true,
  state: {
    fileSizeMax: 4 * (2 ** 10) ** 2, // 4MB
    fileType: "image/jpeg",
    fileWidthMin: 640, // 短辺が640px未満の場合, アップロード対象外
    fileWidthCompress: 1600, // 長辺が1600px以上の場合, 圧縮対象
    images: [],
    isCancel: false, // アップロードの中断
    progress: {
      max: 0,
      value: 0,
    }, // アップロードの進捗状況
    selectedErrors: [],
    selectableCount: 20, // 同時にアップロードできる枚数
  },
  getters: {
    images: (state) => {
      return state.images;
    },
    progress: (state) => {
      return state.progress;
    },
    selectedErrors: (state) => {
      return state.selectedErrors;
    },
    status: (state) => {
      const isCancel = state.isCancel;
      const isCompleted =
        state.images.length >= 1 &&
        state.images.every(
          (v) => v.status === "error" || v.status === "uploaded"
        );
      const isRunning =
        state.images.length >= 1 &&
        state.images.some((image) => image.status === "uploading");
      const isSelected = state.images.length >= 1;
      return { isCancel, isCompleted, isRunning, isSelected };
    },
    selectableCount: (state) => {
      return state.selectableCount;
    },
  },
  mutations: {
    ADD_IMAGE(state, payload) {
      state.images.push(payload);
    },
    ADD_SELECTED_ERROR(state, payload) {
      // const { index, error } = payload;
      state.selectedErrors.push(payload);
    },
    DELETE_IMAGE(state, index) {
      state.images.splice(index, 1);
    },
    INIT_PROGRESS(state) {
      state.progress = {
        max: state.images.filter((image) => image.status === "selected").length,
        value: 0,
      };
    },
    UPDATE_IMAGE_ITEM(state, { index, keyName, item }) {
      if (index in state.images) {
        state.images[index][keyName] = item;
      }
    },
    UPDATE_IMAGES(state, payload) {
      state.images = payload;
    },
    UPDATE_IS_CANCEL(state, payload) {
      state.isCancel = payload;
    },
    UPDATE_SELECTED_ERRORS(state, payload) {
      state.selectedErrors = payload;
    },
    UPDATE_PROGRESS(state) {
      state.progress.value += 1;
    },
  },
  actions: {
    /**
     * 画像をサーバーにアップロード
     * @param {*} param0
     * @param {Number} index 選択した画像のindex
     */
    async addImageApi({ commit, dispatch, state }, index) {
      // FormData を利用して File を POST
      let formData = new FormData();
      formData.append("image", state.images[index].file);
      let url = "/images";
      const response = await httpClient
        .post(url, formData)
        .catch((error) => console.log(error));
      const result = response && response.status === API_CODE.response.created;
      const data = await dispatch("getResponseData", response, { root: true });
      commit("UPDATE_IMAGE_ITEM", {
        index,
        keyName: "status",
        item: result ? "uploaded" : "error",
      });
      if (data) {
        // アップロードモーダルの編集するボタンのリンク先をセットするため更新
        commit("UPDATE_IMAGE_ITEM", {
          index,
          keyName: "id",
          item: data.id,
        });
      }
      commit("UPDATE_PROGRESS");
    },
    /**
     * 画像アップロードを中断する
     * @param {*} param0
     */
    cancelUploadImage({ commit }) {
      commit("UPDATE_IS_CANCEL", true);
    },
    /**
     * サーバーに同名の画像が存在するか
     * @param {*} param0
     * @param {String} name 画像名
     */
    async checkSameImageApi({ dispatch }, name) {
      let url = "/images/check-same-file";
      const param = { file_name: name };
      const response = await httpClient
        .post(url, param)
        .catch((error) => console.log(error));
      const data = await dispatch("getResponseData", response, { root: true });
      const result = response && response.status === API_CODE.response.success;
      if (result && data && Array.isArray(data)) {
        return data;
      } else {
        return [];
      }
    },
    /**
     * 有効なファイルであるか
     * @param {*} param0
     * @param {Object} payload { index: 選択時のindex, file:選択ファイル }
     */
    checkValidFile({ commit, state }, payload) {
      const { index, file } = payload;
      let error;
      if (!file) {
        error = file.name + "を読み込みできません";
      } else if (file.type !== state.fileType) {
        error = file.name + "はJPEGファイルではありません";
      }
      if (error) commit("ADD_SELECTED_ERROR", { index, error });
      return !error;
    },
    /**
     * 有効な画像であるか
     * @param {*} param0
     * @param {Object} payload { index: 選択時のindex, image:読込画像, file:選択ファイル }
     */
    checkValidImage({ commit, state }, payload) {
      const { index, image, file } = payload;
      let error;
      if (!image) {
        error = file.name + "を読み込みできません";
      } else if (
        image.height < state.fileWidthMin ||
        image.width < state.fileWidthMin
      ) {
        const size = `${state.fileWidthMin}x${state.fileWidthMin}px未満`;
        error = file.name + "はサイズが小さい(" + size + ")ため登録できません";
      }
      if (error) commit("ADD_SELECTED_ERROR", { index, error });
      return !error;
    },
    /**
     * 指定画像をstate.imagesから削除
     * @param {*} param0
     * @param {Object} index
     */
    deselectImage: function({ commit }, index) {
      commit("DELETE_IMAGE", index);
    },
    /**
     * 読み込んだ画像ファイルを削除
     * @param {*} param0
     */
    clearData({ commit, dispatch, state }) {
      commit("UPDATE_IS_CANCEL", false);
      if (state.images.some((image) => image.status === "uploaded")) {
        dispatch("getInitialData", null, { root: true });
      }
      commit("UPDATE_IMAGES", []);
      commit("UPDATE_SELECTED_ERRORS", []);
      commit("INIT_PROGRESS");
    },
    /**
     * 画像ファイルを読み込みstateにセット
     * @param {*} param0
     * @param {FileList} files 選択したファイルのリスト
     */
    readImages({ commit, dispatch, state }, files) {
      commit("UPDATE_SELECTED_ERRORS", []);
      Object.keys(files).forEach((index) => {
        const param = { index, file: files[index] };
        dispatch("checkValidFile", param);
        if (state.selectedErrors.some((error) => error.index === index)) return;
        dispatch("readImage", param);
      });
    },
    /**
     * 画像ファイルを読み込みstateにセット
     * @param {*} param0
     * @param {Object} payload { index: 選択時のindex, file:選択ファイル }
     */
    async readImage({ commit, dispatch, state }, payload) {
      const { index, file } = payload;
      // 同名の画像がサーバーに存在するか確認
      const sameImages = await dispatch("checkSameImageApi", file.name);
      // 画像を自動圧縮
      const compressedFile = await compressFile(file, commit);
      if (!compressedFile) {
        const payload = {
          text: "画像の自動圧縮が失敗しました。\n別の画像を選択してください。",
        };
        dispatch("modal/showErrorModal", payload, { root: true });
        return;
      }

      // ファイル読込
      const url = await loadFile(compressedFile).catch(() => null);
      const image = await loadImage(url).catch(() => null);
      const isValidImage = await dispatch("checkValidImage", {
        index,
        image,
        file: compressedFile,
      });
      if (isValidImage) {
        const isCompress =
          image.height > state.fileWidthCompress ||
          image.width > state.fileWidthCompress;
        commit("ADD_IMAGE", {
          file: compressedFile,
          isCompress,
          sameImages: sameImages,
          size: {
            height: image.height,
            width: image.width,
          },
          status: "selected",
          url,
        });
      }
    },
    /**
     * 複数の画像をサーバーにアップロード
     * @param {*} param0
     */
    async uploadImages({ commit, dispatch, state }) {
      commit("INIT_PROGRESS");
      for (let index in state.images) {
        const status = state.images[index].status;
        if (state.isCancel) {
          break;
        } else if (status === "error" || status === "uploaded") {
          continue;
        }
        commit("UPDATE_IMAGE_ITEM", {
          index,
          keyName: "status",
          item: "uploading",
        });
        await dispatch("addImageApi", index);
      }
      if (state.isCancel) commit("UPDATE_IS_CANCEL", false);
    },
  },
};

export default Upload;
