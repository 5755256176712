import { httpClient } from "@/lib/httpClient";
import API_CODE from "@/assets/common/ApiCode";

const actions = {
  // モーダル表示
  async showModalVoice({ commit }) {
    commit("SET_MODAL_SHOW", true);
  },
  async closeModalVoice({ commit }) {
    commit("SET_MODAL_SHOW", false);
  },
  // 変更内容を state に保存
  setVoiceData({ commit }, payload) {
    commit("UPDATE_DATA", payload);
  },
  // データ送信
  async sendData({ commit, dispatch, state }) {
    commit("modal/setUpdateModal", { isShow: true, text: "送信中です…" });
    const u = "voice";
    const p = {
      content: state.data.content,
      customer_name: state.data.customer_name,
      customer_tel: state.data.customer_tel,
      customer_mail: state.data.customer_mail,
      customer_time: state.data.customer_time,
    };
    await dispatch("postVoiceData", {
      url: `${u}`,
      params: p,
    });
  },
  async postVoiceData({ commit, dispatch }, payload) {
    const { url, params } = payload;
    const response = await httpClient.post(url, params).catch((error) => {
      const e = {
        isShow: true,
        text: error,
      };
      dispatch("modal/showErrorModal", e);
    });
    if (response && response.status === API_CODE.response.created) {
      commit("SET_MODAL_SHOW", false);
      commit("CLEAR_DATA");
      commit("modal/setCompleteText", "送信しました");
      dispatch("modal/showCompleteModal");
    } else {
      const e = {
        isShow: true,
        text:
          "お問い合わせの送信に失敗しました　時間をおいて再度お試しください",
      };
      dispatch("modal/showErrorModal", e);
    }
  },
  /**
   * バリデーション
   */
  /**
   * 初期化処理
   * rulesを読み込んでstate.requiredItemsを更新
   * @param {*} param0
   */
  init({ commit, state }) {
    const rules = Object.keys(state.rules).filter(
      (k) => state.rules[k].is_required
    );
    commit("REQUIRED_ITEMS", rules);
  },
  /**
   * 入力済みかどうかの情報を登録
   * 入力済みかどうかのチェックの後、必須項目かどうかのチェックを行う
   * @param {*} param0
   * @param {*} payload { Key: 要素名, isValid: 正常に入力されているか }
   */
  setValidation({ commit }, payload) {
    // const { key, isValid } = payload;
    commit("VALID_ITEM", payload);
    commit("COMPLETED_REQUIRED_ITEM", payload);
    commit("ITEMS_HAS_ERROR", payload);
  },
};
export default actions;
