<template>
  <div class="voiceDetail">
    <div class="voiceDetail_icons">
      <span class="iconPop iconAdd material-icons">add_comment</span>
      <span class="iconPop iconSms material-icons">sms</span>
      <span class="iconPop iconAnnounce material-icons">announcement</span>
      <span class="iconConstruct material-icons">construction</span>
    </div>
    <p class="voiceDetail_txt">
      {{ name }}は式場様と一緒に
      <br />作り上げていきたいと考えております。
    </p>
    <p class="voiceDetail_txt">
      機能やサービスに関する、
      <br />ご意見・ご要望をお聞かせください。
    </p>
    <p class="voiceDetail_txt">
      サービス向上・改善の
      <br />参考にさせていただきます。
    </p>
  </div>
</template>

<script>
import MASTER_CONFIG from "@/assets/common/MasterConfig";

export default {
  computed: {
    name: function() {
      return MASTER_CONFIG.name;
    },
  },
};
</script>

<style scoped lang="scss">
.voiceDetail {
  @include gradientMain();
  border-radius: $BOX_RADIUS;
  padding: $GRID * 1.5 $GRID * 0.5 $GRID * 2;
  text-align: center;
  width: $GRID * 17;
  &_icons {
    display: inline-block;
    position: relative;
    padding: $GRID * 2.25 0 $GRID * 1.5;
  }
  &_txt {
    font-size: 13px;
    & + & {
      margin-top: $GRID;
    }
  }
}
.iconPop {
  left: 50%;
  position: absolute;
}
.iconAdd {
  left: -$GRID * 0.5;
  top: $GRID;
}
.iconSms {
  left: $GRID * 1.25;
  top: -$GRID * 0.25;
}
.iconAnnounce {
  right: -$GRID * 1.75;
  top: $GRID;
}
.iconConstruct {
  font-size: 52px;
}
</style>
