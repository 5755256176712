<template>
  <div class="imgUnit">
    <div class="imgUnitHead">
      <fair-id
        v-if="mode === 'view' && !isDisabled"
        :id="image.id"
        :is-checked="checkedIds.indexOf(image.id) !== -1"
        @update="updateId"
      />
      <div class="fontNum" v-else>#{{ image.id }}</div>
    </div>
    <div class="block" :class="blockClassType" @click="selectImage(false)">
      <div class="img" :class="{ noUrlType }">
        <fair-img height="224px" width="224px" :path="url" />
        <period-date
          text="版権期限"
          v-if="image.license_valid_until"
          :date="image.license_valid_until"
          :status="image.license_status"
          :status-master="master.license_status"
        />
      </div>
      <div class="detail">
        <div class="name ellipsis" :class="{ isCenter: !image.name }">
          {{ image.name ? image.name : image.original_file_name }}
        </div>
        <div class="file ellipsis" v-if="image.name">
          {{ image.original_file_name }}
        </div>
        <div class="tag ellipsis" :class="{ disabled: tags.length === 0 }">
          <i class="material-icons">local_offer</i>
          <span>{{ tags.length >= 1 ? tags.join(",") : "なし" }}</span>
        </div>
        <div class="used borderBottom">
          <use-count
            class="usedCount"
            :class="{ disabled: mode === 'select' || isDisabled }"
            :count="image.used_data.length"
            :is-large="true"
            @click="$emit('modalOpen', 'replace', image)"
          />
          <div class="link">
            <div
              class="selectBox"
              v-for="(v, i) in image.media_image_id"
              :class="{ disabled: image.media.indexOf(Number(i)) === -1 }"
              :key="i"
            >
              <span>{{ medias[i].short_name }}</span>
            </div>
          </div>
        </div>
        <div class="trim borderBottom">
          <i class="material-icons">crop</i>
          <div
            class="selectBox fontNum"
            v-for="(v, i) in image.trimming"
            :class="{ disabled: !isSetTrim(v.ratio_type) }"
            :key="i"
          >
            <span>{{ master.trim_type[v.ratio_type] }}</span>
          </div>
        </div>
        <div class="size borderBottom fontNum">
          <p class="px">
            <i class="material-icons">photo</i>
            <span>{{ image.width }} x {{ image.height }}</span>
          </p>
          <div class="selectBox" :class="{ disabled: !existsLargeTrim }">
            {{ sizeType }}
          </div>
        </div>
        <action-button
          v-if="mode === 'view'"
          class="settingBtn"
          :is-disabled="isDisabled || isFatalError"
          :is-large="true"
          text="編集する"
          @clickHandler="selectImage"
        />
      </div>

      <status-label
        v-if="isFatalError"
        :is-fatal-error="true"
        :is-small="true"
      />
      <status-label
        v-else-if="isLinkageError"
        :is-linkage-error="true"
        :is-small="true"
        :medias="medias"
        :error-media-ids="linkageErrorMediaIds"
      />

      <inoperable-cover
        v-if="isDisabled"
        :icon="message.icon"
        :is-vertical="true"
        :text="message.text"
      />
    </div>
  </div>
</template>

<script>
// component
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import FairId from "@/components/atoms/common/FairId.vue";
import FairImg from "@/components/atoms/common/FairImg.vue";
import InoperableCover from "@/components/atoms/common/InoperableCover.vue";
import PeriodDate from "@/components/atoms/common/PeriodDate.vue";
import UseCount from "@/components/atoms/common/UseCount.vue";
import StatusLabel from "@/components/molecules/common/StatusLabel.vue";

// store
import { mapActions, mapGetters } from "vuex";
// asset
import API_CODE from "@/assets/common/ApiCode";

export default {
  components: {
    ActionButton,
    FairId,
    FairImg,
    InoperableCover,
    PeriodDate,
    UseCount,
    StatusLabel,
  },
  props: {
    image: Object,
    mode: {
      type: String,
      default: "view",
    },
    selected: {
      type: Number,
      default: -1,
    },
    targetMediaIds: {
      type: Array,
      default: () => [],
    }, // アルバム連携状態を確認する媒体IDリスト
    targetUrls: {
      type: Array,
      default: () => [],
    },
    timeStamp: Number,
    // 存在を確認する画像の種類のリスト(urlsのキー)
    needAllUrls: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters([
      "master",
      "medias",
      "checkedWidth",
      "isSelectableImage",
      "urlType",
    ]),
    ...mapGetters("filter", ["checkedIds"]),
    blockClassType() {
      return {
        selected: this.image && this.image.id === this.selected,
        viewSelect: this.mode === "select",
        isDisabled: this.isDisabled,
      };
    },
    existsLargeTrim() {
      return !!this.image.urls[String(this.checkedWidth)];
    },
    isDisabled() {
      return this.message && this.message.icon !== "" ? true : false;
    },
    message() {
      let icon = "";
      let text = "";
      const status = this.image.link_statuses;
      // 画像管理
      if (
        Object.keys(status).some(
          (k) =>
            status[k] === API_CODE.linkStatus.inSync ||
            status[k] === API_CODE.linkStatus.locked
        )
      ) {
        icon = "autorenew";
        text = `媒体とデータを同期中のため<br />${
          this.mode === "view" ? "編集" : "選択"
        }できません`;
        return { icon, text };
      }
      if (this.mode === "select") {
        // 選択モード
        const isSelectable = this.isSelectableImage(
          this.image,
          this.targetMediaIds,
          this.targetUrls,
          this.needAllUrls
        );
        if (isSelectable === true) {
          return { icon, text };
        }
        icon = "warning";
        switch (isSelectable) {
          case "unlinked":
            text = "アルバム連携が<br />有効ではないため<br />選択できません";
            break;

          case "untrimmed":
            text = "トリミング設定が<br />されていないため<br />選択できません";
            break;

          case "too_short":
            text =
              "画像が" + this.checkedWidth + "px未満のため<br />選択できません";
            break;
        }
      }
      return { icon, text };
    },
    tags() {
      if (this.image && this.image.tags) {
        let tags = [];
        const masterTags = Object.keys(this.master.tags).map(Number);
        for (let id of this.image.tags) {
          if (masterTags.indexOf(id) !== -1) {
            tags.push(this.master.tags[id]);
          }
        }
        return tags;
      } else {
        return [];
      }
    },
    url() {
      let type = this.urlType || "thumb";
      if (!this.image.urls[type]) type = "thumb";
      // 画像タイプを変更する場合はキャッシュ対策にパラメータ追加
      const param = type === "thumb" ? "" : `?t=${this.timeStamp}`;
      return this.image.urls[type] + param;
    },
    noUrlType() {
      return this.urlType ? !this.image.urls[this.urlType] : false;
    },
    sizeType() {
      return `${this.medias[API_CODE.media.wp].short_name}対応`;
    },
    isFatalError() {
      return Object.values(this.image.link_statuses).some(
        (status) => status == API_CODE.linkStatus.fatalError
      );
    },
    isLinkageError() {
      return Object.values(this.image.link_statuses).some(
        (status) => status == API_CODE.linkStatus.error
      );
    },
    linkageErrorMediaIds() {
      return Object.keys(this.image.link_statuses).filter(
        (mediaId) =>
          this.image.link_statuses[mediaId] >= API_CODE.linkStatus.error
      );
    },
  },
  methods: {
    ...mapActions("filter", ["updateCheckedId"]),
    ...mapActions(["movePage"]),
    isSetTrim(ratioType) {
      const urlType = this.master.trim_type[ratioType];
      if (urlType == undefined) return false;
      return this.image.urls[urlType] != undefined;
    },
    selectImage(isBtn = true) {
      if (this.mode === "view" && isBtn) {
        // ボタン以外から呼んだ場合はリンクはしない
        this.movePage({
          path: "/image-detail/" + this.image.id,
          isShowTab: true,
        });
      } else {
        this.$emit("select", this.image);
      }
    },
    updateId(v) {
      const data = {
        id: this.image.id,
        isChecked: this.checkedIds.indexOf(v) === -1,
      };
      this.updateCheckedId(data);
    },
  },
};
</script>

<style scoped lang="scss">
.imgUnit {
  box-sizing: border-box;
  position: relative;
  &Head {
    color: initial;
    font-size: initial;
    height: $GRID * 1.5;
  }
  .block {
    @include Box();
    overflow: hidden;
    position: relative;
    width: $GRID * 14;
    .detail {
      color: $COLOR_KEY;
      font-size: 10px;
      min-height: $GRID * 14;
      padding: 0 $GRID $GRID;
      > div {
        margin: $GRID * 0.5 0;
      }
      .settingBtn {
        height: 32px;
        margin-top: $GRID;
      }
      .file {
        color: $COLOR_SUB_TEXT;
        height: $GRID;
        margin: 4px 0 12px;
      }
      .link {
        margin-left: $GRID * 0.5;
        .selectBox {
          margin: 1px 0;
          width: $GRID * 5;
        }
      }
      .name {
        font-size: 12px;
        font-weight: bold;
        height: $GRID;
        margin: 12px 0 4px;
        position: relative;
        &.isCenter {
          height: $GRID * 2;
          line-height: $GRID * 2;
          margin: 12px 0;
        }
      }
      .size {
        display: flex;
        .material-icons {
          margin-right: $GRID * 0.5;
        }
        .px {
          font-size: 12px;
          margin-right: $GRID * 0.5;
          min-width: $GRID * 5.25;
        }
        .selectBox {
          width: $GRID * 4;
        }
      }
      .tag {
        background: $BG_COLOR_SUB;
        border-radius: 6px;
        padding: 0 $GRID * 0.25;
        &.disabled {
          color: $COLOR_DISABLE_TEXT;
        }
        .material-icons {
          padding: 2px 2px 0 0;
        }
      }
      .trim {
        align-items: center;
        display: flex;
        font-size: 12px;
        .selectBox,
        .material-icons {
          margin-right: $GRID * 0.5;
        }
        .selectBox {
          letter-spacing: 0.1em;
          text-indent: 0.1em;
          width: $GRID * 3;
        }
      }
      .used {
        align-items: center;
        display: flex;
        .disabled {
          pointer-events: none;
        }
      }
    }
    .img {
      position: relative;
      &.noUrlType {
        background: rgba(#000, 0.5);
        ::v-deep img {
          opacity: 0.4;
        }
      }
    }
  }
  .borderBottom {
    border-bottom: 1px solid $BG_COLOR_SUB;
    padding-bottom: $GRID * 0.5;
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .material-icons {
    font-size: 14px;
  }
  .selectBox {
    align-items: center;
    border: 2px solid;
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    height: $GRID;
    justify-content: space-around;
    padding-top: 1px;
    &.disabled {
      border-color: #f7f7f7;
      color: $COLOR_DISABLE_TEXT;
    }
  }
  .viewSelect {
    cursor: pointer;
    transition: all 400ms ease-in;
    &:not(.isDisabled):not(.selected) {
      &:hover {
        box-shadow: $DEPTH_LV_3;
        transform: scale(1.05);
        transition: transform 160ms ease-out;
      }
    }
    &.isDisabled {
      cursor: not-allowed;
    }
    &.selected {
      box-shadow: 0 0 0 $GRID * 0.25 rgba($COLOR_SUBMIT, 0.85);
      cursor: default;
    }
  }
}
</style>
