const getters = {
  count: (state) => {
    return state.count;
  },
  master: (state) => {
    return state.master;
  },
  masterTrimTypes: (state) => {
    return Object.values(state.master.trim_type);
  },
  filterTags: (state) => {
    return state.filterTags;
  },
  medias: (state) => {
    return state.medias;
  },
  checkedWidth: (state) => {
    return state.checkedWidth;
  },
  isSelectableImage: (state, getters) => (
    image,
    targetMediaIds,
    targetUrls,
    needAllUrls = true
  ) => {
    let isUnLinked = false;

    // アルバム連携設定のある媒体が含まれる場合、有効になっていれば選択可能に
    if (targetMediaIds) {
      const linkMediaIds = Object.keys(image.is_uploaded).map(Number);
      isUnLinked = targetMediaIds.some((id) => {
        return (
          linkMediaIds.includes(Number(id)) && !image.media.includes(Number(id))
        );
      });
      if (isUnLinked) return "unlinked";
    }

    if (!targetUrls || targetUrls.length === 0) return true;
    if (needAllUrls) {
      if (!getters.existsTargetTrim(image, targetUrls, needAllUrls)) {
        return "untrimmed";
      } else if (!getters.existsTargetWidth(image, targetUrls)) {
        return "too_short";
      }
    }

    const targetWidth = state.checkedWidth;
    const notExistsTrim = !getters.existsTargetTrim(
      image,
      targetUrls,
      needAllUrls
    );
    const notIncludesWidth = targetUrls.indexOf(targetWidth) < 0;
    const notExistsWidth = !getters.existsTargetWidth(image, targetUrls);
    if (notExistsTrim & (notIncludesWidth || notExistsWidth)) {
      return "untrimmed";
    }
    return true;
  },
  existsTargetTrim: (getters) => (image, targetUrls, needAllUrls = true) => {
    if (!targetUrls || targetUrls.length === 0) return true;
    const master = getters.master,
      trimTypes = Object.values(master.trim_type);
    if (needAllUrls) {
      return trimTypes.every((type) => {
        if (targetUrls.indexOf(type) === -1) return true;
        return !!image.urls[type];
      });
    } else {
      return trimTypes.some((type) => {
        if (targetUrls.indexOf(type) === -1) return false;
        return !!image.urls[type];
      });
    }
  },
  existsTargetWidth: (state, getters) => (image, targetUrls) => {
    if (!targetUrls || targetUrls.length === 0) return true;
    const targetType = state.checkedWidth;
    if (targetUrls.indexOf(targetType) === -1) return true;
    return !!image.urls[String(targetType)];
  },
  urlType: (state) => {
    return state.urlType;
  },
};
export default getters;
