import Vue from "vue";
import Vuex from "vuex";

import commonModule from "@/store/common";
import utilityModule from "@/store/utility";
import modalModule from "@/store/modal";
import hpSettingModule from "@/store/hp-setting/hp-setting-module";

import ImageSelect from "@/store/modules/ImageSelect.js";
import NgCheck from "@/store/modules/NgCheck.js";
import statusChecker from "@/store/modules/StatusChecker";
import filter from "./filterModule";
import rules from "@/store/benefit/rules";
import validation from "@/store/benefit/ValidationModule";
import publishing from "@/store/benefit/publishing";

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import editState from "@/store/benefit/edit/state";
import editActions from "@/store/benefit/edit/actions";
import editGetters from "@/store/benefit/edit/getters";
import editMutations from "@/store/benefit/edit/mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    filter,
    rules,
    statusChecker,
    validation,
    publishing,
    common: commonModule,
    utility: utilityModule,
    modal: modalModule,
    imageSelect: ImageSelect,
    ngCheck: NgCheck,
    hpSetting: hpSettingModule,
  },
  state: {
    ...state,
    ...editState,
  },
  getters: {
    ...getters,
    ...editGetters,
  },
  mutations: {
    ...mutations,
    ...editMutations,
  },
  actions: {
    ...actions,
    ...editActions,
  },
});
