import Vue from "vue";

/**
 * モーダルを動的に生成するための関数
 * componentでsubmitとcancelを設定しておけば、モーダルの結果をPromiseで取得できる
 * @param {*} component - 表示したいコンポーネント
 * @param {*} props - コンポーネントに渡すprops
 * @param {*} elementId - コンポーネントに自動的に付与するID. モーダルを重ねて表示したい場合は任意のIDを指定すること
 */
function modal(component, props, store = null, elementId = "modal") {
  // 新しくVueインスタンスを生成する
  const options = {
    props,
    attrs: {
      id: elementId,
    },
    on: {
      submit(event) {
        vm.$emit("submit", event);
      },
      cancel(event) {
        vm.$emit("cancel", event);
      },
    },
  };

  // bodyにVueインスタンスをマウントする
  const vm = new Vue({
    store,
    render: (h) => h(component, options),
  }).$mount();
  document.body.appendChild(vm.$el);

  // Vueインスタンスを破棄するための関数
  const destroy = () => {
    document.body.removeChild(vm.$el);
    vm.$destroy();
  };

  // モーダルからのエミットを検知して返却する
  return new Promise((resolve, reject) => {
    vm.$on("submit", (event) => {
      destroy();
      resolve(event);
    });

    vm.$on("cancel", (event) => {
      destroy();
      reject(event);
    });
  });
}

export default modal;
