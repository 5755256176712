<template>
  <div class="job">
    <page-title icon="history" title="更新履歴" />
    <job-tab-unit />
    <component :is="currentComponent" />
  </div>
</template>

<script>
// Components
import PageTitle from "@/components/atoms/common/PageTitle.vue";
import JobListUnit from "@/components/organisms/job/JobListUnit.vue";
import HistoryListUnit from "@/components/organisms/job/HistoryListUnit.vue";
import JobTabUnit from "@/components/organisms/job/JobTabUnit.vue";
// Store
import { mapGetters } from "vuex";

export default {
  components: {
    PageTitle,
    JobListUnit,
    HistoryListUnit,
    JobTabUnit,
  },
  data() {
    return {
      tabComponents: {
        waiting: "job-list-unit",
        executed: "history-list-unit",
        operationLogs: "history-list-unit",
      },
    };
  },
  computed: {
    ...mapGetters("job", ["tab"]),
    currentComponent: function() {
      return (
        this.tabComponents[this.tab.active] ||
        Object.values(this.tabComponents)[0]
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
