<template>
  <div class="clamp-text" :class="addClass">
    {{ text || noInputText }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    noInputText: {
      type: String,
      default: "未入力",
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addClass: function() {
      return {
        "clamp-text--centered": this.isCentered || !this.text,
        "clamp-text--no-input": !this.text,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.clamp-text {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;

  &--centered {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--no-input {
    color: $COLOR_DISABLE_TEXT;
  }
}
</style>
