/**
 * 検索用モジュール
 */
import isSuspended from "@/store/hall-admin/helper/is-suspended.js";

const Filter = {
  namespaced: true,
  state: {
    keyword: "",
    usageState: "usage_started_at",
    usageStateMaster: {
      preparation_started_at: "入力中",
      account_created_at: "取込中", //"入力完了",
      import_ended_at: "紐付け", //"紐付け",
      associate_ended_at: "メンテナンス", // "紐付け完了",
      data_created_at: "確認中",
      usage_started_at: "利用中",
      suspended_at: "終了",
      is_test: "デモ",
    },
    showErrorState: "",
    showErrorStateMaster: {
      1: "エラー有",
      2: "エラー無",
    },
    isClearedFilter: false,
    tab: {
      active: "usage_started_at",
    },
  },
  getters: {
    filteredData: (_, getters, rootState) => {
      let data = rootState.hallAdmin.hall.list;

      // フィルタリング(検索)
      // キーワード検索
      if (getters.keyword != "") {
        let keywordArray = getters.keyword.replace(/\u3000/g, " ").split(" "); // スペース区切り
        keywordArray.forEach((word) => {
          data = data.filter((d) => {
            const hasKeywordId = String(d.hall_id).indexOf(word) !== -1;
            const hasKeywordName = d.hall_name.indexOf(word) !== -1;
            const hasKeywordCity = d.city && d.city.indexOf(word) !== -1;
            const hasKeywordComment =
              d.comment && d.comment.indexOf(word) !== -1;
            const isShowComment =
              d.preparation_started_at && !isSuspended(d.suspended_at);
            return (
              hasKeywordId ||
              hasKeywordName ||
              hasKeywordCity ||
              (isShowComment && hasKeywordComment)
            );
          });
        });
      }
      // 利用状態
      if (getters.usageState === "") {
        data = data.filter((hall) => !hall.is_test);
      }
      if (getters.usageState != "") {
        data = data.filter((d) => {
          let s = "";
          if (d.is_test) s = "is_test";
          else if (isSuspended(d.suspended_at)) s = "suspended_at";
          else if (d.usage_started_at && d.in_preparation_target.length === 0)
            s = "usage_started_at";
          else if (d.data_created_at) s = "data_created_at";
          else if (d.associate_ended_at) s = "associate_ended_at";
          else if (d.import_ended_at) s = "import_ended_at";
          else if (d.account_created_at) s = "account_created_at";
          else if (d.preparation_started_at) s = "preparation_started_at";

          return s == getters.usageState;
        });
      }
      if (getters.showErrorState !== "") {
        const showErrorState = getters.showErrorState;
        data = data.filter((hall) => {
          const hasError = hall.caution.length > 0;
          return showErrorState === "1" ? hasError : !hasError;
        });
      }
      return data;
    },
    keyword: ({ keyword }) => keyword,
    usageState: ({ usageState }) => usageState,
    usageStateMaster: ({ usageStateMaster }) => usageStateMaster,
    showErrorState: ({ showErrorState }) => showErrorState,
    showErrorStateMaster: ({ showErrorStateMaster }) => showErrorStateMaster,
    isClearedFilter: (state) => {
      return state.isClearedFilter;
    },
  },
  mutations: {
    /**
     * フィルターの初期化
     */
    INITIALIZE_FILTER(state) {
      state.keyword = "";
      state.usageState = "";
      state.isClearedFilter = true;
    },
    /**
     * フィルタリング
     */
    SET_FILTER(state, payload) {
      const { label, value } = payload;
      state[label] = value;
    },
    /**
     * Filter 初期化フラグをセット
     * @param {Boolean} payload 初期化するかどうか
     */
    SET_IS_CLEARED_FILTER(state, payload) {
      state.isClearedFilter = payload;
    },
    /**
     * タブ切り替え
     */
    SET_TAB_KEY(state, key) {
      if (key == "") key = "all";
      state.tab.active = key;
    },
    /**
     * filterMaster を制限
     */
    SET_RESTRICTED_MASTER(state) {
      if (state.usageState.before) {
        delete state.usageState.before;
      }
    },
    /**
     * エラー有無のセレクトボックスの値を更新する
     */
    showErrorState(state, errorState) {
      state.showErrorState = errorState;
    },
  },
};
export default Filter;
